import React from "react";

const CustomLabel = ({ wrapperClass, label, handleClick, actionClassName, children, labelsArray = [] }) => {
  return (
    <div className={`position-relative ${wrapperClass}`}>
      {children}
      {labelsArray?.length > 1 ? (
        <div className="position-absolute d-flex gap-2 customLabelTextWrapper top-0 end-0">
          {labelsArray?.map((item, index) => (
            <span
              key={index}
              className={`d-inline-block customLabelText ${actionClassName}`}
              onClick={item.event || handleClick}
            >
              {item.label}
            </span>
          ))}
        </div>
      ) : (
        <span
          className={`position-absolute d-inline-block customLabelText top-0 end-0`}
          onClick={handleClick}
        >
          {label}
        </span>
      )}
    </div>
  );};

export default CustomLabel;
