import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showConfirmationModal } from "./generalAction";
import CONSTANTS from "./generalConstant";
import { Storage } from "aws-amplify";
import { CONFIG } from "constant";
import api from "../../../api";

export const setERADocumentView = createAsyncThunk(CONSTANTS.SHOW_DOCUMENT, async (user) => {
  try {

    const eraid = user.eraIds ? user.eraIds.split(",").pop() :  user.eraid;
    let filePath = await api.getPatientERA({ ...user, eraid: eraid });

    if (!filePath) {
      setLoading(false);
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      return;
    }
    const result = await Storage.get(filePath, {
      download: false,
      contentDisposition: "inline",
      bucket: CONFIG.eligibilityBucket,
      contentType: "application/pdf",
    });

    const message = user?.type === "Patient" ? `: ${user.pcn}` : "";

    const pdfObj = {
      fileUrl: result,
      show: true,
      fileType: "pdf",
      title: `ERA View ${message}`,
      user
    };

    return pdfObj;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("File does not exist");
      return false;
    } else {
      console.error("Error occurred while checking file existence:", error);
    }
  }
});

export const setResultView = createAsyncThunk(CONSTANTS.SHOW_RESULT, async (claim) => {
  try {

   
    let filePath = await api.fetchResultFile({ barcode: claim.accNo, type : claim.reqType });
    
    if (!filePath) {
      setLoading(false);
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      return;
    }
    const result = await Storage.get(`${filePath.file}`, {
      download: false,
      contentDisposition: "inline",
      bucket: CONFIG.documentBucket,
      contentType: "application/pdf",
    });

    const title = claim.reqType  === 'r' ? "Result" : "Requisition"

    const pdfObj = {
      fileUrl: result,
      show: true,
      fileType: "pdf",
      title: `${title} View ${claim.accNo }`
    };

    return pdfObj;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("File does not exist");
      return false;
    } else {
      console.error("Error occurred while checking file existence:", error);
    }
  }
});


const generalSlice = createSlice({
  name: CONSTANTS.GENERAL,
  initialState: {
    loader: false,
    error: null,
    message: {
      text: "",
      mode: "",
    },
    confirmationModal: {
      showModal: false,
      message: "",
      title: "",
      onConfirm: null,
    },
    documentView: CONSTANTS.EMPTY_DOCUMENT_OBJ,
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = Boolean(action.payload);
    },
    showMessage: (state, action) => {
      state.message = {
        text: action.payload?.text || "",
        mode: action.payload?.mode || "",
      };
    },
    setError: (state, action) => {
      state.error = action.payload || null;
    },
    setERADocumentView: (state, actionn) => {
      state.documentView = actionn.payload;
    },
    closeDocumentView: (state, actionn) => {
      state.documentView = CONSTANTS.EMPTY_DOCUMENT_OBJ;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setERADocumentView.fulfilled, (state, action) => {
      state.documentView = action.payload;
    });
    builder.addCase(setResultView.fulfilled, (state, action) => {
      state.documentView = action.payload;
    });
    builder.addCase(showConfirmationModal.fulfilled, (state, action) => {
      state.confirmationModal = action.payload;
    });
  },
});

export const { setLoader, showMessage, setError, closeDocumentView } = generalSlice.actions;

export default generalSlice.reducer;
