import api from "api";
import { Storage } from "aws-amplify";
import CheckBoxBar from "components/CheckBox/CheckBoxBar";
import EmployeeModal from "components/Employee/EmployeeModal";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import InputField from "components/InputField/InputField";
import ErrorMessage from "components/Message/ErrorMessage";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ErrorMessageModal from "components/Modal/ErrorMessageModal";
import LabInfoModal from "components/Modal/LabInfoModal/LabInfoModal";
import EraModal from "components/Modal/Order/EraModal";
import HospiceModifierModal from "components/Modal/Order/HospiceModifierModal";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import ProcedureListView from "components/Modal/Order/components/ProcedureListView";
import ProcedureModefierModal from "components/Modal/ProcedureModefierModal";
import ProviderModal from "components/Modal/ProviderModal";
import RadioButton from "components/RadioButton/RadioButton";
import { INSURANCE_PROVIDER, MEDICARE_ID, ON_HOLD_CPT } from "constant";
import { ErrorBoundary } from "react-error-boundary";
import { setClaimReSubmissionModal } from "store/features/employeeClaims/employeeClaimsSlice";
import CheckBox from "components/CheckBox/CheckBox";

import claimApi from "api/claimApi";
import employeeApi from "api/employeeApi";
import {
  CLAIM_SUBMIT_STATUS,
  CONFIG,
  CONFIRMATION_TYPE,
  CUSTOM_INS,
  DIAGNOSIS_ITEMS,
  HOSPICE_MODFIRE,
  INSURANCE_EMPTY_OBJECT,
  INSURANCE_TYPE,
  MESSAGE_MODES,
  PARSE_ALPHA_INTO_NUM,
  PARSE_CLAIM_ERROR_KEY,
  PREV_CLAIM_ACTIONS,
  PROCEDURE_CODE_HEADER,
  PROCEDURE_CODE_STATES,
  PROC_CODE_OPT,
  currencyformatter,
  usersNo,
} from "constant";
import graphqlApi from "graphqlApi";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Button, Card, Col, Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select, { components as selectComponents } from "react-select";
import insServices from "services/InsuranceService";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import {
  selectedCompanySetting,
  selectedCompanySettingClia,
  renderingProviderSettingData,
} from "store/features/companySetting/companySettingSelectors";
import {
  createEmployeeClaim,
  fetchAllEmployeeClaims,
  updateEmployeeClaim,
  updateSubmittedClaim,
} from "store/features/employeeClaims/employeeClaimsActions";
import { createEmployee, updateEmployee } from "store/features/employees/employeesAction";
import { employeesSelector } from "store/features/employees/employeesSelectors";
import { updateEmployeeFromClaim } from "store/features/employees/employeesSlice";
import { setMessage } from "store/features/general/generalAction";
import { setLoader } from "store/features/general/generalSlice";
import { setOpenInsuranceModal, setSelectedInsurance } from "store/features/insurances/insuranceSlice";
import { selectedLocation } from "store/features/locations/locationsSelectors";
import { selectedProvider } from "store/features/providers/providersSelectors";
import { selectedSubAgent } from "store/features/subAgents/subAgentsSelectors";
import {
  CapsFirstLetter,
  calcHeight,
  convertToUpper,
  createErrorMessageString,
  formatEmployeeClaim,
  formatProvidersLabel,
  formatZip,
  getDiagonosisCode,
  getDiagonosisInternalCodeValue,
  getValidDep,
  getValidSpaces,
  isValidZipCode,
  parseErrorMessageString,
  parseInsDetails,
  removeSpaceIns,
  userCompanyID,
  getTotalCharge,
} from "utils";
import ClaimResolveReasonModal from "views/EmployeeClaims/ClaimResolveReasonModal";
import EmployeePrevClaimsModal from "views/EmployeeClaims/EmployeePrevClaimsModal";
import ViewClaimList from "views/EmployeeClaims/ViewClaimList";
import {
  formatClaimObject,
  isClaimDataModified,
  validateDiags,
  validateDiagReferences,
  checkProcCodes,
  containsDiagCode,
} from "views/EmployeeClaims/claimFuntions/formatClaim";
import { selectedClients } from "store/features/clients/clientsSelectors";
import { formatEmployeesData } from "utils";
import InputSelectDropDown from "components/InputField/InputSelectDropDown";
import { checkPlanDates } from "components/Modal/CheckEligibility/common";
import { DIAG_REF_CODE } from "constant";
import { isAllDiagAndProcCodeValidation } from "./claimFuntions/formatClaim";
import { authSelector } from "store/features/authentication/authenticationSelectors";
import { AppContext } from "context/app-context";
import { insurnaceSelector } from "store/features/insurances/insuranceSelectors";
import { addModifireInProcArray } from "utils";
import { setEligibilityDetailsData } from "store/features/checkEligibility/checkEligibilitySlice";
import { addHospiceModifireInClaimProcArray } from "utils";
import CustomLabel from "components/CustomLabel/CustomLabel";
import { setResultView } from "store/features/general/generalSlice";

const arePropsEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return true;
  return false;
  // Custom comparison logic for props
  // Return true if the props are equal, otherwise return false
};

const CreateClaimResubmissionModal = (props) => {
  const { user, handleClose } = props;
  const [procedure, setProcedure] = useState(false);
  const [newClaim, setNewClaim] = useState({ clientID: userCompanyID.get() });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [oldClaim, setOldClaim] = useState({});
  const [editObj, setEditObj] = useState({});
  const [providersOptions, setProvidersOptions] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [prevClaims, setPrevClaims] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState(INSURANCE_TYPE.primary);
  const [employeeData, setEmployeeData] = useState(null);
  const [isHospice, setIsHospice] = useState(null);
  const [hospiceModifer, setHospiceModifier] = useState(false);
  const [openPrevClaimModal, setOpenPrevClaimModal] = useState(false);
  const [eraList, setEraList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [reasonModal, setReasonModal] = useState(false);
  const [showLabInfoModal, setShowLabInfoModal] = useState("");
  const [providerObj, setProviderObj] = useState(null);
  const [isNotification, setIsNotification] = useState(false);
  const [changePatientData, setChangePatientData] = useState(false);
  const [employeeObjToUpdate, setEmployeeObjToUpdate] = useState(null);
  const [claimList, setClaimList] = useState([]);
  const [updatePatient, setUpdatePatient] = useState(null);
  const [openEraModal, setOpenEraModal] = useState(null);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [empSearch, setEmpSearch] = useState("");
  const [filteredEmps, setFilteredEmps] = useState([]);
  const [ignoreEligibility, setIgnoreEligibility] = useState(false);
  const setting = useSelector(selectedCompanySetting);
  const { providers } = useSelector(selectedProvider);
  const { locations, userLocation } = useSelector(selectedLocation);
  const { subAgents, userSubAgent } = useSelector(selectedSubAgent);
  const loginUser = useSelector(selectLoginUser);
  const { employees } = useSelector(employeesSelector);
  const { clients } = useSelector(selectedClients);
  const cliList = useSelector(selectedCompanySettingClia);
  const dispatch = useDispatch();

  const { permission } = useSelector(authSelector);
  const { payerList, insurancesList } = useSelector(insurnaceSelector);

  const appContext = useContext(AppContext);

  const handleAddData = (newData, actionState) => {
    let newArray = [...dataList];

    newArray = [...newArray].map((obj) => {
      return { ...obj, from_date: newData.from_date };
    });
    newArray.push(newData);
    setDataList(newArray);
    // close the dialog if click on add button else allow to continue to add
    if (actionState === PROCEDURE_CODE_STATES.ADD) {
      setProcedure(false);
      setEditObj({});
    }
  };

  useEffect(async () => {
    if (newClaim.status && newClaim.status === CLAIM_SUBMIT_STATUS.denied) {
      const era = await api.getClaimERA(newClaim);
      if (era && era.length > 0) {
        setEraList(era);
      }
    }
  }, [newClaim.status]);

  useEffect(() => {
    getCliData();
  }, [cliList]);

  const fetchEmployeesList = async (filterTerm) => {
    const response = await employeeApi.listEmployeesApi({ firstName: filterTerm || empSearch });
    if (response) setFilteredEmps(employeeParser(response.data));
  };

  const fetchFromApi = useCallback(
    debounce(async (filterTerm) => {
      fetchEmployeesList(filterTerm);
    }, 1000),
    []
  );

  useEffect(() => {
    if (empSearch) fetchFromApi(empSearch);
  }, [empSearch]);

  useEffect(() => {
    if (
      newClaim.message &&
      (newClaim.status === CLAIM_SUBMIT_STATUS.denied || newClaim.status === CLAIM_SUBMIT_STATUS.rejected)
    ) {
      const res = parseErrorMessageString(newClaim.message);
      if (res && res.length > 0) {
        const errList = [];
        res.forEach((val) => {
          if (Object.keys(newClaim).includes(val.field) && !val.resolve) {
            const findKey = Object.keys(newClaim).find((f) => f === val.field);
            errList.push(findKey);
          } else if ((val.code?.includes("procedure code") || val.key?.includes("procedure code")) && !val.resolve) {
            errList.push("proc_code");
          } else if (
            Object.entries({ ...newClaim }).find(
              ([key, value]) =>
                val.code?.includes(value) ||
                val.code?.includes(key) ||
                val.field?.includes(value) ||
                val.field?.includes(key)
            ) &&
            !val.resolve
          ) {
            const findKey = Object.entries({ ...newClaim }).find(
              ([key, value]) =>
                val.code?.includes(value) ||
                val.code?.includes(key) ||
                val.field?.includes(value) ||
                val.field?.includes(key)
            );

            errList.push(findKey[0]);
          } else if (
            Object.entries({ ...newClaim, ...newClaim.provider }).find(([key, value]) => {
              const keyVlaue = PARSE_CLAIM_ERROR_KEY[key] || key;
              return (
                val.code?.includes(value) ||
                val.code?.includes(keyVlaue) ||
                val.field?.includes(value) ||
                val.field?.includes(keyVlaue)
              );
            }) &&
            !val.resolve
          ) {
            const findKey = Object.entries({ ...newClaim, ...newClaim.provider }).find(([key, value]) => {
              const keyVlaue = PARSE_CLAIM_ERROR_KEY[key] || key;
              return (
                val.code?.includes(value) ||
                val.code?.includes(keyVlaue) ||
                val.field?.includes(value) ||
                val.field?.includes(keyVlaue)
              );
            });

            errList.push(findKey[0]);
          }
        });
        setErrorList([...errList]);
      }
    }
  }, [newClaim.message]);

  useEffect(() => {
    if (newClaim.status && oldClaim && Object.keys(oldClaim).length > 0) {
      const patientData = Object.keys(newClaim).filter((f) => f.includes("pat_") || f.includes("payer"));
      const dataKey = patientData.find((s) => oldClaim[s] != newClaim[s]);
      if (dataKey) {
        setIsNotification(true);
      } else {
        setIsNotification(false);
        setChangePatientData(false);
      }
    }
  }, [newClaim]);
  const LOCATION_OPTIONS = useMemo(() => {
    if (locations?.length > 0) {
      const items = locations.map((item) => ({
        ...item,
        // label: `${String(item.practiceID).padEnd(4, " ")} - ${item.name}`,
        label: item.name,
        value: item.id,
      }));
      return items;
    }
    return [];
  }, [locations]);

  const totalBilledAmount = useMemo(() => {
    if (dataList?.length > 0) {
      return dataList.reduce((ttl, proc) => ttl + getTotalCharge(proc), 0);
    }
    return newClaim?.total_charge || 0;
  }, [dataList]);

  // in case create claim is called from Employee Listing
  useEffect(async () => {
    if (user && user.length > 0) {
      const obj = user[0];
      let empID = "";
      let empObj = null;

      // in user object only employee id is come when called from Claim Listing tab on select any claim
      // check if only employee id is coming get the employee record from employees and set else set the user obj

      // just employeeId
      if (obj.employeeId) {
        empID = obj.employeeId;
      } else if (obj.employeeID) {
        // claim Obj
        const { data: claimData } = await claimApi.getClaimById(obj.id);
        empID = claimData.employeeID;
        empObj = employeeParser([claimData.employee])[0];
        formatClaimData([claimData]);
      } else {
        // employee Obj
        empID = obj.id;
      }

      if (empObj) {
        setSelectedEmployee(empObj);
      } else {
        //set employee as selected employee
        const employeeObj = await employeeApi.getEmployeeById(empID);

        // const employeeObj = employees.find((f) => f.id === obj.employeeId);
        if (employeeObj && employeeObj.data) {
          const parsedEmp = employeeParser([employeeObj.data]);
          setSelectedEmployee(parsedEmp[0]);
          formatClaimData(parsedEmp);
        }
        await getEmployeeClaims(empID);
      }

      // fetch the previous employee claims
    } else {
      await fetchEmployeesList();
    }
  }, [user]);

  const getEmployeeClaims = async (employeeID) => {
    try {
      setLoading(true);
      const res = await api.getEmployeeClaimsAPI(employeeID);
      // show claim selection in case of any previous claims

      if (res && res.length > 0) {
        const listToSave = res.map((claim) => {
          return {
            ...claim,
            proc_array: claim.proc_array.map((proc) => {
              return { ...proc, proc_name: PROC_CODE_OPT.find((obj) => obj.value === proc.proc_code)?.label || "" };
            }),
          };
        });
        setPrevClaims(listToSave);
        setOpenPrevClaimModal(true);
      } else {
        setPrevClaims([]);
      }
    } catch (er) {
      console.log("Error", er);
    } finally {
      setLoading(false);
    }
  };

  const getCliData = async () => {
    if (cliList && cliList.length > 0) {
      const defaultCliaNumber = cliList.find((s) => s.isDefault === true || s.isDefault === "Yes");
      if (defaultCliaNumber.cliCode) {
        setNewClaim((prev) => ({ ...prev, clia_number: defaultCliaNumber?.cliCode }));
      }
    }
  };

  useEffect(() => {
    if (providers.length > 0) {
      let arr = providers.map((m) => {
        const formattedLabel = formatProvidersLabel(getValidDep(m.name), m?.code || "");
        return { ...m, value: m.id, label: formattedLabel };
      });
      setProvidersOptions(arr);
    }
  }, [providers]);

  const renderingProvidersOptions = useSelector(renderingProviderSettingData);

  const handleConfirm = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) return;
    switch (type) {
      case CONFIRMATION_TYPE.SUBMIT_CLAIM:
        onHandleSave({
          isNew: false,
          claimStatus: "submitted",
          claimtype: "claim",
          isEligibilityChecked: false,
        });

        break;
      case CONFIRMATION_TYPE.RESUBMIT_CLAIM:
        onHandleSave({
          isNew: false,
          claimStatus: "submitted",
          claimtype: "reclaim",
          isEligibilityChecked: false,
        });

        break;
    }
  };

  const formatClaimData = (user) => {
    const { claimObj, proc_array } = formatClaimObject(user, setting);
    setDataList(proc_array);
    setNewClaim((prev) => ({
      ...prev,
      ...claimObj,
      proc_array,
    }));
    if (!user[0].employeeID) {
      setEmployeeData({ ...user[0], insuranceDetails: parseInsDetails(user[0].insuranceDetails, user[0]) });
    }
    if (user[0].employeeID) {
      setOldClaim(user[0]);
    }
  };

  const handleAddHospiceModifire = ({ user: obj, claimtype, claimStatus, isNew, isEligibilityChecked }) => {
    setNewClaim({ ...obj });

    onHandleSave({
      isNew,
      claimStatus,
      claimData: obj,
      claimtype: claimtype,
      isEligibilityChecked,
    });
  };

  useEffect(() => {
    if (insuranceInfo && employeeData) {
      if (insuranceInfo === INSURANCE_TYPE.primary) {
        setNewClaim((prev) => ({
          ...prev,
          payerid: employeeData?.insuranceCompany || "",
          payer_name: employeeData?.insuranceCompanyCode || "",
          ins_number: employeeData?.medicalNo || "",
        }));
      } else {
        setNewClaim((prev) => ({
          ...prev,
          ...newClaim,
          payerid: employeeData.insuranceDetails[1]?.insuranceCompany || "",
          payer_name: employeeData.insuranceDetails[1]?.insuranceCompanyCode || "",
          ins_number: employeeData.insuranceDetails[1]?.medicalNo || "",
        }));
      }
    }
  }, [insuranceInfo, employeeData]);

  const handleCloseReasonModal = (reason) => {
    setReasonModal(false);
    setOpenConfirmation({
      isShow: true,
      message: `Are you sure, you want to Re-Send Patient Claim?`,
      actionType: CONFIRMATION_TYPE.RESUBMIT_CLAIM,
      title: "Claim Re-Send",
    });
    if (reason) {
      setNewClaim({ ...newClaim, message: reason });
    }
  };

  // Checck Submitted Claim Eliigibility for Hospice
  const checkSubmitClaimEligibilityForHospice = async (emp, mergedObj, claimStatus, claimtype, eligRes = {}, isNew) => {
    if (CUSTOM_INS.includes(mergedObj.payerid)) {
      return true;
    }

    const isEligibilityChecked = Object.keys(eligRes).length > 0;

    if (
      emp &&
      mergedObj.eEligibility &&
      !hospiceModifer &&
      mergedObj.proc_array.every(
        (f) =>
          !HOSPICE_MODFIRE[f.mod1] && !HOSPICE_MODFIRE[f.mod2] && !HOSPICE_MODFIRE[f.mod3] && !HOSPICE_MODFIRE[f.mod4]
      )
    ) {
      if (emp.isHospice && MEDICARE_ID.includes(mergedObj.payerid)) {
        setIsHospice({ newClaim: { ...mergedObj }, claimStatus, claimtype, isNew, isEligibilityChecked });
        dispatch(setLoader(false));
        return;
      }

      if (eligRes && eligRes.benefit && eligRes.benefit.length > 0) {
        const IsClaimHospice = eligRes.benefit.find((f) => f.benefit_description.toLowerCase() === "hospice");
        if (IsClaimHospice && checkPlanDates(IsClaimHospice.plan_date, mergedObj.from_date)) {
          setIsHospice({ newClaim: { ...mergedObj }, claimStatus, claimtype, isNew, isEligibilityChecked });
          dispatch(setLoader(false));
          return;
        }
      }
    }
    return true;
  };

  // Check Claim CPT or ICT is on Hold then Claim Not Submitted only Created in Draft
  const isClaimOnHold = (claimStatus, mergedObj) => {
    if (claimStatus === CLAIM_SUBMIT_STATUS.submitted && CONFIG.isBioLab) {
      const isOnHoldCPT = mergedObj.proc_array.some((s) => removeSpaceIns(s.proc_code).includes(ON_HOLD_CPT));
      if (isOnHoldCPT) {
        dispatch(setMessage("CPT is on Hold", MESSAGE_MODES.error));
        return true;
      }
    }
    return false;
  };

  // fetch inusruance modal
  const fetchInsuranceWithPayerID = async (mergedObj) => {
    let ins = await insServices.fetchInsurances({ payerId: mergedObj.payerid })?.rows;
    if (ins && ins.length > 0) {
      Object.assign(mergedObj, {
        bill_npi: ins[0].npi,
        eClaim: ins[0].eClaim ? "yes" : "no",
        eEligibility: ins[0].eEligibility,
      });
    } else {
      Object.assign(mergedObj, {
        bill_npi: CONFIG.DEFAULT_NPI,
        eClaim: "yes",
        eEligibility: true,
      });
    }
  };

  // Resolve Claims Error Messages
  const resolveErrorMessages = (claim, mergedObj) => {
    const res = parseErrorMessageString(claim.message);
    if (res && res.length > 0 && claim.message) {
      const resolveMessage = res.map((val) => {
        if (Object.keys(claim).includes(val.field) && !val.resolve) {
          const findKey = Object.keys(claim).find((f) => f === val.field);
          if (user[0][findKey] !== mergedObj[findKey]) {
            return { ...val, resolve: "(resolve)" };
          }
        } else if ((val.code?.includes("procedure code") || val.key?.includes("procedure code")) && !val.resolve) {
          if (dataList.some((s) => user[0].proc_array.find((f) => s.proc_code !== f.proc_code))) {
            return { ...val, resolve: "(resolve)" };
          }
        } else if (
          Object.entries({ ...claim, ...claim.provider }).find(
            ([key, value]) =>
              val.code?.includes(value) ||
              val.code?.includes(key) ||
              val.field?.includes(value) ||
              val.field?.includes(key)
          ) &&
          !val.resolve
        ) {
          const findKey = Object.entries(claim).find(
            ([key, value]) =>
              val.code?.includes(value) ||
              val.code?.includes(key) ||
              val.field?.includes(value) ||
              val.field?.includes(key)
          );

          if (user[0][findKey] !== mergedObj[findKey]) {
            return { ...val, resolve: "(resolve)" };
          }
        } else if (
          Object.entries({ ...claim, ...claim.provider }).find(
            ([key, value]) =>
              val.code?.includes(value) ||
              val.code?.includes(key) ||
              val.field?.includes(value) ||
              val.field?.includes(key)
          ) &&
          !val.resolve
        ) {
          const findKey = Object.entries(claim).find(
            ([key, value]) =>
              val.code?.includes(value) ||
              val.code?.includes(key) ||
              val.field?.includes(value) ||
              val.field?.includes(key)
          );

          if (user[0][findKey] !== mergedObj[findKey]) {
            return { ...val, resolve: "(resolve)" };
          }
        }
      });
      const msg = createErrorMessageString(resolveMessage.filter((f) => f));
      if (msg) mergedObj["message"] = msg;
    }
  };

  const updateClaimWithDiagRef = (claim) => {
    if (claim.proc_array.length === 0) return claim;

    const diagCodeRef = {
      G0103: { code: "Z125" },
      G0328: { code: "Z1211" },
    };

    // Function to find and return existing diagnostic references in a charge item
    const findExistingRefs = (chargeItem) => {
      return Array.from({ length: 5 }, (_, i) => chargeItem[`diag_ref_${i + 1}`]).filter(Boolean);
    };

    // Function to update the first available diagnostic reference in a charge item
    const updateFirstAvailableRef = (chargeItem, ref) => {
      for (let i = 1; i <= 5; i++) {
        const refKey = `diag_ref_${i}`;
        if (!chargeItem[refKey]) {
          chargeItem[refKey] = ref;
          break;
        }
      }
    };

    claim.proc_array.forEach((chargeItem) => {
      if (diagCodeRef.hasOwnProperty(chargeItem.proc_code)) {
        const targetCode = diagCodeRef[chargeItem.proc_code].code;
        let diagFound = false;
        const existingRefs = findExistingRefs(chargeItem);

        for (let i = 1; i <= 8 && !diagFound; i++) {
          const diagKey = `diag_${i}`;
          if (claim[diagKey]) {
            try {
              const diagObj = JSON.parse(claim[diagKey]);
              if (diagObj.code === targetCode) {
                const ref = String.fromCharCode(64 + i); // Convert 1 to 'A', 2 to 'B', etc.
                if (!existingRefs.includes(ref)) {
                  updateFirstAvailableRef(chargeItem, ref);
                }
                diagFound = true;
              }
            } catch (e) {
              console.error("Error parsing diag:", e);
            }
          }
        }

        if (!diagFound) {
          for (let i = 1; i <= 8; i++) {
            const diagKey = `diag_${i}`;
            if (!claim[diagKey]) {
              const newDiag = JSON.stringify({ code: targetCode, internalCode: targetCode });
              claim[diagKey] = newDiag;
              const ref = String.fromCharCode(64 + i);
              updateFirstAvailableRef(chargeItem, ref);
              break;
            }
          }
        }
      }
    });

    return claim;
  };

  // handle Claim Validattion
  const handleClaimvalidation = async (claim, claimData, claimStatus) => {
    if (!selectedEmployee) {
      setError("Please Select an Employee");
      return;
    }

    const tempClaim = !claimData ? { ...claim, proc_array: dataList } : claim;

    const insuranceObj = insurancesList.find((item) => item.payerId === tempClaim?.payerid);

    const checkError = await isAllDiagAndProcCodeValidation({ tempClaim, claimStatus, insuranceObj });

    if (checkError) {
      setError(checkError);
      return true;
    }
    return false;
  };

  const getPayerName = (name) => {
    if (!name.includes(":")) {
      return name;
    }
    const parts = name.split(":");
    const afterColon = parts[1] ? parts[1].trim() : "";
    return afterColon || name;
  };

  const onHandleSave = async ({ isNew, claimStatus, claimData, claimtype, isEligibilityChecked }) => {
    if (
      !isNew &&
      permission?.claims !== undefined &&
      permission?.claims.editClaims !== undefined &&
      permission?.claims.editClaims === false
    ) {
      appContext.showErrorMessage("This user has not permission to edit claim.");
      return;
    }

    const claim = claimData ? { ...claimData } : { ...newClaim };
    const modifiedObj = claim;

    Object.assign(modifiedObj, {
      ins_name_f: claim.pat_name_f,
      ins_name_l: claim.pat_name_l,
      ins_name_m: claim.pat_name_m,
      ins_sex: claim.pat_sex,
      ins_addr_1: claim.pat_addr_1,
      ins_addr_2: claim.pat_addr_2,
      ins_city: claim.pat_city,
      ins_state: claim.pat_state,
      ins_dob: claim.pat_dob,
      ins_zip: claim.pat_zip,
      proc_array: dataList,
      payer_name: getPayerName(claim.payer_name),
      note: "",
    });

    const emp = claim.employee;

    // Handle Claim Validation
    const isCliamError = await handleClaimvalidation(claim, claimData, claimStatus);
    if (isCliamError) {
      return;
    }

    if (Object.keys(dataList).length > 0 || claim.proc_array.length > 0) {
      const claimObjWithProcArray = Object.assign(modifiedObj, {
        proc_array: claimData ? claim.proc_array : dataList,
        status: claimStatus || "draft",
        updatedBy: loginUser.sub,
        updatedByName: loginUser.name,
        ...(modifiedObj.isInjury && {
          employment_related: "Y",
          auto_accident: modifiedObj.isAccident ? "Y" : "N",
          auto_accident_state: modifiedObj.stateOfInjury,
          cond_date: moment(modifiedObj.injuryDate).format("YYYYMMDD"),
        }),
      });

      let mergedObj = updateClaimWithDiagRef(claimObjWithProcArray);

      if (prevClaims && prevClaims.length > 0 && loginUser.phone_number !== usersNo.ibrahim) {
        if (
          prevClaims
            .filter((f) => f.status !== CLAIM_SUBMIT_STATUS.cancelled)
            .some((f) => f.from_date_1 === claim?.proc_array[0].from_date)
        ) {
          setError("Date of Service is already assigned to some other claim!");
          return;
        }
      }

      mergedObj = await addModifireInProcArray(mergedObj);
      // TODO:: Need to manage the Update Call and Submitt Call Later
      //if claim is in submitted state and want to update CPT and Diagonisis
      // if (oldClaim?.status === CLAIM_SUBMIT_STATUS.submitted && claimStatus === CLAIM_SUBMIT_STATUS.submitted) {
      //   const isClaimModified = isClaimDataModified(claim, oldClaim);
      //   if (isClaimModified) {
      //     if (!newClaim.reasonForSubmit) {
      //       setError("Please add the reason for modification");
      //       return;
      //     }
      //     const claimObjectToUpdate = formatEmployeeClaim(mergedObj, setting);
      //     dispatch(
      //       updateSubmittedClaim({ ...claimObjectToUpdate, updatedBy: loginUser.sub, updatedByName: loginUser.name })
      //     );
      //   }
      //   handleClose(true);
      //   return;
      // }

      if (mergedObj.isHospice) {
        mergedObj = addHospiceModifireInClaimProcArray(mergedObj);
      }

      if (claimStatus === CLAIM_SUBMIT_STATUS.denied || claimStatus === CLAIM_SUBMIT_STATUS.rejected) {
        //  Call Resolve Error Messages Function For Denied or rejected Claims
        resolveErrorMessages(claim, mergedObj);
      }

      let claimRes = null;

      // Check Claim onHold or Not
      if (isClaimOnHold(claimStatus, mergedObj)) {
        return;
      }

      dispatch(setLoader(true));

      try {
        // Fetch Insurance Record For Check to Not Submit Claim onto Claim MD or not check Eligibility
        await fetchInsuranceWithPayerID(mergedObj);

        // if Eligibility is Not Checked then Check it
        if (!isEligibilityChecked) {
          const res = await handleCheckEligibility(emp, mergedObj);

          if (claimStatus === CLAIM_SUBMIT_STATUS.submitted && res && res.error && !ignoreEligibility) {
            // Change status of draft only for biolab when eligibility failed
            // if (CONFIG.isBioLab && !res.error[0]?.error_mesg?.includes("real-time")) {
            //   claimStatus = CLAIM_SUBMIT_STATUS.draft;
            //   mergedObj.status = CLAIM_SUBMIT_STATUS.draft;
            // }

            const eligibilityError = Array.isArray(res?.error) ? res.error[0]?.error_mesg : res.error?.error_mesg;

            const isRealTimeEligibilityFail =
              eligibilityError.includes("real-time") || eligibilityError.includes("routing issue");

            if (!isRealTimeEligibilityFail) {
              dispatch(setMessage(eligibilityError || "Not Eligible", MESSAGE_MODES.error));
              dispatch(setLoader(false));
              return;
            }

            Object.assign(mergedObj, {
              note: eligibilityError,
            });
          }

          // Check Submitted Claim Eligibility for Hospice
          const responseEligibility = await checkSubmitClaimEligibilityForHospice(
            emp,
            mergedObj,
            claimStatus,
            claimtype,
            res,
            isNew
          );

          if (!responseEligibility) return;
        }

        if (isNew) {
          const setting = await api.getCompanySetting();
          const seqNumber = await api.getEmployeeNo("c", setting.id);

          Object.assign(mergedObj, { remote_claimid: `CL-${setting.code}-${seqNumber.toString().padStart(6, 0)}` });
          const claimSubmission = formatEmployeeClaim(mergedObj, setting);

          await dispatch(createEmployeeClaim(claimSubmission, seqNumber, claimStatus, claimtype));

          claimRes = true;
        } else {
          claimRes = await handleUpdateClaim(mergedObj, claimStatus, claimtype);
        }
        dispatch(setLoader(false));
        if (claimRes) handleClose(true);
      } catch (err) {
        console.log("Error:-", err);
        dispatch(setLoader(false));
      }
    }
  };

  const handleUpdateClaim = async (mergedObj, claimStatus, claimtype) => {
    const claimSubmission = formatEmployeeClaim(mergedObj, setting);
    await dispatch(updateEmployeeClaim(claimSubmission, claimStatus, claimtype, oldClaim));
    //TODO:: Need to Check why we are updating this code
    if (changePatientData) {
      const res = await api.updateEmployeewithClaims(mergedObj);

      dispatch(updateEmployeeFromClaim(employeeParser([res])[0]));
    }
    return true;
  };

  const handleCheckEligibility = async (emp, claim) => {
    if (emp && CUSTOM_INS.includes(claim.payerid)) {
      return { status: "pass" };
    }
    let response = "";

    const modifiedEmp = { ...emp, insuranceCompany: claim.payerid, medicalNo: claim.ins_number };

    if (
      emp?.eligibilityStatus &&
      emp.eligibilityStatus.createdAt &&
      emp.eligibilityStatus.createdAt.slice(0, 10) === moment().format("YYYY-MM-DD")
    ) {
      const item = emp.eligibilityStatus;
      const data = JSON.parse(item?.apiData || "{}");
      if (data.fileName) {
        try {
          let res = await Storage.get(`${data.fileName}`, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });

          const string = await res.Body.text();
          const apidata = JSON.parse(string);

          if (apidata?.elig) {
            response = apidata?.elig || "";
          } else {
            response = await api.checkManualEligibility(
              { ...modifiedEmp, fdos: moment(claim.proc_array[0]?.from_date).format("YYYYMMDD") },
              loginUser
            );
          }
        } catch (err) {
          console.log("Error:-", err.message);
          dispatch(setMessage(err.message, MESSAGE_MODES.error));
        }
      }
    } else {
      response = await api.checkManualEligibility(
        { ...modifiedEmp, fdos: moment(claim.proc_array[0]?.from_date).format("YYYYMMDD") },
        loginUser
      );
    }

    return response;
  };

  const handleEdit = (data) => {
    setEditObj(data);
    setProcedure(true);
  };

  const handleUpdate = (item) => {
    let newArray = dataList?.map((obj) => {
      if (obj.id === item.id) {
        return item;
      } else {
        return obj;
      }
    });
    newArray = newArray.map((obj) => {
      return { ...obj, from_date: item.from_date };
    });
    setDataList(newArray);
    setEditObj({});
    setProcedure(false);
  };

  const handleDelete = (item) => {
    const newArray = dataList?.filter((obj) => {
      if (obj.id !== item.id) {
        return item;
      }
    });
    setDataList(newArray);
  };

  const handleUpdatePatientAddress = (val, flag) => {
    if (!flag) {
      setNewClaim({ ...newClaim, pat_addr_1: val });
    } else {
      const addressObj = {
        pat_addr_1: val.street,
        pat_country: val.country,
        pat_city: val.city,
        pat_zip: val.zipcode,
        pat_state: val.state,
        isoCode: val.isoCode,
      };
      setNewClaim({ ...newClaim, ...addressObj });
    }
  };

  // Selected ICT Array
  const getSelectedDiagnosis = (str) => {
    return DIAGNOSIS_ITEMS.map((m) => getDiagonosisCode(newClaim[`diag_${PARSE_ALPHA_INTO_NUM[m]}`])).filter(
      (f) => f && f !== getDiagonosisCode(str)
    );
  };

  const CustomControlRenderingProv = ({ children, ...props }) => (
    <selectComponents.ValueContainer {...props}>
      <div
        className="customProviderText"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setProviderObj(providers.find((prov) => prov.id === newClaim.provider?.prov_id));
        }}
      >
        {children}
      </div>
    </selectComponents.ValueContainer>
  );

  const componentOptionsRenderingProv = {
    ValueContainer: CustomControlRenderingProv,
  };

  const createMember = async (updatedUser) => {
    if (updatedUser.isNew) {
      dispatch(createEmployee(updatedUser));
    } else {
      let refProvider = null;
      let ordProvider = null;
      let provider = null;
      if (updatedUser.referringProvider) {
        refProvider = providers.find((f) => f.id === updatedUser.referringProvider);
      }
      if (updatedUser.orderingProvider) {
        ordProvider = providers.find((f) => f.id === updatedUser.orderingProvider);
      }
      if (updatedUser.renderingProvider) {
        provider = providers.find((f) => f.id === updatedUser.renderingProvider);
      }
      dispatch(updateEmployee(updatedUser));

      if (loginUser?.phone_number === usersNo.ibrahim || CONFIG.isLabType) {
        const claims = await api.getEmployeeClaimsAPI(updatedUser.id);
        setUpdatePatient({ updatedUser, provider, refProvider, ordProvider });
        setClaimList(
          claims.filter(
            (f) =>
              f.status === CLAIM_SUBMIT_STATUS.denied ||
              f.status === CLAIM_SUBMIT_STATUS.rejected ||
              f.status === CLAIM_SUBMIT_STATUS.draft
          )
        );
        // await api.updateClaims(updatedUser, loginUser, provider, refProvider, ordProvider);
      }
    }
    // await appContext.resetEmployees();
  };

  const formatEmp = (emp) => {
    return {
      ...emp,
      gender: emp.sex,
      ...(userSubAgent && {
        subAgentID: userSubAgent.id,
        companyID: userSubAgent.locationID,
        clientID: userSubAgent.clientID,
      }),
    };
  };

  const employeeParser = (data) => {
    const emps = formatEmployeesData(data, subAgents || [], locations || [], clients || [], providers || []);
    return emps;
  };
  const handleEmployeeSelectionChange = async (e) => {
    await getEmployeeClaims(e.id);

    setSelectedEmployee(e);
    formatClaimData([e]);
  };

  const handleERAModal = () => {
    setOpenEraModal(newClaim);
  };
  const handleERALog = () => {
    setOpenOrderDetail(true);
    setSelectedOrder(newClaim);
  };
  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render
    return (
      <ErrorMessageModal
        error={error.message}
        handleClose={() => {
          dispatch(setClaimReSubmissionModal(null));
          setOpenOrderDetail(false);
        }}
      />
    );
  };

  const showCreateUpdateButton = (claimStatus) => {
    return (
      !claimStatus ||
      claimStatus === CLAIM_SUBMIT_STATUS.draft ||
      claimStatus === CLAIM_SUBMIT_STATUS.rejected ||
      claimStatus === CLAIM_SUBMIT_STATUS.submitted ||
      claimStatus === CLAIM_SUBMIT_STATUS.denied
    );
  };

  const updateClientLocationId = async (e) => {
    setNewClaim({
      ...newClaim,
      locationName: e.name,
      locationID: e.id,
    });
  };

  const onClickEligibility = async () => {
    try {
      if (!newClaim.payerid) {
        dispatch(setMessage("Please select the payer", MESSAGE_MODES.error));
        return;
      }

      if (!newClaim.pat_name_f) {
        dispatch(setMessage("Patient Information Required", MESSAGE_MODES.error));
        return;
      }

      dispatch(setLoader(true));
      let fdos =
        Array.isArray(newClaim.proc_array) && newClaim.proc_array.length > 0
          ? moment(newClaim.proc_array[0]?.from_date).format("YYYYMMDD")
          : moment().format("YYYYMMDD");

      const modifiedEmp = {
        ...newClaim,
        firstName: newClaim.pat_name_f,
        lastName: newClaim.pat_name_l,
        middleName: newClaim.pat_name_m,
        phoneNumber: newClaim.pat_phone,
        countryCode: newClaim.pat_country,
        dob: newClaim.pat_dob,
        street: newClaim.pat_addr_1,
        street2: newClaim.pat_addr_2,
        zip: newClaim.pat_zip,
        city: newClaim.pat_city,
        state: newClaim.pat_state,
        medicalNo: newClaim.ins_number,
        userName: user.name,
        userID: user.sub,
        insuranceCompany: newClaim.payerid,
        medicalNo: newClaim.ins_number,
        fdos,
        notLinkwithEmployee: true,
      };

      const res = await api.checkManualEligibility(
        { ...modifiedEmp, fdos: moment(newClaim.proc_array[0]?.from_date).format("YYYYMMDD") },
        loginUser
      );

      let dataToView = null;

      if (!res.error) {
        dataToView = {
          ...res.data,
          payerId: modifiedEmp.insuranceCompany,
          createdAt: moment().utc().toISOString(),
          updatedAt: fdos,
          message: "Success",
        };
      } else {
        dataToView = {
          ...modifiedEmp,
          payerId: modifiedEmp.insuranceCompany,
          createdAt: moment().utc().toISOString(),
          updatedAt: fdos,
          message: "Unuccess",
        };
      }
      dispatch(setEligibilityDetailsData(dataToView));
    } catch (ex) {
      console.log("Ex", ex);
      dispatch(setLoader(false));
    }
  };

  const onClickViewResult = async () => {
    if (newClaim.accNo) {
      dispatch(setResultView({ ...newClaim, reqType: "r" }));
    }
  };

  const onClickViewReqForm = async () => {
    if (newClaim.accNo) {
      dispatch(setResultView({ ...newClaim, reqType: "req" }));
    }
  };

  const labelsArray = [
    { label: "Req Form", event: onClickViewReqForm },
    { label: "View Result", event: onClickViewResult },
  ];

  return (
    <Modal show backdrop="static" animation onHide={() => handleClose()} centered size={"2x2"} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          {`Claim Info ${newClaim.status ? `(${newClaim.status})` : ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <div className="d-md-flex  justify-content-between">
              <Col md="12">
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <div className="patient-info-title">
                        <h4 className="text-decoration-underline text-black mt-0 section-title">Patient Info</h4>
                        {selectedEmployee && (
                          <i
                            className="fas fa-edit cursor-pointer"
                            onClick={() => setEmployeeObjToUpdate(selectedEmployee)}
                          />
                        )}
                      </div>
                      <div>
                        {newClaim.bill_npi && (
                          <span
                            className="d-flex flex-column align-items-center text-secondary px-3 linkedText"
                            onClick={() => setShowLabInfoModal(newClaim.bill_npi)}
                          >
                            Facility Info
                          </span>
                        )}
                        <span className="text-secondary px-3">Patient Account #: {newClaim.pcn}</span>
                      </div>
                      {employeeData?.insuranceDetails[1]?.insuranceCompany && (
                        <div>
                          <Form.Group className="d-flex align-items-end">
                            <Form.Label className="my-0 modalLineHeaders w-100">Choose Insurance Info:</Form.Label>
                            <div className="d-flex justify-content-between">
                              <RadioButton
                                name="showInsurance"
                                id={INSURANCE_TYPE.primary}
                                checked={insuranceInfo === INSURANCE_TYPE.primary ? true : false}
                                label="Primary"
                                handleChange={(e) => setInsuranceInfo(INSURANCE_TYPE.primary)}
                                cssClass={"mx-1"}
                              />
                              <RadioButton
                                id={INSURANCE_TYPE.secondary}
                                checked={insuranceInfo === INSURANCE_TYPE.secondary ? true : false}
                                name="showInsurance"
                                label="Secondary"
                                handleChange={(e) => setInsuranceInfo(INSURANCE_TYPE.secondary)}
                                cssClass={"mx-1"}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      )}
                    </div>
                    <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                      <div className={"crew-profile-info crew-profile-row crew-profile-again"}>
                        {filteredEmps.length > 0 && (
                          <Form.Group className="form-group-wrapper">
                            <Form.Label className="mt-0 text-capitalize">Select Patient</Form.Label>
                            {/* <Select
                              options={filteredEmps}
                              blurInputOnSelect={true}
                              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                              getOptionValue={(option) => option.id}
                              menuPlacement="auto"
                              autoComplete="none"
                              placeholder="Select Patient"
                              inputValue={empSearch}
                              onInputChange={(newVal) => setEmpSearch(newVal)}
                              onChange={handleEmployeeSelectionChange}
                              isDisabled={isDisabled}
                            /> */}
                            <InputSelectDropDown
                              optionList={filteredEmps}
                              handleChange={(value) => {
                                setEmpSearch(value);
                              }}
                              placeholder={"Search Patient"}
                              value={empSearch}
                              setSelectedEmployee={(value) => handleEmployeeSelectionChange(value)}
                            />
                          </Form.Group>
                        )}
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="First Name"
                          value={newClaim.pat_name_f}
                          index="pat_name_f"
                          placeholder="First Name"
                          disabled={isDisabled}
                          errorList={errorList}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              pat_name_f: getValidDep(e.target.value),
                            })
                          }
                          required
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Middle Name"
                          value={newClaim.pat_name_m}
                          index="pat_name_m"
                          errorList={errorList}
                          placeholder="Middle Name"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              pat_name_m: getValidDep(e.target.value),
                            })
                          }
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Last Name"
                          value={newClaim.pat_name_l}
                          index="pat_name_l"
                          errorList={errorList}
                          placeholder="Last Name"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              pat_name_l: getValidDep(e.target.value),
                            })
                          }
                          required
                        />
                        <InputField
                          type="phone"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Phone"
                          value={newClaim.pat_phone}
                          index="pat_phone"
                          errorList={errorList}
                          maxLength={65}
                          placeholder="Phone"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              pat_phone: e,
                            })
                          }
                        />
                        <div className="d-flex personal-info-cols-divide column-gap-10">
                          <div className="updated-middle-box w-100 first-col">
                            <InputField
                              type="datePicker"
                              inputStyle="w-100 orderTestDob"
                              labelStyle="text-capitalize"
                              label="DOB"
                              value={newClaim.pat_dob}
                              index="pat_dob"
                              errorList={errorList}
                              placeholder="DOB"
                              disabled={isDisabled}
                              handleChange={(e) =>
                                setNewClaim({
                                  ...newClaim,
                                  pat_dob:
                                    moment(e).format("YYYY-MM-DD") != "Invalid date"
                                      ? moment(e).format("YYYY-MM-DD")
                                      : "",
                                })
                              }
                              minDate={new Date("1900-01-01")}
                              maxDate={new Date()}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="crew-profile-info crew-profile-row crew-profile-again">
                        <div>
                          <Form.Label className="mt-0 text-capitalize">
                            Gender <span className="fw-bold fs-6 maroon">*</span>
                          </Form.Label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            <RadioButton
                              name="gender"
                              id="male"
                              label="Male"
                              checked={newClaim?.pat_sex === "M" ? true : false}
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewClaim({
                                  ...newClaim,
                                  pat_sex: e.target.checked ? "M" : "",
                                })
                              }
                            />
                            <RadioButton
                              name="gender"
                              id="female"
                              checked={newClaim?.pat_sex === "F" ? true : false}
                              label="Female"
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewClaim({
                                  ...newClaim,
                                  pat_sex: e.target.checked ? "F" : "",
                                })
                              }
                            />
                            <RadioButton
                              id="other"
                              checked={newClaim?.pat_sex === "X" ? true : false}
                              name="gender"
                              label="Not Specified"
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewClaim({
                                  ...newClaim,
                                  pat_sex: e.target.checked ? "X" : "",
                                })
                              }
                            />
                          </div>
                        </div>
                        <InputField
                          type="address"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Address"
                          value={newClaim.pat_addr_1}
                          index="pat_addr_1"
                          errorList={errorList}
                          maxLength={65}
                          placeholder="Address"
                          disabled={isDisabled}
                          handleChange={(val, flag) => handleUpdatePatientAddress(val, flag)}
                          required
                        />

                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Apt/Unit#"
                          value={newClaim.pat_addr_2}
                          errorList={errorList}
                          index="pat_addr_2"
                          maxLength={65}
                          placeholder="Apt/Unit#"
                          disabled={isDisabled}
                          handleChange={(e) => setNewClaim({ ...newClaim, pat_addr_2: e.target.value })}
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="City"
                          maxLength={65}
                          value={newClaim.pat_city}
                          disabled={isDisabled}
                          placeholder="City"
                          handleChange={(e) => setNewClaim({ ...newClaim, pat_city: e.target.value })}
                          required
                        />

                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          value={newClaim.pat_state}
                          errorList={errorList}
                          index="pat_state"
                          placeholder="Enter State"
                          maxLength={10}
                          label="State"
                          disabled={isDisabled}
                          handleChange={(e) => {
                            setNewClaim({
                              ...newClaim,
                              pat_state: e.target.value,
                            });
                          }}
                          required
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          value={newClaim.isoCode === "US" ? newClaim.pat_zip : newClaim.pat_zip}
                          placeholder="Enter Zip Code"
                          errorList={errorList}
                          index="pat_zip"
                          maxLength={10}
                          label="Zip"
                          disabled={isDisabled}
                          handleChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setNewClaim({
                              ...newClaim,
                              pat_zip: newClaim.isoCode === "US" ? formatZip(val) : getValidSpaces(val),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3 crew-from-column">
                    <div className="personal-info-row-1">
                      {/* <h4 className="text-decoration-underline text-black mt-0">Insurance Info</h4> */}
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex single-block-wrapper">
                          {CONFIG.isBioLab ? (
                            <div className="justify-content-between d-flex column-gap-15 align-items-center mb-0 m-0 secondary-insurance-head">
                              {/* <InputCPTCodeDropDown
                                type="text"
                                inputStyle="modalInput"
                                labelStyle="text-capitalize"
                                label="Payer:"
                                groupWrapper="w-100"
                                placeholder="Select Payer"
                                errorList={errorList}
                                index={"payer_name"}
                                readOnly={isDisabled}
                                optionList={[]}
                                value={newClaim.payer_name}
                                handleChange={(opt) => {
                                  if (opt) {
                                    setNewClaim({
                                      ...newClaim,
                                      payer_name: opt.Name,
                                      payerid: opt.payerId,
                                      bill_npi: opt.npi,
                                    });
                                  } else {
                                    setNewClaim({ ...newClaim, payer_name: "", payerid: "", bill_npi: "" });
                                  }
                                }}
                                searchtype="Payer"
                                required
                              /> */}

                              <InputField
                                type="dropDown"
                                options={payerList}
                                labelStyle="text-capitalize"
                                label={
                                  loginUser?.phone_number.includes("+92")
                                    ? `Payer: ${newClaim?.payerid || ""}`
                                    : "Payer"
                                }
                                placeholder="Select Payer"
                                errorList={errorList}
                                index={"payer_name"}
                                readOnly={isDisabled}
                                optionList={[]}
                                value={newClaim.payerid ? payerList.find((f) => f.value === newClaim.payerid) : null}
                                handleChange={(opt) => {
                                  if (opt) {
                                    setNewClaim({
                                      ...newClaim,
                                      payer_name: opt.payer_name || opt.label,
                                      payerid: opt.value,
                                    });
                                  } else {
                                    setNewClaim({ ...newClaim, payer_name: "", payerid: "", bill_npi: "" });
                                  }
                                }}
                                searchtype="Payer"
                                required
                              />
                              <div className="plus-icon">
                                <i
                                  className="fas fa-plus-circle pt-3 fa-lg cursor-pointer w-auto"
                                  onClick={() => {
                                    dispatch(setOpenInsuranceModal(true));
                                    dispatch(
                                      setSelectedInsurance({ ...INSURANCE_EMPTY_OBJECT, clientID: userCompanyID.get() })
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <CustomLabel wrapperClass="w-50" label="Check Eligibility" handleClick={onClickEligibility}>
                              <InputField
                                type="dropDown"
                                options={payerList}
                                labelStyle="text-capitalize"
                                label="Payer:"
                                groupWrapper="w-100"
                                placeholder="Select Payer"
                                errorList={errorList}
                                index={"payer_name"}
                                readOnly={isDisabled}
                                optionList={[]}
                                value={newClaim.payerid ? payerList.find((f) => f.value === newClaim.payerid) : null}
                                handleChange={(opt) => {
                                  if (opt) {
                                    setNewClaim({
                                      ...newClaim,
                                      payer_name: opt.label,
                                      payerid: opt.value,
                                    });
                                  } else {
                                    setNewClaim({ ...newClaim, payer_name: "", payerid: "", bill_npi: "" });
                                  }
                                }}
                                searchtype="Payer"
                                required
                              />
                            </CustomLabel>
                          )}

                          <InputField
                            type="text"
                            groupWrapper="insuredNumberField"
                            inputStyle="modalInput"
                            labelStyle="text-capitalize"
                            label="Member ID:"
                            value={newClaim.ins_number}
                            errorList={errorList}
                            index="ins_number"
                            placeholder="Member ID"
                            disabled={isDisabled}
                            handleChange={(e) => {
                              let val = e.target.value;
                              setNewClaim({
                                ...newClaim,
                                ins_number: convertToUpper(val),
                              });
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="personal-info-row-2">
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex single-block-wrapper">
                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={renderingProvidersOptions}
                            inputStyle="preserve-spaces"
                            labelStyle="text-capitalize"
                            label="Rendering Provider"
                            value={
                              newClaim.provider?.prov_id
                                ? renderingProvidersOptions.find((f) => f.value === newClaim.provider?.prov_id)
                                : null
                            }
                            index="prov_npi"
                            isClearable={true}
                            placeholder="Select Rendering Provider"
                            disabled={isDisabled}
                            errorList={errorList}
                            // componentOptions={componentOptionsRenderingProv}
                            handleChange={(e) => {
                              if (e) {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    prov_id: e.id,
                                    prov_npi: e.npi,
                                    prov_name_f: e.firstName,
                                    prov_name_l: e.lastName,
                                    prov_name_m: e.middleName,
                                    prov_taxid: e.taxid,
                                  },
                                });
                              } else {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    prov_id: "",
                                    prov_npi: "",
                                    prov_name_l: "",
                                    prov_name_f: "",
                                    prov_name_m: "",
                                    prov_taxid: "",
                                  },
                                });
                              }
                            }}
                          />

                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={providersOptions}
                            labelStyle="text-capitalize"
                            inputStyle="preserve-spaces"
                            label="Referring Physician"
                            value={
                              newClaim.provider?.ref_id
                                ? providersOptions.find((f) => f.value === newClaim.provider?.ref_id)
                                : null
                            }
                            index="ref_npi"
                            isClearable={true}
                            placeholder="Select Referring Physician"
                            disabled={isDisabled}
                            errorList={errorList}
                            handleChange={(e) => {
                              if (e) {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    ref_id: e.id,
                                    ref_npi: e.npi,
                                    ref_name: e.name,
                                    ref_name_f: e.firstName,
                                    ref_name_l: e.lastName,
                                    ref_name_m: e.middleName,
                                  },
                                });
                              } else {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    ref_id: "",
                                    ref_npi: "",
                                    ref_name: "",
                                    ref_name_l: "",
                                    ref_name_f: "",
                                    ref_name_m: "",
                                  },
                                });
                              }
                            }}
                          />

                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={providersOptions}
                            labelStyle="text-capitalize"
                            inputStyle="preserve-spaces"
                            label="Ordering Provider"
                            value={
                              newClaim.provider?.ord_prov_id
                                ? providersOptions.find((f) => f.value === newClaim.provider?.ord_prov_id)
                                : null
                            }
                            index="ord_prov_npi"
                            isClearable={true}
                            placeholder="Select Ordering Provider"
                            disabled={isDisabled}
                            errorList={errorList}
                            handleChange={(e) => {
                              if (e) {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    ord_prov_id: e.id,
                                    ord_prov_npi: e.npi,
                                    ord_prov_name: e.name,
                                    ord_prov_name_f: e.firstName,
                                    ord_prov_name_l: e.lastName,
                                    ord_prov_name_m: e.middleName,
                                  },
                                });
                              } else {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    ord_prov_id: "",
                                    ord_prov_npi: "",
                                    ord_prov_name: "",
                                    ord_prov_name_l: "",
                                    ord_prov_name_f: "",
                                    ord_prov_name_m: "",
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: "auto" }} className="personal-info-row-3">
                      {/* <h4 className="text-decoration-underline text-black mt-0">CLIA Info</h4> */}
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex">
                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={cliList}
                            labelStyle="text-capitalize"
                            label="CLIA Number:"
                            value={
                              newClaim.clia_number
                                ? { cliCode: newClaim.clia_number, cliCode: newClaim.clia_number }
                                : null
                            }
                            index="clia_number"
                            isClearable={true}
                            placeholder="Select CLIA Number"
                            getOptionLabel={(option) => option.cliCode}
                            getOptionValue={(option) => option.cliCode}
                            disabled={isDisabled}
                            errorList={errorList}
                            handleChange={(e) => {
                              setNewClaim({ ...newClaim, clia_number: e?.cliCode || "" });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <div className="d-flex gap-3">
                  <Card className="mb-3">
                    <div className="personal-info-wrapper py-3 px-3">
                      <h4 className="text-decoration-underline text-black mt-0 section-title">Diagnosis Code</h4>
                      <div className="crew-profile-complete crew-profile-again d-flex">
                        {DIAGNOSIS_ITEMS.map((item, index) => {
                          return (
                            <InputCPTCodeDropDown
                              key={index}
                              tabIndex={index + 1}
                              type="text"
                              inputStyle="modalInput"
                              labelStyle="text-capitalize"
                              label={item}
                              groupWrapper="section-item"
                              placeholder={"Search Code"}
                              errorList={errorList}
                              index={`diag_${PARSE_ALPHA_INTO_NUM[item]}`}
                              readOnly={isDisabled}
                              optionList={[]}
                              selectedDiagnosis={getSelectedDiagnosis(newClaim[`diag_${PARSE_ALPHA_INTO_NUM[item]}`])}
                              value={getDiagonosisInternalCodeValue(newClaim[`diag_${PARSE_ALPHA_INTO_NUM[item]}`])}
                              handleChange={(opt) =>
                                setNewClaim({
                                  ...newClaim,
                                  [`diag_${PARSE_ALPHA_INTO_NUM[item]}`]: opt
                                    ? JSON.stringify({ code: opt?.value, internalCode: opt?.internalCode })
                                    : "",
                                })
                              }
                              searchtype="ict"
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Card>
                  <Card className="mb-3 worker-compenesation-wrapper p-3 crew-member-profile-inner personal-info-wrapper ">
                    <div className="d-flex justify-content-start align-items-center gap-3 worker-componesation">
                      <CheckBox
                        id="isInjury"
                        value={newClaim?.isInjury}
                        name="isInjury"
                        checked={newClaim?.isInjury}
                        label="Worker Componesation"
                        customStyle
                        customWidth="w-100 justify-content-start"
                        handleChange={(e) =>
                          setNewClaim({
                            ...newClaim,
                            isInjury: e.target.checked,
                            ...(!e.target.checked && {
                              isAccident: false,
                              injuryDate: null,
                              stateOfInjury: null,
                            }),
                          })
                        }
                      />
                      <CheckBox
                        id="isAccident"
                        value={newClaim?.isAccident}
                        name="isAccident"
                        checked={newClaim?.isAccident}
                        label="Accident"
                        customStyle
                        customWidth="w-35"
                        handleChange={(e) =>
                          setNewClaim({
                            ...newClaim,
                            isAccident: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-3">
                      <InputField
                        type="datetime-local"
                        inputStyle="modalInput inputCptDropDownField"
                        labelStyle="text-capitalize"
                        groupWrapper="w-auto"
                        label="Accident Date"
                        index="ingDate"
                        placeholder="Accident Date"
                        value={moment(newClaim?.injuryDate).format("YYYY-MM-DDTHH:mm") || ""}
                        maxlength={35}
                        errorList={errorList}
                        handleChange={(e) =>
                          setNewClaim({
                            ...newClaim,
                            injuryDate: moment(e.target.value).utc().format(),
                          })
                        }
                        disabled={newClaim?.isInjury ? !newClaim.isInjury : true}
                      />
                      <InputField
                        type="text"
                        inputStyle="modalInput inputCptDropDownField"
                        labelStyle="text-capitalize"
                        groupWrapper="w-35"
                        label="State"
                        index="stateOfInjury"
                        placeholder="State"
                        value={newClaim?.stateOfInjury || ""}
                        errorList={errorList}
                        handleChange={(e) =>
                          setNewClaim({
                            ...newClaim,
                            stateOfInjury: e.target.value,
                          })
                        }
                        disabled={newClaim?.isInjury ? !newClaim.isInjury : true}
                      />
                    </div>
                  </Card>
                </div>
                <section className="d-flex gap-3 crew-member-profile-inner personal-info-wrapper">
                  <Card className="w-75 procedure-info-width mb-3">
                    <div className="personal-info-wrapper pt-3 px-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="text-decoration-underline text-black mt-0 section-title">Procedure Info</h4>
                        <div className="plus-icon" style={{ fontSize: "x-large" }}>
                          <i
                            className="fas fa-plus-circle fa-lg cursor-pointer w-auto"
                            onClick={() => {
                              if (!isDisabled) {
                                if (DIAGNOSIS_ITEMS.some((key) => newClaim[`diag_${PARSE_ALPHA_INTO_NUM[key]}`])) {
                                  setProcedure(true);
                                } else {
                                  setError("Please Select At least one Diagnosis Code");
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="data-listing-form table-wrapper mb-0">
                          <div>
                            <Table>
                              <thead>
                                <tr className="data-listing-row">
                                  <th />
                                  {PROCEDURE_CODE_HEADER.map((title) => (
                                    <th key={title} className="centered">
                                      {title}
                                    </th>
                                  ))}
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {dataList?.map((data, index) => (
                                  <ProcedureListView
                                    key={index}
                                    index={index}
                                    data={data}
                                    handleEdit={handleEdit}
                                    isDisabled={isDisabled}
                                    newClaim={newClaim}
                                    handleDelete={handleDelete}
                                    eraList={eraList}
                                    errorList={errorList}
                                  />
                                ))}

                                {newClaim.total_charge ? (
                                  <tr index={dataList.length}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-center">
                                      <span>{currencyformatter.format(totalBilledAmount)}</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {procedure && (
                          <ProcedureModefierModal
                            data={editObj}
                            payer={
                              newClaim?.payerid ? insurancesList.find((f) => f.payerId === newClaim.payerid) : null
                            }
                            handleClose={() => {
                              setProcedure(false);
                              setEditObj({});
                            }}
                            isHospice={selectedEmployee?.isHospice}
                            hospiceCode={
                              MEDICARE_ID.includes(newClaim.payerid) ? selectedEmployee?.codes?.hospiceModifer : ""
                            }
                            prevClaims={prevClaims}
                            handleAddData={handleAddData}
                            handleUpdate={handleUpdate}
                            claimData={newClaim}
                            cssClass="seperateModal"
                            dataList={dataList}
                          />
                        )}
                        {reasonModal && (
                          <ClaimResolveReasonModal
                            handleClose={handleCloseReasonModal}
                            cssClass="seperateModal"
                            handleCancel={() => setReasonModal(false)}
                          />
                        )}
                      </div>
                    </div>
                  </Card>
                  <div className="client-info-width">
                    <Card className="px-3 py-3 mb-3">
                      <h4 className="text-decoration-underline mb-2 text-black mt-0 section-title">
                        Special Conditions
                      </h4>
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <CheckBox
                          customWidth="tracking-1 min-h-30 h-30 justify-content-start"
                          id="homebound"
                          value={newClaim?.homeBound}
                          name="Homebound"
                          checked={newClaim?.homeBound === "Y" ? true : false}
                          label="Home Bound"
                          customStyle
                          labelStyle="text-capitalize tracking-1"
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              homeBound: e.target.checked ? "Y" : "",
                            })
                          }
                        />
                        <CheckBox
                          customWidth="tracking-1 min-h-30 h-30 justify-content-start"
                          id="hospice"
                          value={newClaim?.isHospice}
                          name="Hospice"
                          checked={newClaim?.isHospice}
                          label="Hospice"
                          customStyle
                          labelStyle="text-capitalize tracking-1"
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              isHospice: e.target.checked,
                            })
                          }
                        />
                        <CheckBox
                          customWidth="tracking-1 min-h-30 h-30 justify-content-start"
                          id="snf"
                          value={newClaim?.snf}
                          name="snf"
                          checked={newClaim?.snf}
                          labelStyle="text-capitalize tracking-1"
                          label="Skilled Nursing Facility"
                          customStyle
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              snf: e.target.checked,
                            })
                          }
                        />
                        <CheckBox
                          customWidth="tracking-1 min-h-30 h-30 justify-content-start"
                          id="isInsHousing"
                          value={newClaim?.isInsHousing}
                          name="isInsHousing"
                          checked={newClaim?.isInsHousing}
                          labelStyle="text-capitalize tracking-1"
                          label="Institutional Housing"
                          customStyle
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              isInsHousing: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </Card>
                  </div>
                </section>

                <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                  <div className="form-group-wrapper mb-0 single-block-wrapper d-flex gap-3">
                    <InputField
                      type="dropDown"
                      labelStyle="text-capitalize"
                      groupWrapper="w-50 mb-2"
                      label="Client"
                      options={LOCATION_OPTIONS}
                      value={newClaim.locationID ? LOCATION_OPTIONS.find((f) => f.id === newClaim.locationID) : null}
                      index="client"
                      placeholder="Select Client"
                      disabled={isDisabled}
                      handleChange={updateClientLocationId}
                    />
                    <CustomLabel
                      wrapperClass="w-50"
                      labelsArray={labelsArray}
                    >
                      <InputField
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        groupWrapper="w-100 mb-2"
                        label="Acc. No"
                        value={newClaim.accNo}
                        index="accNo"
                        placeholder="Accession Number"
                        disabled={isDisabled}
                        maxlength={35}
                        errorList={errorList}
                        handleChange={(e) =>
                          setNewClaim({
                            ...newClaim,
                            accNo: getValidDep(e.target.value),
                          })
                        }
                      />
                    </CustomLabel>

                    <CheckBox
                      customWidth="tracking-1 min-h-30 h-30 justify-content-start"
                      id="eligibilityIgnoreCheck"
                      value={ignoreEligibility}
                      name="eligibilityIgnoreCheck"
                      checked={ignoreEligibility}
                      labelStyle="text-capitalize tracking-1"
                      label="Submit w/o Eligibility"
                      customStyle
                      handleChange={(e) => setIgnoreEligibility(e.target.checked)}
                    />
                  </div>
                </div>

                <div className="personal-info-wrapper pt-3 px-3 crew-from-column d-block gap-0">
                  {/* <div className="personal-info-special-row">
                    <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                      <div className="form-group-wrapper d-flex single-block-wrapper">
                        <CheckBox
                          id="isInjury"
                          value={newClaim?.isInjury}
                          name="isInjury"
                          checked={newClaim?.isInjury}
                          label="Worker Componesation"
                          customStyle
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              isInjury: e.target.checked,
                              ...(!e.target.checked && {
                                isAccident: false,
                                injuryDate: null,
                                stateOfInjury: null,
                              }),
                            })
                          }
                        />

                        <CheckBox
                          id="isAccident"
                          value={newClaim?.isAccident}
                          name="isAccident"
                          checked={newClaim?.isAccident}
                          label="Accident"
                          customStyle
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              isAccident: e.target.checked,
                            })
                          }
                        />

                        <InputField
                          type="datetime-local"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          groupWrapper="w-100"
                          label="Accident Date"
                          index="ingDate"
                          placeholder="Accident Date"
                          value={moment(newClaim?.injuryDate).format("YYYY-MM-DDTHH:mm") || ""}
                          maxlength={35}
                          errorList={errorList}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              injuryDate: moment(e.target.value).utc().format(),
                            })
                          }
                          disabled={newClaim?.isInjury ? !newClaim.isInjury : true}
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          groupWrapper="w-100"
                          label="Accident state"
                          index="stateOfInjury"
                          placeholder="Accident state"
                          value={newClaim?.stateOfInjury || ""}
                          errorList={errorList}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              stateOfInjury: e.target.value,
                            })
                          }
                          disabled={newClaim?.isInjury ? !newClaim.isInjury : true}
                        />
                      </div>{" "}
                    </div>
                  </div> */}
                </div>

                <div className="d-flex align-items-center justify-content-between column-gap-15">
                  {(newClaim.status === CLAIM_SUBMIT_STATUS.rejected ||
                    newClaim.status === CLAIM_SUBMIT_STATUS.denied) &&
                    newClaim.message && (
                      <div className="w-75">
                        <h4 className="profile-title">{CapsFirstLetter(newClaim.status)} Reason</h4>
                        <div className="mb-3">
                          <textarea
                            className="form-control reason-textarea w-100"
                            ref={(el) => {
                              if (el) {
                                el.style.setProperty(
                                  "height",
                                  `${calcHeight(el.value, el.offsetWidth) + "px"}`,
                                  "important"
                                );
                              }
                            }}
                            value={newClaim?.message || ""}
                          ></textarea>
                        </div>
                      </div>
                    )}
                  {(newClaim.status === CLAIM_SUBMIT_STATUS.rejected ||
                    newClaim.status === CLAIM_SUBMIT_STATUS.denied ||
                    newClaim.status === CLAIM_SUBMIT_STATUS.submitted) && (
                    <div className="w-75">
                      <h4 className="profile-title">Comments</h4>
                      <div className="mb-3">
                        <textarea
                          className="form-control reason-textarea w-100"
                          ref={(el) => {
                            if (el) {
                              el.style.setProperty(
                                "height",
                                `${calcHeight(el.value, el.offsetWidth) + "px"}`,
                                "important"
                              );
                            }
                          }}
                          value={newClaim?.reasonForSubmit || ""}
                          onChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              reasonForSubmit: e.target.value.replace(/'/g, ""),
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>

                {isNotification && (
                  <CheckBoxBar
                    label="You Changed Patient Info. Do you Want to change Patient Demographic as well?"
                    handleChange={(e) => setChangePatientData(e.target.checked)}
                    checked={changePatientData}
                  />
                )}
              </Col>
            </div>
          </div>
        </div>
      </Modal.Body>

      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={handleConfirm}
          cssClass="seperateModal"
        />
      )}

      {openPrevClaimModal && (
        <EmployeePrevClaimsModal
          cssClass={"seperateModal"}
          user={newClaim}
          empClaimsList={prevClaims}
          handleClose={() => setOpenPrevClaimModal(false)}
          handleSaveClaim={(action, claim) => {
            setOpenPrevClaimModal(false);
            if (action === PREV_CLAIM_ACTIONS.auto) {
              setDataList(claim.proc_array);
              setNewClaim(claim);
              onHandleSave({
                isNew: true,
                claimStatus: "submitted",
                claimData: claim,
                claimtype: "claim",
                isEligibilityChecked: false,
              });
            } else if (action === PREV_CLAIM_ACTIONS.update) {
              formatClaimData(claim);
            }
          }}
        />
      )}

      {isHospice && (
        <HospiceModifierModal
          newUser={{ ...isHospice.newClaim }}
          claimtype={isHospice.claimtype}
          claimStatus={isHospice.claimStatus}
          isNew={isHospice.isNew}
          isEligibilityChecked={isHospice.isEligibilityChecked}
          handleClose={(val) => {
            if (val) setHospiceModifier(true);
            setIsHospice(null);
          }}
          handleSave={handleAddHospiceModifire}
        />
      )}

      {showLabInfoModal && (
        <LabInfoModal
          facilityNPI={showLabInfoModal}
          cssClass="seperateModal"
          handleClose={() => setShowLabInfoModal("")}
        />
      )}

      {providerObj && (
        <ProviderModal
          cssClass="seperateModal"
          show={providerObj}
          user={providerObj}
          handleClose={() => setProviderObj(null)}
          setting={setting}
          providers={providers}
        />
      )}

      {employeeObjToUpdate && (
        <EmployeeModal
          cssClass={"seperateModal"}
          user={formatEmp(employeeObjToUpdate)}
          handleClose={() => setEmployeeObjToUpdate(null)}
          handleSave={createMember}
          // resetCall={setOpenPasswordModal}
          users={employees}
        />
      )}

      {claimList && claimList.length > 0 && (
        <ViewClaimList
          empClaimsList={claimList}
          patient={updatePatient}
          handleClose={(val) => {
            if (val) {
              dispatch(fetchAllEmployeeClaims(null, 1, {}, true));
              dispatch(setMessage("Updated Claims Successfully", MESSAGE_MODES.success));
            }
            setClaimList([]);
          }}
          cssClass={"seperateModal"}
        />
      )}
      {openEraModal && (
        <EraModal user={openEraModal} handleClose={() => setOpenEraModal(null)} cssClass="seperateModal" />
      )}
      {openOrderDetail && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <OrderLogsModal
            order={selectedOrder}
            show={openOrderDetail}
            user={loginUser}
            handleClose={() => {
              setOpenOrderDetail(false);
              setSelectedOrder("");
            }}
            claim
            cssClass="seperateModal"
          />
        </ErrorBoundary>
      )}

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>

        {newClaim.status && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill mx-3 ms-auto"
            onClick={() => handleERALog()}
          >
            {"Audit Trail"}
          </Button>
        )}

        {(newClaim?.status === CLAIM_SUBMIT_STATUS.paid || newClaim?.status === CLAIM_SUBMIT_STATUS.partial) && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill mx-3  ms-auto"
            onClick={() => handleERAModal()}
          >
            {"Era Detail"}
          </Button>
        )}
        {newClaim.status === CLAIM_SUBMIT_STATUS.draft && !CUSTOM_INS.includes(newClaim.payerid) && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill mx-3 ms-auto"
            onClick={() =>
              setOpenConfirmation({
                isShow: true,
                message: `Are you sure, you want to Send Patient Claim?`,
                actionType: CONFIRMATION_TYPE.SUBMIT_CLAIM,
                title: "Claim Send",
              })
            }
          >
            Send Claim
          </Button>
        )}
        {(newClaim.status === CLAIM_SUBMIT_STATUS.rejected ||
          newClaim.status === CLAIM_SUBMIT_STATUS.denied ||
          newClaim.status === CLAIM_SUBMIT_STATUS.submitted ||
          newClaim.status === CLAIM_SUBMIT_STATUS.partial) && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill mx-3 ms-auto"
            onClick={() =>
              setOpenConfirmation({
                isShow: true,
                message: `Are you sure, you want to Re-Send Patient Claim?`,
                actionType: CONFIRMATION_TYPE.RESUBMIT_CLAIM,
                title: "Claim Re-Send",
              })
            }
          >
            Re-Send Claim
          </Button>
        )}

        {showCreateUpdateButton(newClaim.status) && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill"
            onClick={() =>
              onHandleSave({ isNew: !newClaim.status, claimStatus: newClaim.status, isEligibilityChecked: true })
            }
          >
            {!newClaim.status ? "Create" : "Update"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(CreateClaimResubmissionModal, arePropsEqual);
