import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import axios from "axios";
import {
  CLAIM_STATUS_LOG_OBJ,
  CONFIG,
  MEDICATRE_IDS,
  OrderStatusEvents,
  PERSONALIZATION,
  DEFAULT_USER_PERSONALIZATION,
  PROVIDER_URL,
  USER_TYPE_CLIENT,
  USER_TYPE_SUBAGENT,
  USER_TYPE_USER,
  altaIDS,
  claimActions,
  CLAIM_SUBMIT_STATUS,
  localClaimId,
} from "constant";
import { AWS_ENDPOINT } from "constants/endpoints";
import { get } from "jquery";
import { AppSetting } from "models";
import moment from "moment";
import { jsonToCSV } from "react-papaparse";
import { getIntVal } from "utils";
import {
  CapsFirstLetter,
  RemoveSpaceAndChar,
  capitalizeLetter,
  emailMsgToCreatedClient,
  emailMsgToCreatedFacility,
  emailMsgToSubAgent,
  getPhoneNo,
  insuranceProviderFormatter,
  toTitleCase,
  userCompanyID,
  getEmployeeIndexValue,
  createInvoicePayload,
} from "utils";

import { CUSTOM_INS, DEFAULT_USRERS_PERMISSIONS, DUMMY_ELIG_RES } from "./constant";
import { v4 as uuidv4 } from "uuid";
import * as queries from "./graphql/queries";
import {
  Client,
  CompanyStats,
  EligibiliyFiles,
  Employee,
  EmployeeClaims,
  EmployeeOrder,
  ProviderInfo,
  Site,
  SubAgent,
  Users,
  Invoices,
  ClientTestSetting,
  MessageTemplate,
} from "./models";
import { updateInsurancefromClaim, SYSTEM_TIME_ZONE } from "utils";
import graphqlApi from "graphqlApi";
import { getFloatVal } from "utils";
import { getLoginUserInfoFromLocal } from "utils";

const PUBLIC_API_URL = "https://kzyxbb35nl.execute-api.eu-west-1.amazonaws.com/staging";

const BASE_URL_API = "https://jc24amz3s1.execute-api.eu-west-1.amazonaws.com/staging/";

const PATIENT_DATA_URL = `${BASE_URL_API}preregistration`;
const PLACE_ORDER = `${BASE_URL_API}ordertest`;
const GET_LOCATION_URL = `${BASE_URL_API}location`;

const MAX_PAGE_LIMIT = 1000;
const timezones = {
  "Eastern Standard Time": -240,
  "Eastern Daylight Time": -300,
  "Central Standard Time": -300,
  "Central Daylight Time": -360,
  "Mountain Standard Time": -360,
  "Mountain Daylight Time": -420,
  "Pacific Standard Time": -420,
  "Pacific Daylight Time": -480,
  "Pakistan Standard Time": +300,
};

const apiName = "apif79b844d";
const publicApiName = "api4af2df69";

const callAmplifyAPI = async (path, payload) => {
  try {
    const loginUserInfo = getLoginUserInfoFromLocal();

    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: { ...payload, clientID: userCompanyID.get(), ...loginUserInfo },
    };

    const apiRes = await API.post(apiName, path, params);

    return apiRes;
  } catch (err) {
    throw Error(err);
  }
};

const callAmplifyAPIWithError = async (path, payload) => {
  const loginUserInfo = getLoginUserInfoFromLocal();

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: { ...payload, clientID: userCompanyID.get(), ...loginUserInfo },
  };

  const apiRes = await API.post(apiName, path, params);

  return apiRes;
};

const callPublicAPI = async ({ path, payload }) => {
  try {
    let params = {
      body: {
        ...payload,
      },
    };
    const res = await API.post(publicApiName, path, params);

    return res;
  } catch (err) {
    console.log("Err", err);
    return null;
  }
};

const callAmplifyAPIPut = async (path, payload) => {
  try {
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: { ...payload, clientID: userCompanyID.get() },
    };

    const apiRes = await API.put(apiName, path, params);

    return apiRes;
  } catch (err) {
    throw err;
  }
};

const callAPIPost = async (path, payload) => {
  try {
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: { ...payload, clientID: userCompanyID.get() },
    };

    const apiRes = await API.post(apiName, path, params);

    return apiRes;
  } catch (err) {
    throw err;
  }
};

const getTestGraphQL = async () => {
  console.log("getTestGraphQL ");
  try {
    const graphqlData = await API.graphql(
      graphqlOperation(queries.getEmployee, {
        id: "57b4f60a-acec-4150-8224-49eb3d2e5c8e",
      })
    );
    console.log("getTestGraphQL ", graphqlData);
  } catch (err) {
    console.log("Error ", err);
  }
};

const getCurrentUser = async () => {
  try {
    const session = await Auth.currentSession();
    const isAuthenticated = session.isValid();
    if (isAuthenticated) {
      const response = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const compnayID = response.attributes["custom:labID"];
      const roles = response.signInUserSession.accessToken.payload["cognito:groups"] || [];
      let baseRole = "";
      if (roles.some((role) => role === "Admins")) {
        baseRole = "Admins";
      } else if (roles.some((role) => role === "Employers")) {
        baseRole = "Employers";
      } else if (roles.some((role) => role === "Clients")) {
        baseRole = "Clients";
      } else if (roles.some((role) => role === "SubAgents")) {
        baseRole = "SubAgents";
      } else if (roles.some((role) => role === "Employees")) {
        baseRole = "Employees";
      }

      return {
        ...response.attributes,
        baseRole,
        name: `${response.attributes["custom:firstName"]} ${response.attributes["custom:lastName"] || ""}`,
        roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
        isAdmin: function () {
          return this.roles.some((role) => role === "Admins");
        },
        isUser: function () {
          return this.roles.some((role) => role === "Employees");
        },
        isSite: function () {
          return this.roles.some((role) => role === "Employers");
        },
        isClient: function () {
          return this.roles.some((role) => role === "Clients");
        },
        isSubAgent: function () {
          return this.roles.some((role) => role === "SubAgents");
        },
        companyID: compnayID,
      };
    }
  } catch (err) {
    console.log("Error get Current User:-", err);
    localStorage.clear();
    return null;
  }
};

const getAllUsers = async () => {
  const models = await DataStore.query(Employee);
  return models;
};

const signIn = async (username, password) => {
  try {
    const response = await Auth.signIn(username, password);
    // console.log(response);
    return {
      ...response.attributes,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isUser: function () {
        return this.roles.length === 0;
      },
    };
  } catch (err) {
    return null;
  }
};

const resendOtp = async () => {
  const path = "/reSendOtp";

  return await callAPIPost(path, {});
};
const verifyOtp = async (otp) => {
  const path = "/verifyOtp";
  return await callAPIPost(path, { code: Number(otp) });
};

const fetchAllClients = async () => {
  let path = "/clients";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const models = await API.get(publicApiName, path, myInit);

    return models;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};
const getActivePayers = async () => {
  let path = "/activePayers";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    const models = await API.get(apiName, path, params);

    return models?.rows || [];
  } catch (e) {
    console.log("Error getting users:", e);
  }
};

const fetchAllCompanies = async () => {
  let path = "/clist";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const models = await API.get(publicApiName, path, myInit);

    return models;
  } catch (e) {
    console.log("Error:", e);
  }
};

const searchProviderAPI = async (provider) => {
  let path = "/searchProvider";
  let params = {
    body: {
      ...(!provider.npi ? { first_name: provider.firstName, last_name: provider.lastName } : {}),
      npi: provider.npi,
    },
  };

  try {
    const models = await API.post(publicApiName, path, params);
    console.log("searchProviderAPI Res", models);
    return models?.data || [];
  } catch (e) {
    console.log("Error:", e);
  }
};

const fetchUsersInGroup = async (groupName) => {
  let path = "/listUsersInGroup";
  let myInit = {
    queryStringParameters: {
      groupname: groupName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const models = await API.get(apiName, path, myInit);

    return models.Users;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};
const fetchAllUsers = async (filter, NextToken) => {
  let path = "/listUsers";
  let params = {
    queryStringParameters: {
      limit: 60,
      filter: filter,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, params);

  return models;
};

const changeUserRole = async (prevRole, newRole, username) => {
  let path = "/removeUserFromGroup";
  let prevRoleAlias;
  if (prevRole === "Crew") {
    prevRoleAlias = "Employee";
  } else if (prevRole === "Health & Safety") {
    prevRoleAlias = "Employer";
  } else {
    prevRoleAlias = prevRole;
  }
  let params = {
    body: {
      username: username,
      groupname: prevRoleAlias + "s",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const roleRemoval = await API.post(apiName, path, params);
    path = "/addUserToGroup";
    params.body["groupname"] = newRole + "s";
    const roleChange = await API.post(apiName, path, params);
    // console.log("Changing role", roleRemoval, roleChange);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to change user role");
  }
};

const confirmUserSignUp = async (username) => {
  console.log(username);
  const path = "/confirmUserSignUp";
  try {
    const params = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const done = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Fail to confirm user");
  }
};

const getStatsData = async (npi) => {
  const path = "/getStatsData";
  try {
    const params = {
      body: {
        id: npi ? `${userCompanyID.get()}-${npi}` : userCompanyID.get(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const newPatientCreateAPI = async (param) => {
  const path = "/employees";
  try {
    const params = {
      body: {
        employee: param,
        clientID: param.clientID,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const patientUpdateAPI = async (param) => {
  const path = "/employees";
  try {
    const params = {
      body: {
        employee: param,
        clientID: param.clientID,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.put(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const getEmployeeNo = async (type, id) => {
  const path = "/getEmployeeNo";
  try {
    const params = {
      body: {
        id: id,
        type: type,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res.counter;
  } catch (e) {
    console.log("Error :", e);
  }
};

const fetchDocumentTypesAPI = async (param) => {
  const { title } = param;
  const path = "/documents/types/list";
  try {
    const params = {
      body: {
        title,
        clientID: userCompanyID.get(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res.rows;
  } catch (e) {
    console.log("Error :", e);
  }
};

const creteDocumentTypesAPI = async (param) => {
  const { title } = param;
  const path = "/documents/types";
  try {
    const params = {
      body: {
        title,
        clientID: userCompanyID.get(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res.rows;
  } catch (e) {
    console.log("Error :", e);
  }
};

const fetchDocumentAPI = async (param) => {
  const { employeeID } = param;
  const path = "/documents/list";
  try {
    const params = {
      body: {
        employeeID,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res.rows;
  } catch (e) {
    console.log("Error :", e);
  }
};

const newDocumentCreateAPI = async (param) => {
  const path = "/documents";
  try {
    const params = {
      body: {
        list: param,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const updateDocumentAPI = async (param) => {
  const { id, employeeID, docType, docLabel, fileName, fileType, fileSize, notes, updatedBy, updatedByName } = param;
  const path = "/documents";
  try {
    const params = {
      body: {
        id,
        employeeID,
        docType,
        docLabel,
        fileName,
        fileType,
        fileSize,
        notes,
        updatedBy,
        updatedByName,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.put(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const deleteDocumentAPI = async (param) => {
  console.log("deleteDocumentAPI", param);
  const path = "/documents";
  const { id } = param;
  try {
    const params = {
      body: {
        id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.del(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const updateLogs = async (param) => {
  const { id, employeeID, updatedBy, updatedByName } = param;
  const path = "/updateLogs";
  try {
    const res = await callAmplifyAPI(path, {
      id,
      employeeID,
      updatedBy,
      updatedByName,
    });
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const searchCptCode = async (param) => {
  const path = "/cpt/list";
  try {
    const res = await callAmplifyAPI(path, param);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const searchICTCode = async (param) => {
  const path = "/ict/list";
  try {
    const res = await callAmplifyAPI(path, param);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const addCptCode = async (param) => {
  const path = "/cpt";
  try {
    const params = {
      body: {
        code: param.code,
        title: param.description,
        charge: param.charges,
        isActive: param.isActive,
        internalCode: param.internalCode,
        referenceCharge: getFloatVal(param.referenceCharge),
        cashCharge: param.cashCharge,
        inNeworkCharge: param.inNeworkCharge,
        outNetworkCharge: param.outNetworkCharge,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const addICTCode = async (param) => {
  const path = "/ict";
  try {
    const params = {
      body: {
        code: param.code,
        title: param.title,
        isActive: param.isActive,
        internalCode: param.internalCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const updateCptCode = async (param) => {
  const path = "/cpt";
  try {
    const params = {
      body: {
        id: param.id,
        code: param.code,
        title: param.description,
        charge: param.charges,
        isActive: param.isActive,
        internalCode: param.internalCode,
        referenceCharge: getFloatVal(param.referenceCharge),
        cashCharge: param.cashCharge,
        inNeworkCharge: param.inNeworkCharge,
        outNetworkCharge: param.outNetworkCharge,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.put(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const updateICTCode = async (param) => {
  const path = "/ict";
  try {
    const params = {
      body: {
        id: param.id,
        code: param.code,
        title: param.title,
        isActive: param.isActive,
        internalCode: param.internalCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const res = await API.put(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error :", e);
  }
};

const updateUserInfo = async (user, clientName, connectedID, companyID) => {
  console.log("[update_info]", user);
  const path = "/updateUserInfo";

  const params = {
    body: {
      username: user.username,
      roles: JSON.stringify(user.roles),
      firstName: user.firstName,
      lastName: user.lastName,
      phone_number: user.phone,
      email: user.email,
      companyID: companyID,
      clientID: user.clientID,
      locationID: user.locationID,
      subAgentID: user.subAgentID,
      userRole: user.role,
      userPermission: JSON.stringify(user.userPermission),
      prevRole: user.prevRole,
      note: clientName,
      connectedID,
      status: "Confirm",
      systemUser: 1,
      is2FaEnabled: user.is2FaEnabled ? "y" : "n",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[update_info]", infoUpdated);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user info");
  }
};

const sendEmailMessage = async (user) => {
  let fnc = null;
  if (user.role === "Clients") {
    fnc = emailMsgToCreatedClient;
  } else if (user.role === "Employers") {
    fnc = emailMsgToCreatedFacility;
  } else if (user.role === "SubAgents") {
    fnc = emailMsgToSubAgent;
  } else {
    return "";
  }
  const message = fnc(`${user.firstName} ${user.lastName}`, user.phone, user.password);

  await sendEmail([
    {
      email: user.email,
      subject: "SafeCamp LTC Team",
      msg: message,
    },
  ]);
};

const addUser = async (user, clientName, connectedID, companyID) => {
  const res = await DataStore.save(
    new Users({
      userID: user.username,
      roles: JSON.stringify(user.roles),
      firstName: user.firstName,
      lastName: user.lastName,
      phone_number: user.phone,
      email: user.email,
      clientID: user.clientID,
      locationID: user.locationID,
      subAgentID: user.subAgentID,
      userRole: user.role,
      companyID: companyID,
      note: clientName,
      userPermission: user.userPermission,
      connectedID,
      status: "Confirm",
      systemUser: 1,
      personalization: JSON.stringify(DEFAULT_USER_PERSONALIZATION),
      is2FaEnabled: user.is2FaEnabled ? "y" : "n",
    })
  );
};

const addUserClients = async (user, clientName, connectedID, companyID) => {
  console.log("[addUserClients]", user);
  const path = "/addUserClients";

  const params = {
    body: {
      username: user.username,
      roles: JSON.stringify(user.roles),
      firstName: user.firstName,
      lastName: user.lastName,
      phone_number: user.phone,
      email: user.email,
      clientID: user.clientID,
      locationID: user.locationID,
      subAgentID: user.subAgentID,
      userRole: user.role,
      companyID: companyID,
      note: clientName,
      connectedID,
      status: "Confirm",
      systemUser: 1,
      personalization: JSON.stringify(PERSONALIZATION),
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);

    console.log("[addUserClients]", infoUpdated);

    await sendEmailMessage(user);

    //  await api.sendSMSNotification(user.phone, msg);
  } catch (e) {
    console.log("Error changing addUserClients:", e);
  }
};

const udpatePersonalization = async (username, data) => {
  const setting = await getCompanySetting();
  const userName = CONFIG.isLabType ? `${username}_${setting.code}` : username;
  let models = await DataStore.query(Users, (e) => e.userID("eq", userName));
  if (models && models.length === 0) {
    models = await DataStore.query(Users, (e) => e.userID("eq", username));
  }
  const dbUser = models[0];
  await DataStore.save(
    Users.copyOf(dbUser, (updated) => {
      updated.personalization = JSON.stringify(data);
    })
  );
};

// const udpatePersonalization = async (user, data) => {
//   const path = "/updatePersonalisationInfo";
//   let note = "";

//   const params = {
//     body: {
//       data: JSON.stringify(data),
//       username: user, // user.phone_number,
//     },
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
//     },
//   };

//   try {
//     await API.post(apiName, path, params);
//   } catch (e) {
//     console.log("Error changing user role:", e);
//   }
// };

const updateAssociatedInfo = async (username, note) => {
  console.log("[update_info]", username, note);
  const path = "/updateAssociatedInfo";

  const params = {
    body: {
      username: username,
      note: note,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const updateUserPassword = async (username, password) => {
  console.log("[update_user_password_info]", username, password);
  const path = "/updateUserPassword";
  const params = {
    body: {
      username: username,
      password: password,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[update_user_password_info]", infoUpdated);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user password");
  }
};

const deleteUserLinkFromEmployee = async (val) => {
  const users = await DataStore.query(Employee, (e) => e.subID("eq", val));
  users.forEach(async (user) => {
    await DataStore.save(
      Employee.copyOf(user, (updated) => {
        updated.subID = null;
      })
    );
  });
};

const deleteUser = async (user) => {
  console.log("[delete_user_password_info]", user);
  const path = "/deleteUser";
  const params = {
    body: {
      id: user.id,
      username: user.userID,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    //alert("Unable to update user password");
  }
};

const deleteUserFromDataStore = async (user) => {
  await DataStore.delete(Users, (obj) => obj.id("eq", user.id));
};

const sendSMSNotification = async (phoneNumber, message) => {
  try {
    const notification = await axios.post(AWS_ENDPOINT + "/notification", {
      phone_number: phoneNumber,
      message: message,
    });
    return notification;
  } catch (error) {
    console.log(error);
  }
};

const parseMessage = (msg, emp, companyData, testType) => {
  let message = msg;
  message = message.replace(/{firstName}/g, toTitleCase(emp.firstName));
  message = message.replace(/{lastName}/g, toTitleCase(emp.lastName));
  message = message.replace(/{testType}/g, testType);
  return message;
};

const parseMessageCallTime = (msg, firstName, lastName, testType, callTime) => {
  let message = msg;
  message = message.replace(/{firstName}/g, toTitleCase(firstName));
  message = message.replace(/{lastName}/g, toTitleCase(lastName));
  message = message.replace(/{testType}/g, testType);
  message = message.replace(/{callTime}/g, callTime);
  return message;
};

export const formatDateMDY = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");
const buildAWSDate = (dobFromID) => {
  const awsdob = [];
  awsdob.push(dobFromID.substring(4));
  awsdob.push(dobFromID.substring(0, 2));
  awsdob.push(dobFromID.substring(2, 4));
  return awsdob.join("-");
};

const sendEmail = async (data) => {
  console.log("[sendEmail]", data);
  const path = "/sendEmail";
  // const path = "/sendInvitationEmail";
  // const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[SEND_EMAIL]", infoUpdated);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};

const sendEmailToCrew = async (data) => {
  console.log("[sendEmailToCrew]", data);
  // const path = "/sendEmailToCrew";
  const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[SEND_EMAIL]", infoUpdated);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};

const fetchAllEmployees = async () => {
  const models = await DataStore.query(Employee);
  return models.length;
};

const getEmployees = async () => {
  let models = [];

  if (userCompanyID.get()) {
    models = await DataStore.query(Employee, (e) => e.companyID("eq", userCompanyID.get()), {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });

    const clientRecords = await DataStore.query(Employee, (e) => e.clientID("eq", userCompanyID.get()), {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });
    const subAgentRecords = await DataStore.query(Employee, (e) => e.subAgent("eq", userCompanyID.get()), {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });
    models = models.concat(clientRecords);
    models = models.concat(subAgentRecords);
  } else {
    models = await DataStore.query(Employee, Predicates.ALL, {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });
  }
  return models;
};

const getEmployeeClients = async (username) => {
  const path = "/getEmployeeClients";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      username: username,
    },
  };

  const counterData = await API.post(apiName, path, params);
  return counterData.Items.length > 0 ? counterData.Items[0] : {};
};

const getEmployeeByID = async (emp) => {
  let models = [];
  if (emp.id) {
    models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  } else {
    models = await DataStore.query(Employee, (e) =>
      e.firstName("eq", emp.firstName).lastName("eq", emp.lastName).dob("eq", emp.dob)
    );
  }
  return models.length > 0 ? models[0] : null;
};

const getLoggedInUserEmployeeID = async (id, medicalno) => {
  let models = null;
  if (id) {
    models = await DataStore.query(Employee, (e) => e.loginID("eq", medicalno).id("ne", id));
  } else {
    models = await DataStore.query(Employee, (e) => e.loginID("eq", medicalno));
  }
  return models.length > 0 ? models[0] : null;
};

const getUserEmployeeID = async (newUser) => {
  let models = [];
  if (MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1) {
    models = await DataStore.query(Employee, (e) =>
      e.medicalNo("eq", newUser.medicalNo).insuranceCompany("eq", newUser.insuranceCompany).id("ne", newUser.id)
    );
  } else {
    models = await DataStore.query(Employee, (e) =>
      e
        .medicalNo("eq", newUser.medicalNo)
        .insuranceCompany("eq", newUser.insuranceCompany)
        .insuranceGroupId("eq", newUser.insuranceGroupId)
        .id("ne", newUser.id)
    );
  }
  return models.length > 0 ? models[0] : null;
};

const getDuplicateData = async () => {
  const models = await DataStore.query(Employee, Predicates.ALL);
  const ttl = models.length;

  const emps = models.filter(
    (f, i, arr) =>
      arr.findIndex(
        (m) =>
          m.firstName === f.firstName && f.lastName === m.lastName && f.medicalNo === m.medicalNo && f.dob === m.dob
      ) !== i
  );
  const duplicateData = models.filter(
    (f, i) =>
      emps.findIndex(
        (m) =>
          m.firstName === f.firstName && f.lastName === m.lastName && f.medicalNo === m.medicalNo && f.dob === m.dob
      ) !== -1
  );
  return duplicateData;
};

const isEmployeeExists = async (newUser) => {
  let models = [];
  if (MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1) {
    models = await DataStore.query(Employee, (e) =>
      e.medicalNo("eq", newUser.medicareNo).insuranceCompany("eq", newUser.insuranceCompany).id("ne", newUser.id)
    );
  } else {
    models = await DataStore.query(Employee, (e) =>
      e
        .medicalNo("eq", newUser.medicareNo)
        .insuranceCompany("eq", newUser.insuranceCompany)
        .insuranceGroupId("eq", newUser.groupId)
        .id("ne", newUser.id)
    );
  }
  return models.length > 0 ? models[0] : null;
};

const getUserFromLocalDB = async (username, user) => {
  const path = "/getUserFromLocalDB";
  let type = user.baseRole;
  // if (user?.baseRole !== "Admins") {
  //   type = user.baseRole;
  // }
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: userCompanyID.get(),
      type: type,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes;
};

const getEligibilityList = async (param) => {
  const path = "/getEligibilityList";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: param,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes;
};

const getClaimList = async (param) => {
  const path = "/getClaimList";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: { ...param, limit: 100 },
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes || [];
};

const newEmployee = async (emp, num, user) => {
  const setting = await getCompanySetting();
  let seqNumber = emp.schrID;
  if (!seqNumber) {
    seqNumber = await getEmployeeNo("e", setting.id);
  }

  const employeeID = CONFIG.isLabType
    ? `${CONFIG.appCodeName}-${setting.code}-${seqNumber.toString().padStart(6, 0)}`
    : `${CONFIG.appCodeName}-${seqNumber.toString().padStart(6, 0)}`;
  const loginID = `${CONFIG.appCodeName}${seqNumber.toString().padStart(6, 0)}${moment().format("HHmmss")}`;

  const employeeObject = {
    ...(emp.id && { id: emp.id }),
    firstName: emp.firstName,
    middleName: emp.middleName,
    lastName: emp.lastName,
    schrID: employeeID,
    email: emp.email,
    street: toTitleCase(emp.street),
    street2: emp.street2,
    sex: emp.gender || emp.sex,
    isVaccinated: emp.isVaccinated || false,
    country: toTitleCase(emp.country),
    state: capitalizeLetter(emp.state),
    city: toTitleCase(emp.city),
    insuranceCompany: emp.insuranceCompany,
    insuranceDetails: JSON.stringify(emp.insuranceDetails),
    insuranceGroupId: emp.insuranceGroupId,
    insuranceCardFront: emp.insuranceCardFront,
    insuranceCardBack: emp.insuranceCardBack,
    insuranceCompanyCode: emp.insuranceCompanyCode,
    primaryInsurance: JSON.stringify(emp.primaryInsurance),
    medicareNo: emp.medicareNo?.trim().toUpperCase() || null,
    jobTitle: emp.jobTitle,
    medicalNo: emp.medicalNo?.trim().toUpperCase() || null,
    zip: emp.zip,
    isNew: false,
    unionNo: "",
    empTZ: "",
    isSignUp: emp.password ? 1 : 0,
    testAvailable: emp.testAvailable || 8,
    testOrdered: 0,
    totalOrderd: 0,
    clientID: emp.clientID || setting.clientID || CONFIG.generalClient,
    companyID: emp.companyID || setting.facilityID || CONFIG.generalFacility,
    subAgent: emp.subAgentID || setting.subAgentID || CONFIG.generalSubAgent,
    autoShipment: emp.autoShipment,
    loginID: loginID,
    updatedBy: user?.sub,
    updatedByName: user?.name,
    note: emp.note,
    noteAddedBy: emp.note ? user?.name : "",
    status: "active",
    sendSMS: emp.sendSMS || false ? "1" : "0",
    sendEmail: emp.sendEmail || false ? "1" : "0",
    localNo: emp.localNo,
    referringPyhsician: emp.referringPyhsician,
    renderingProvider: emp.renderingProvider,
    referringProvider: emp.referringProvider,
    orderingProvider: emp.orderingProvider,
    primaryCarePhysician: emp.primaryCarePhysician,
    isHospice: emp.isHospice,
    snf: emp.snf,
    autoRelease: emp.autoRelease,
    isInsHousing: emp.isInsHousing,
    codes: JSON.stringify(emp.codes),
    dob: emp.dob,
    setting: JSON.stringify(emp.setting),
    employeeFND: emp.employeeFND,
  };

  const [countryCode, phoneNo] = getPhoneNo(emp.phoneNumber);
  Object.assign(employeeObject, {
    phoneNumber: phoneNo || "",
    countryCode: countryCode || "",
  });

  const res = await graphqlApi.createEmployeeGQL(employeeObject);

  if (!res) {
    throw new Error("Fail to create the Patient");
  }

  return { data: res?.loginID, id: res?.id, res };
};

const newProviderInfo = async (user) => {
  const providerObject = {
    name: user.name,
    clientID: userCompanyID.get() || CONFIG.generalClient,
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    code: user.code,
    npi: user.npi,
    street: user.street,
    city: user.city,
    state: user.state,
    zip: user.zip,
    phoneNumber: user.phoneNumber,
    fax: user.fax,
    isActive: user.isActive,
    taxid: user.taxid,
    speciality: user.speciality,
    npiType: user.npiType,
    associatedClient: user.associatedClient,
    setting: user.setting,
    createdBy: user.createdBy,
    createdByName: user.createdByName,
    updatedByName: user.updatedByName,
  };

  const res = await DataStore.save(new ProviderInfo(providerObject));

  if (user.associatedClient) {
    await updateClientProviderNumber(user.associatedClient, 1);
  }

  return res;
};

const updateProviderClient = async (pId, cId) => {
  const models = await DataStore.query(ProviderInfo, (e) => e.id("eq", pId));
  const dbEmp = models[0];

  if (dbEmp.associatedClient !== cId) {
    await updateClientProviderNumber(cId, 1);
  }

  const res = await DataStore.save(
    ProviderInfo.copyOf(dbEmp, (updated) => {
      updated.associatedClient = cId;
    })
  );

  await callAmplifyAPI("/labclient/provider", { providerID: pId, labClientID: cId, commission: 100 });

  return dbEmp;
};

const createClaim = async (data, number) => {
  const empClaim = {
    ...data,
    clientID: userCompanyID.get() || CONFIG.generalClient,
    charge_1: data.charge_1?.toString(),
    total_charge: data.total_charge?.toString(),
    units_2: data.units_2?.toString(),
  };
  const res = await DataStore.save(new EmployeeClaims(empClaim));
  if (number) await updateCompnayClaimNo(number);
  return { data: res };
};

const updateEmpSingleField = async (key, val, emp) => {
  const dbEmp = await graphqlApi.getEmployeeByIdGQl(emp.id);

  const updated = {
    id: dbEmp.id,
    [key]: val,
    _version: dbEmp._version,
  };

  const response = await graphqlApi.updateEmployeeGQL(updated);
  return response;
};

const updateEmployeePersonals = async (emp) => {
  console.log("emp", emp);

  const dbEmp = await graphqlApi.getEmployeeByIdGQl(emp.id);
  const updated = {
    id: dbEmp.id,
    firstName: emp.firstName,
    lastName: emp.lastName,
    dob: emp.dob,
    sex: emp.gender || emp.sex,
    insuranceDetails: JSON.stringify(emp.insuranceDetails),
    _version: dbEmp._version,
  };

  if (emp.medicalNo) {
    updated.medicalNo = emp.medicalNo;
    updated.medicareNo = emp.medicareNo;
  }

  const res = await graphqlApi.updateEmployeeGQL(updated);

  return res;
};

const updateEmployee = async (emp, user) => {
  const dbEmp = await graphqlApi.getEmployeeByIdGQl(emp.id);

  const [countryCode, phoneNo] = getPhoneNo(emp.phoneNumber);
  const updated = {
    id: emp.id,
    firstName: emp.firstName,
    lastName: emp.lastName,
    middleName: emp.middleName,
    dob: emp.dob,
    schrID: emp.schrID,
    email: emp.email,
    phoneNumber: phoneNo,
    countryCode: countryCode,
    street: toTitleCase(emp.street),
    street2: emp.street2,
    sex: emp.gender || emp.sex,
    isVaccinated: emp.isVaccinated,
    country: toTitleCase(emp.country),
    city: toTitleCase(emp.city),
    zip: emp.zip,
    medicalNo: emp.medicalNo?.trim().toUpperCase() || null,
    state: capitalizeLetter(emp.state),
    companyID: emp.companyID,
    clientID: emp.clientID,
    subAgent: emp.subAgentID,
    insuranceCompany: emp.insuranceCompany,
    insuranceGroupId: emp.insuranceGroupId,
    insuranceCardFront: emp.insuranceCardFront,
    insuranceCardBack: emp.insuranceCardBack,
    insuranceDetails: JSON.stringify(emp.insuranceDetails),
    insuranceCompanyCode: emp.insuranceCompanyCode,
    primaryInsurance: JSON.stringify(emp.primaryInsurance),
    medicareNo: emp.medicareNo?.trim().toUpperCase() || null,
    autoShipment: emp.autoShipment,
    picture: emp.profileImage instanceof Object ? `${emp.id}.jpeg` : emp.picture,
    updatedBy: user?.sub,
    updatedByName: user?.name,
    note: emp.note,
    status:
      CUSTOM_INS.includes(emp.insuranceCompany) && dbEmp.insuranceCompany !== emp.insuranceCompany
        ? "active"
        : emp.status,
    referringPyhsician: emp.referringPyhsician,
    primaryCarePhysician: emp.primaryCarePhysician,
    renderingProvider: emp.renderingProvider,
    referringProvider: emp.referringProvider,
    orderingProvider: emp.orderingProvider,
    isHospice: emp.isHospice,
    autoRelease: emp.autoRelease,
    sendSMS: emp.sendSMS || false ? "1" : "0",
    sendEmail: emp.sendEmail || false ? "1" : "0",
    codes: JSON.stringify(emp.codes),
    setting: JSON.stringify(emp.setting),
    employeeFND: emp.employeeFND,
    snf: emp.snf,
    isInsHousing: emp.isInsHousing,
    _version: dbEmp._version,
  };

  if (emp.status === "inactive") {
    Object.assign(updated, { testAvailable: 0 });
  }
  if (emp.password) {
    Object.assign(updated, { isSignUp: 1 });
  }
  const response = await graphqlApi.updateEmployeeGQL(updated);
  // const response = await DataStore.save(
  //   Employee.copyOf(dbEmp, (updated) => {
  //     updated.firstName = emp.firstName;
  //     updated.lastName = emp.lastName;
  //     updated.middleName = emp.middleName;
  //     updated.dob = emp.dob;
  //     updated.schrID = emp.schrID;
  //     updated.email = emp.email;
  //     updated.phoneNumber = phoneNo;
  //     updated.countryCode = countryCode;
  //     updated.street = toTitleCase(emp.street);
  //     updated.street2 = emp.street2;
  //     updated.sex = emp.gender || emp.sex;
  //     updated.isVaccinated = emp.isVaccinated;
  //     updated.country = toTitleCase(emp.country);
  //     updated.city = toTitleCase(emp.city);
  //     if (emp.status === "inactive") {
  //       updated.testAvailable = 0;
  //     }
  //     if (emp.password) {
  //       updated.isSignUp = 1;
  //     }
  //     updated.zip = emp.zip;
  //     updated.medicalNo = emp.medicalNo?.trim().toUpperCase() || null;
  //     updated.state = capitalizeLetter(emp.state);
  //     updated.companyID = emp.companyID;
  //     updated.clientID = emp.clientID;
  //     updated.subAgent = emp.subAgentID;
  //     updated.insuranceCompany = emp.insuranceCompany;
  //     updated.insuranceGroupId = emp.insuranceGroupId;
  //     updated.insuranceCardFront = emp.insuranceCardFront;
  //     updated.insuranceCardBack = emp.insuranceCardBack;
  //     updated.insuranceDetails = emp.insuranceDetails;
  //     updated.insuranceCompanyCode = emp.insuranceCompanyCode;
  //     updated.primaryInsurance = emp.primaryInsurance;
  //     updated.medicareNo = emp.medicareNo?.trim().toUpperCase() || null;
  //     updated.autoShipment = emp.autoShipment;
  //     updated.picture = emp.profileImage instanceof Object ? `${emp.id}.jpeg` : emp.picture;
  //     updated.updatedBy = user?.sub;
  //     updated.updatedByName = user?.name;
  //     updated.note = emp.note;
  //     updated.status =
  //       CUSTOM_INS.includes(emp.insuranceCompany) && dbEmp.insuranceCompany !== emp.insuranceCompany
  //         ? "active"
  //         : emp.status;
  //     updated.referringPyhsician = emp.referringPyhsician;
  //     updated.primaryCarePhysician = emp.primaryCarePhysician;
  //     updated.renderingProvider = emp.renderingProvider;
  //     updated.referringProvider = emp.referringProvider;
  //     updated.orderingProvider = emp.orderingProvider;
  //     updated.isHospice = emp.isHospice;
  //     updated.codes = emp.codes;
  //     updated.setting = emp.setting;
  //     updated.employeeFND = emp.employeeFND;
  //   })
  // );
  if (dbEmp.status !== emp.status) {
    const logObj = {
      firstName: dbEmp.firstName,
      lastName: dbEmp.lastName,
      dob: dbEmp.dob,
      empID: dbEmp.id,
      medicalNo: dbEmp.medicalNo,
      locationID: dbEmp.companyID,
      clientID: dbEmp.clientID,
      subAgentID: dbEmp.subAgent,
      userID: user.sub,
      userName: user.name,
      action: `User Marked ${CapsFirstLetter(emp.status)}`,
      eventType: "UpdateStatus",
      eventData: "UpdateStatus",
    };

    await saveLogs(logObj);
  }
  try {
    if (emp.profileImage instanceof Object) {
      console.log("Before", emp.profileImage);
      await Storage.put(`profile/${emp.id}.jpeg`, emp.profileImage, {
        bucket: "medflow-images",
      });
      console.log("aFter", emp.profileImage);
    }
  } catch (err) {
    console.log("Error", err.message);
  }
  return { data: emp.loginID, id: emp.id, res: response };
};

const updateEmployeeInsurance = async (emp, user) => {
  const dbEmp = await graphqlApi.getEmployeeByIdGQl(emp.id);

  const updated = {
    id: dbEmp?.id,
    insuranceDetails: JSON.stringify(emp.insuranceDetails),
    updatedBy: user?.sub,
    updatedByName: user?.name,
    _version: dbEmp?._version,
  };
  if (emp.medicalNo) {
    Object.assign(updated, {
      medicalNo: emp.medicalNo.trim().toUpperCase(),
      insuranceCompany: emp.insuranceCompany,
      insuranceCompanyCode: emp.insuranceCompanyCode,
    });
  }
  if (emp.medicareNo) {
    Object.assign(updated, { medicareNo: emp.medicareNo.trim().toUpperCase() });
  }
  const response = await graphqlApi.updateEmployeeGQL(updated);
  if (dbEmp.status !== emp.status) {
    const logObj = {
      firstName: dbEmp.firstName,
      lastName: dbEmp.lastName,
      dob: dbEmp.dob,
      empID: dbEmp.id,
      medicalNo: dbEmp.medicalNo,
      locationID: dbEmp.companyID,
      clientID: dbEmp.clientID,
      subAgentID: dbEmp.subAgent,
      userID: user.sub,
      userName: user.name,
      action: `User Marked ${CapsFirstLetter(emp.status)}`,
      eventType: "UpdateStatus",
      eventData: "UpdateStatus",
    };

    // await saveLogs(logObj);
  }
  return;
};
const fetchAllCrewMembers = async (groupName, NextToken) => {
  const path = "/listUsersInGroup";
  let users = [];
  const myInit = {
    queryStringParameters: {
      groupname: groupName,
      limit: 60,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, myInit);
  return models;
};

const deleteEmployee = async (id, user) => {
  let claims = [];
  if (CONFIG.isLabType) {
    // claims = await findEmployeeClaims(id);
    claims = await getEmployeeClaimsAPI(id);
  }
  if (claims.length > 0 && claims.some((f) => f.status !== CLAIM_SUBMIT_STATUS.draft)) return false;

  const empObj = await graphqlApi.getEmployeeByIdGQl(id);
  const logObj = {
    firstName: empObj.firstName,
    lastName: empObj.lastName,
    dob: empObj.dob,
    empID: empObj.id,
    medicalNo: empObj.medicalNo,
    clientName: empObj.clientName,
    locationName: empObj.locationName,
    subAgentName: empObj?.subAgentName,
    locationID: empObj.locationID,
    clientID: empObj.clientID,
    subAgentID: empObj?.subAgentID,
    userID: user.sub,
    userName: user.name,
    action: null,
    eventType: "EmployeeArchive",
    eventData: "EmployeeArchive",
  };
  if (empObj) await graphqlApi.deleteEmployeeGQL(empObj);

  await saveLogs(logObj);
  return true;
};

const addAuditLogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_program.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};

const addEligibilityCsv = async (data, fileName, bucketName) => {
  console.log({ data });
  await Storage.put(`${fileName}`, jsonToCSV(data), {
    bucket: bucketName,
  });
};

const pushFileOnBucket = async (data, fileName, bucketName) => {
  try {
    await Storage.put(`${fileName}`, jsonToCSV(data), {
      bucket: bucketName,
    });
    return true;
  } catch (ex) {
    console.log("Error", ex);
    return false;
  }
};

const saveFileOnBucket = async (data, fileName) => {
  const primaryBucketSaved = await pushFileOnBucket(data, fileName, CONFIG.updateBucket);
  if (primaryBucketSaved) {
    return true;
  }

  if (CONFIG.updateBackupBucket) {
    // Attempt to save on backup bucket if primary bucket saving fails
    const backupBucketSaved = await pushFileOnBucket(data, fileName, CONFIG.updateBackupBucket);
    return backupBucketSaved;
  }
};

const addlogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_audit_logs.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};

const addJSON = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_payer_list.json`, data, {
    bucket: "medflow-images",
  });
};

export const getCompanySetting = async () => {
  let models = await DataStore.query(AppSetting, (e) => e.clientID("eq", userCompanyID.get()));
  if (models.length === 0) {
    models = await DataStore.query(AppSetting, Predicates.ALL);
  }

  // if (models.length === 0) {
  //   await DataStore.save(
  //     new AppSetting({
  //       employeeCounter: 1,
  //     })
  //   );
  //   models = await DataStore.query(AppSetting, Predicates.ALL);
  // }
  return models[0] || {};
};

const getBRMCompanies = async () => {
  const models = await DataStore.query(AppSetting, Predicates.ALL, {
    sort: (s) => s.createdAt(SortDirection.DESCENDING),
  });
  return models;
};

const updateBRMCompanyActiveToggle = async (companyId, isActive) => {
  const models = await DataStore.query(AppSetting, (e) => e.id("eq", companyId));
  const dbBrmCompany = models[0];
  if (dbBrmCompany) {
    await DataStore.save(
      AppSetting.copyOf(dbBrmCompany, (updated) => {
        updated.isActive = isActive;
      })
    );
  }
};

const updateSignUp = async (emp) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  const dbEmp = models[0];
  if (dbEmp) {
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.isSignUp = 1;
      })
    );
  }
};

const updateCompnayEmployeeNo = async (number) => {
  const setting = await getCompanySetting();
  console.log("setting", setting);
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.employeeCounter = number;
    })
  );
};

const updateAlertSettings = async (arr) => {
  const setting = await getCompanySetting();
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.alertSetting = arr;
    })
  );
};

const updateCompnayDefaultSetting = async (defaultSetting) => {
  const setting = await getCompanySetting();
  const res = await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.defaultSetting = { ...(setting.defaultSetting || {}), ...(defaultSetting || {}) };
    })
  );
  return res;
};

const updateLabData = async () => {
  const setting = await getCompanySetting();
  console.log("setting", setting);
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.name = "ALTA DIAGNOSTIC LABORATORIES, INC";
      updated.npi = "1063180354";
      updated.taxid = "871701212";
      updated.taxonomyType = "E";
      updated.phoneNumber = "8185730434";
      updated.street = "2268 LINCOLN AVE";
      updated.street2 = "";
      updated.city = "ALTADENA";
      updated.state = "CA";
    })
  );
};
const updateCompnayClaimNo = async (number) => {
  const setting = await getCompanySetting();
  console.log("setting", setting);
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.orderId = number;
    })
  );
};

const updateCliItem = async (arr) => {
  const setting = await getCompanySetting();
  const resp = await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.cliaNumber = arr;
    })
  );
  return resp;
};

const updateCompanyAlertSetting = async (alertSetting) => {
  const setting = await getCompanySetting();
  const resp = await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.alertSetting = alertSetting;
    })
  );
  return resp;
};

const updateMember = async (members) => {
  const ttl = members.length;
  for (let i = 0; i < ttl; i++) {
    const emp = members[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.insuranceCompany = emp.insuranceCompany;
        updated.insuranceCompanyCode = emp.insuranceCompanyCode;
        updated.medicalNo = emp.medicalNo;
        updated.insuranceDetails = emp.insuranceDetails;
      })
    );
  }
};

const updateMemberTestKit = async (members, facility) => {
  const ttl = members.length;
  for (let i = 0; i < ttl; i++) {
    const member = members[i];
    if (!member) continue;

    const models = await DataStore.query(Employee, (e) => e.id("eq", member.id));
    const dbEmp = models[0];

    let tests = null;
    tests = facility.testLimit - dbEmp.testOrdered;

    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.testAvailable = tests < 0 ? 0 : tests;
        updated.clientID = facility.clientID;
      })
    );
  }
};

const getLocations = async (value) => {
  if (userCompanyID.get()) {
    const models = await DataStore.query(Site, (e) => e.clientID("eq", userCompanyID.get()).isActive("eq", true), {
      sort: (s) => s.name(SortDirection.ASCENDING),
    });

    // models.forEach(async (c) => {
    //   await DataStore.save(
    //     Site.copyOf(c, (updated) => {
    //       updated.isActive = true;
    //       updated.isArchive = false;
    //     })
    //   );
    // });

    return models;
  }

  const models = await DataStore.query(Site, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  return models;
};

const getLocationById = async (id) => {
  const models = await DataStore.query(Site, (e) => e.id("eq", id));
  return models[0];
};
const getSubAgentById = async (id) => {
  const models = await DataStore.query(SubAgent, (e) => e.id("eq", id));
  return models[0];
};
const getClientTestSettingByCompanyID = async (id) => {
  const models = await DataStore.query(ClientTestSetting, (e) => e.companyID("eq", id));
  return models;
};
const getClientById = async (id) => {
  const models = await DataStore.query(Client, (e) => e.id("eq", id));
  return models[0];
};

const getCompanies = async () => {
  const models = await DataStore.query(Client, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  return models;
};

const getEligibilityFiles = async () => {
  const user = await getCurrentUser();

  if (!user || !user.sub) {
    return [];
  }
  const models = await DataStore.query(EligibiliyFiles, (e) => e.requestedBy("eq", user.sub), {
    sort: (s) => s.createdAt(SortDirection.DESCENDING),
  });
  return models.length > 0 ? [models[0]] : [];
};

const getSubAgents = async () => {
  let models = [];
  if (userCompanyID.get()) {
    models = await DataStore.query(SubAgent, (e) => e.clientID("eq", userCompanyID.get()));

    const facilityRecords = await DataStore.query(SubAgent, (e) => e.locationID("eq", userCompanyID.get()));
    models = models.concat(facilityRecords);
  } else {
    models = await DataStore.query(SubAgent, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    });
  }

  return models;
};

const getPreRegisterData = async (data, env) => {
  console.log("[getPreRegisterRecordNew]");
  const path = "/getPreRegisterRecordNew";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      // phone_number: "26951b33-c456-42de-9afb-33431695d56e",
      env: env,
      phone_number: data,
    },
  };

  const counterData = await API.post(apiName, path, params);

  return counterData.Items;
};
const getLabData = async (id) => {
  console.log("[getLabData]");
  const path = "/getLabData";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id,
    },
  };

  const counterData = await API.post(apiName, path, params);
  console.log("counterData", counterData);
  return counterData;
};

const getClaimERA = async (claim) => {
  console.log("[getClaimERA]");
  const path = "/paymenthistory";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: claim.id,
      employeeID: claim.employeeID,
      clientID: userCompanyID.get(),
    },
  };

  const res = await API.post(apiName, path, params);
  return res?.data || [];
};

const updateEraApi = async (params) => {
  const models = await callAmplifyAPI("/era/update", params);
  return models;
};

const getPatientERA = async (era) => {
  console.log("[getPatientERA]");
  const path = "/getPatientERA";

  const localClaimIdsERA = [
    { id: "8499768b-7058-4563-8275-f028497b86b1", era: "28201331", pcn: "LTCS-001-0001540" },
    { id: "d2eb9df1-1d13-47a5-8205-0e483f19364d", era: "28201331", pcn: "LTCS-001-0001654" },
    { id: "4bba708f-216d-4e58-b286-882897f92b6c", era: "28088744", pcn: "LTCS-001-0001320" },
    { id: "ac4e0942-ebed-47eb-8d05-7d0da15da46a", era: "28658548", pcn: "LTCS-001-0001366" },
    { id: "a20aea22-f1a9-4205-80c5-64481d61635e", era: "28658548", pcn: "LTCS-001-0001770" },
    { id: "6001d699-fdfe-4974-9990-72ce8109f348", era: "28610907", pcn: "LTCS-001-0001651" },
    { id: "76151c75-cc08-4feb-8a36-85d85331b9bc", era: "28340999", pcn: "LTCS-001-0001636" },
    { id: "7eb4aa16-0333-4458-8736-6ae653ee7777", era: "28088744", pcn: "LTCS-001-0001557" },
    { id: "ee55bdd6-017a-43f2-ae77-d7c5f0722876", era: "28088744", pcn: "LTCS-001-0001558" },
    { id: "2cf6e154-3297-4a11-8f5c-bcd185df98ae", era: "28088744", pcn: "LTCS-001-0001559" },
  ];

  const localID = localClaimIdsERA.find((f) => f.id === era.id);
  if (localID) {
    return "";
  }
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      eraid: era.eraid,
      ...(era.type !== "Full" && { ltcid: localID?.pcn || era.pcn }),
    },
  };

  const res = await API.post(apiName, path, params);

  return res;
};

const newLocations = async (loc, user) => {
  const res = await DataStore.save(
    new Site({
      name: loc.name,
      clientID: loc.clientID,
      contact_name: loc.contact_name,
      phone_number: loc.phone_number,
      contact_email: loc.contact_email,
      street: loc.street,
      street2: loc.street2,
      city: loc.city,
      state: loc.state,
      webSite: loc.webSite,
      contactName: loc.contactName,
      countryCode: loc.countryCode,
      country: loc.country,
      zip: loc.zip,
      testOrderCategory: loc.testOrderCategory?.toString(),
      testLimit: loc.testLimit || 8,
      minTestOrderQty: loc.minTestOrderQty,
      preRegistration: loc.preRegistration,
      createdBy: user.sub,
      createdByName: user.name,
    })
  );
  return res;
};

const newClientSetting = async (loc) => {
  console.log("Test Setting", {
    clientID: userCompanyID.get(),
    companyID: loc.companyID,
    subAgentID: loc.subAgentID,
    test: "PCR",
    defaultCPT: [
      { proc_code: "87635", charge: 200, internalCode: "87635", units: "1", proc_name: "COVID-19 MOLECULAR RT-PCR" },
      {
        proc_code: "87636",
        charge: 200,
        internalCode: "87636",
        units: "1",
        proc_name: "COVID-19 and influenza virus types A and B",
      },
    ],
    defaultICT: [{ diag_1: "Z20.822" }],
    defaultProvider: loc.defaultProvider,
  });

  const test1 = {
    clientID: userCompanyID.get(),
    companyID: loc.companyID,
    subAgentID: loc.subAgentID,
    test: "Respiratory",
    defaultCPT: [
      {
        proc_code: "87637",
        charge: 250,
        internalCode: "87637",
        units: "1",
        proc_name: "Influenza virus types A and B, and respiratory syncytial virus",
      },
    ],
    defaultICT: [{ diag_1: "Z20.822" }],
    defaultProvider: loc.defaultProvider,
  };
  const models = await DataStore.query(ClientTestSetting, (s) => s.id("eq", "db43961e-22f4-4fc9-97a7-7621b891065d"));
  // const res1 = await DataStore.save(
  //   ClientTestSetting.copyOf(models[0], (updated) => {
  //     updated.charge = 15.56;
  //   })
  // );
  return;
  const res = await DataStore.save(
    new ClientTestSetting({
      clientID: userCompanyID.get(),
      companyID: loc.companyID,
      subAgentID: loc.subAgentID,
      test: "PCR",
      defaultCPT: [{ proc_code: "87635", internalCode: "7060", units: "1", proc_name: "COVID-19 MOLECULAR RT-PCR" }],
      defaultICT: [{ diag_1: "Z20.822" }],
      defaultProvider: loc.defaultProvider,
    })
  );
  return res;
};

const deleteClientTestSetting = async (id) => {
  const models = await DataStore.delete(ClientTestSetting, (e) => e.id("eq", id));
  return models;
};
const createLabClient = async (loc, user) => {
  const res = await callAmplifyAPI("/labclient", {
    name: loc.name,
    clientID: loc.clientID,
    contact_name: loc.contact_name,
    practiceID: loc.practiceID,
    email: loc.email,
    phone_number: loc.phone_number,
    contact_email: loc.contact_email,
    street: loc.street,
    isActive: loc.isActive,
    fax: loc.fax,
    contact_fax: loc.contact_fax,
    defaultProvider: loc.defaultProvider,
    // street2: loc.street2,
    city: loc.city,
    state: loc.state,
    contactName: loc.contactName,
    // countryCode: loc.countryCode,
    country: loc.country,
    zip: loc.zip,
    discount: loc.discount,
    commission: loc.commission,
    createdBy: user.sub,
    createdByName: user.name,
    associatedClients: loc.associatedClients,
    setting: loc.setting,
  });
  return res;
};

const importLabClient = async (params) => {
  const res = await callAmplifyAPI("/labclient/import", params);
  return res;
};

const updateLabClient = async (loc, user) => {
  //TODO:: Api Change

  let setting = loc.setting || {};

  if (loc.primaryPhysician) {
    setting = { ...setting, lab_director: loc.primaryPhysician };
  }

  const res = await callAmplifyAPIPut("/labclient", {
    id: loc.id,
    name: loc.name,
    clientID: loc.clientID,
    contact_name: loc.contact_name,
    practiceID: loc.practiceID,
    email: loc.email,
    phone_number: loc.phone_number,
    contact_email: loc.contact_email,
    street: loc.street,
    isActive: loc.isActive,
    fax: loc.fax,
    contact_fax: loc.contact_fax,
    defaultProvider: loc.defaultProvider,
    city: loc.city,
    state: loc.state,
    contactName: loc.contactName,
    country: loc.country,
    zip: loc.zip,
    discount: loc.discount,
    commission: loc.commission,
    createdBy: user.sub,
    createdByName: user.name,
    associatedClients: loc.associatedClients,
    setting: setting,
  });
  return res;

  // const models = await DataStore.query(Site, (s) => s.id("eq", loc.id));
  // const res = await DataStore.save(
  //   Site.copyOf(models[0], (updated) => {
  //     updated.name = loc.name;
  //     updated.clientID = loc.clientID;
  //     updated.practiceID = loc.practiceID;
  //     updated.contact_name = loc.contact_name;
  //     updated.email = loc.email;
  //     updated.phone_number = loc.phone_number;
  //     updated.contact_email = loc.contact_email;
  //     updated.street = loc.street;
  //     updated.fax = loc.fax;
  //     updated.contact_fax = loc.contact_fax;
  //     updated.defaultProvider = loc.defaultProvider;
  //     // street2= loc.street;
  //     updated.city = loc.city;
  //     updated.state = loc.state;
  //     // webSite= loc.webSit;
  //     updated.contactName = loc.contactName;
  //     // countryCode= loc.countryCod;
  //     updated.country = loc.country;
  //     updated.zip = loc.zip;
  //     updated.discount = loc.discount;
  //     updated.commission = loc.commission;
  //     updated.isActive = loc.isActive;
  //     updated.createdBy = user.sub;
  //     updated.createdByName = user.name;
  //     updated.associatedClients = loc.associatedClients;
  //   })
  // );
  // console.log({ res });
  // return res;
};
const updateLabClientNotes = async (loc) => {
  console.log(loc);
  const models = await DataStore.query(Site, (s) => s.id("eq", loc.id));

  console.log(models);
  const res = await DataStore.save(
    Site.copyOf(models[0], (updated) => {
      updated.note = loc.note;
    })
  );
  return res;
};

const updateLocation = async (loc) => {
  const models = await DataStore.query(Site, (s) => s.id("eq", loc.id));
  await DataStore.save(
    Site.copyOf(models[0], (updated) => {
      updated.name = loc.name;
      updated.clientID = loc.clientID;
      updated.contact_name = loc.contact_name;
      updated.phone_number = loc.phone_number;
      updated.contact_email = loc.contact_email;
      updated.street = loc.street;
      updated.street2 = loc.street2;
      updated.city = loc.city;
      updated.state = loc.state;
      updated.webSite = loc.webSite;
      updated.contactName = loc.contactName;
      updated.countryCode = loc.countryCode;
      updated.country = loc.country;
      updated.zip = loc.zip;
      updated.testOrderCategory = loc.testOrderCategory?.toString();
      updated.testLimit = loc.testLimit;
      updated.minTestOrderQty = loc.minTestOrderQty;
      updated.preRegistration = loc.preRegistration;
    })
  );
};

const newEligibiliyFiles = async (obj, user) => {
  const res = await DataStore.save(
    new EligibiliyFiles({
      clientID: obj.clientID,
      locationID: obj.locationID,
      subAgentID: obj.subAgentID,
      clientName: obj.clientName,
      locationName: obj.locationName,
      subAgentName: obj.subAgentName,
      fileName: obj.fileName,
      fileType: obj.fileType,
      status: "Pending",
      totalRecord: obj.totalRecord,
      totalSuccess: obj.totalSuccess,
      totalFail: obj.totalFail,
      fileDownloaded: 0,
      requestedBy: user.sub,
      requestedByName: user.name,
    })
  );
  return res;
};

const updateEligibilityFile = async (obj) => {
  const model = await DataStore.query(EligibiliyFiles, (s) => s.id("eq", obj.id));
  const dbEligFile = model[0];
  const res = await DataStore.save(
    EligibiliyFiles.copyOf(dbEligFile, (updated) => {
      updated.fileDownloaded = 1;
    })
  );
  return res;
};

const newSubAgent = async (loc, user) => {
  const res = await DataStore.save(
    new SubAgent({
      name: loc.name,
      clientID: loc.clientID,
      locationID: loc.locationID,
      contact_name: loc.contact_name,
      phone_number: loc.phone_number,
      contact_email: loc.contact_email,
      street: loc.street,
      street2: loc.street2,
      city: loc.city,
      state: loc.state,
      webSite: loc.webSite,
      contactName: loc.contactName,
      countryCode: loc.countryCode,
      country: loc.country,
      zip: loc.zip,
      testOrderCategory: loc.testOrderCategory?.toString(),
      testLimit: loc.testLimit || 8,
      minTestOrderQty: loc.minTestOrderQty,
      preRegistration: loc.preRegistration,
      createdBy: user.sub,
      createdByName: user.name,
    })
  );
  return res;
};

const updateSubAgent = async (loc) => {
  const models = await DataStore.query(SubAgent, (s) => s.id("eq", loc.id));
  await DataStore.save(
    SubAgent.copyOf(models[0], (updated) => {
      updated.name = loc.name;
      updated.clientID = loc.clientID;
      updated.locationID = loc.locationID;
      updated.contact_name = loc.contact_name;
      updated.phone_number = loc.phone_number;
      updated.contact_email = loc.contact_email;
      updated.street = loc.street;
      updated.street2 = loc.street2;
      updated.city = loc.city;
      updated.state = loc.state;
      updated.webSite = loc.webSite;
      updated.contactName = loc.contactName;
      updated.countryCode = loc.countryCode;
      updated.country = loc.country;
      updated.zip = loc.zip;
      updated.testOrderCategory = loc.testOrderCategory?.toString();
      updated.testLimit = loc.testLimit;
      updated.minTestOrderQty = loc.minTestOrderQty;
      updated.preRegistration = loc.preRegistration;
    })
  );
};

const deleteLocation = async (id) => {
  const employees = await DataStore.query(SubAgent, (e) => e.locationID("eq", id));

  if (employees.length > 0) {
    return false;
  }

  await DataStore.delete(Site, (obj) => obj.id("eq", id));
  return true;
};

const deleteSubAgent = async (id) => {
  const employees = await DataStore.query(Employee, (e) => e.subAgent("eq", id));

  if (employees.length > 0) {
    return false;
  }

  await DataStore.delete(SubAgent, (obj) => obj.id("eq", id));
  return true;
};

const newClient = async (loc, user) => {
  const res = await DataStore.save(
    new Client({
      name: loc.name,
      contact_name: loc.contact_name,
      phone_number: loc.phone_number,
      contact_email: loc.contact_email,
      street: loc.street,
      street2: loc.street2,
      city: loc.city,
      state: loc.state,
      webSite: loc.webSite,
      contactName: loc.contactName,
      countryCode: loc.countryCode,
      testOrderCategory: loc.testOrderCategory,
      country: loc.country,
      zip: loc.zip,
      createdBy: user.sub,
      createdByName: user.name,
      testLimit: loc.testLimit || 8,
      minTestOrderQty: loc.minTestOrderQty,
      logo: loc.logo,
    })
  );
  return res;
};

const newCreateUser = async (user) => {
  const res = await DataStore.save(
    new Users({
      clientID: "89aa69bd-d56e-435e-b6c5-12a64a1c4fdc",
      connectedID: "89aa69bd-d56e-435e-b6c5-12a64a1c4fdc",
      companyID: "0dd6697a-9276-4b5f-b241-aa663dc513b4",
      email: "biolabmobile@yopmail.com",
      firstName: "Super",
      lastName: "Admin",
      locationID: "",
      note: "System",
      personalization: JSON.stringify(PERSONALIZATION),
      phone_number: "+13333333333",
      roles: [{ role: "Admin" }],
      status: "Confirm",
      subAgentID: "",
      systemUser: 1,
      userID: "+13333333333_005",
      userRole: "Admins",
    })
  );
  return res;
};

const updateClient = async (loc) => {
  const models = await DataStore.query(Client, (s) => s.id("eq", loc.id));
  await DataStore.save(
    Client.copyOf(models[0], (updated) => {
      updated.name = loc.name;
      updated.contact_name = loc.contact_name;
      updated.phone_number = loc.phone_number;
      updated.contact_email = loc.contact_email;
      updated.street = loc.street;
      updated.street2 = loc.street2;
      updated.city = loc.city;
      updated.webSite = loc.webSite;
      updated.contactName = loc.contactName;
      updated.state = loc.state;
      updated.countryCode = loc.countryCode;
      updated.country = loc.country;
      updated.testOrderCategory = loc.testOrderCategory;
      updated.zip = loc.zip;
      updated.testLimit = loc.testLimit;
      updated.minTestOrderQty = loc.minTestOrderQty;
      updated.logo = loc.logo;
      updated.mdSetting = loc?.mdSetting || models.mdSetting;
    })
  );
};

const updateClientMdSettingInfo = async () => {
  const models = await DataStore.query(Client, (s) => s.id("eq", userCompanyID.get()));
  const obj = {
    appID: "3bf5jls6cvdpzbgsetnd7m7d3e",
    clientID: "5659da45-a80c-478b-8301-2e8d5b2b56ac",
    envName: "devTest",
  };
  await DataStore.save(
    Client.copyOf(models[0], (updated) => {
      updated.mdSetting = obj;
    })
  );
};

const deleteClient = async (id) => {
  const employees = await DataStore.query(Site, (e) => e.clientID("eq", id));

  if (employees.length > 0) {
    return false;
  }

  await DataStore.delete(Client, (obj) => obj.id("eq", id));
  return true;
};

const createOrder = async (ids, orderObj, user) => {
  const ttl = ids.length;
  const limitExcced = [];
  let apiRes = {};
  const path = "/checkEligibility";
  for (let i = 0; i < ttl; i++) {
    const empObj = ids[i];
    if (!empObj) continue;
    const empRecord = await DataStore.query(Employee, (e) => e.id("eq", ids[i]));
    const emp = empRecord[0];
    if (emp.testAvailable < orderObj.quantity) {
      limitExcced.push(emp);
      continue;
    }
    const locationItem = await DataStore.query(Site, (s) => s.id("eq", emp.companyID || CONFIG.generalFacility));
    const model = await DataStore.query(Client, (e) => e.id("eq", emp.clientID || CONFIG.generalClient));
    const client = model[0];
    const locationName = locationItem[0]?.name;
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        empID: ids[i],
        firstName: emp.firstName,
        lastName: emp.lastName,
        companyID: emp.companyID,
        clientID: emp.clientID,
        subAgentID: emp.subAgent,
        clientName: client?.name,
        locationName: locationName,
        testSendQty: orderObj.quantity,
        userName: user.name,
        userID: user.sub,
        details: {
          firstName: emp.firstName,
          lastName: emp.lastName,
          phoneNumber: emp.phoneNumber,
          countryCode: emp.countryCode,
          dob: emp.dob,
          email: emp.email,
          street: emp.street,
          street2: emp.street2,
          state: emp.state,
          zip: emp.zip,
          city: emp.city,
          medicalNo: emp.medicalNo,
          locationName: locationName,
          insuranceCompany: emp.insuranceCompany,
          insuranceGroupId: emp.insuranceGroupId,
        },
        fdos: "20231010", // moment().format("YYYYMMDD"),
      },
    };
    apiRes = await API.post(apiName, path, params);

    if (!apiRes.error) {
      await DataStore.save(
        Employee.copyOf(empRecord[0], (updated) => {
          updated.testOrdered = 0; //emp.testOrdered + orderObj.quantity;
          updated.testAvailable = 0; // emp.testAvailable - orderObj.quantity;
        })
      );
    }
  }
  return [limitExcced, apiRes];
};

// const createBulkOrder = async (employees, user) => {
//   const ttl = employees.length;
//   const limitExcced = [];
//   for (let i = 0; i < ttl; i++) {
//     const empObj = employees[i];
//     const empRecord = await DataStore.query(Employee, (e) =>
//       e.id("eq", empObj.id)
//     );
//     const emp = empRecord[0];
//     if (emp.testAvailable < empObj.quantity) {
//       limitExcced.push(emp);
//       continue;
//     }

//     const locationItem = await DataStore.query(Site, (s) =>
//       s.id("eq", empObj.companyID)
//     );
//     const locationName = locationItem[0].name;

//     await DataStore.save(
//       new EmployeeOrder({
//         empID: empObj.id,
//         employeeID: empObj.id,
//         siteID: emp.companyID,
//         locationID: emp.companyID,
//         clientID: emp.clientID,
//         testQty: empObj.testSendQty,
//         requestedBy: user.sub,
//         requestedByName: user.name,
//         test_type: "Antigen",
//         details: {
//           firstName: emp.firstName,
//           lastName: emp.lastName,
//           phoneNumber: emp.phoneNumber,
//           countryCode: emp.countryCode,
//           street: emp.street,
//           street2: emp.street2,
//           email: emp.email,
//           medicalNo: emp.medicalNo,
//           locationName: locationName,
//         },
//         orderId: "-1",
//         orderStatus: "Pending",
//         orderDate: moment().format("YYYYMMDD"),
//         isActive: 1,
//       })
//     );

//     await DataStore.save(
//       Employee.copyOf(empRecord[0], (updated) => {
//         updated.testOrdered = emp.testOrdered + empObj.testSendQty;
//         updated.testAvailable = emp.testAvailable - empObj.testSendQty;
//       })
//     );
//   }
//   return limitExcced;
// };

const deniedClaimStatus = async (orderID, msg, status, amount, insurance, claim, user) => {
  const orders = await DataStore.query(EmployeeOrder, (e) => e.id("eq", orderID));
  const empObj = orders[0];
  await DataStore.save(
    EmployeeOrder.copyOf(empObj, (updated) => {
      if (insurance) {
        updated.InsuranceStatus = status;
        updated.InsuranceMessage = msg;
        updated.expectedAmount = parseFloat(amount);
      }
      if (claim) {
        updated.claimStatus = "Rejected";
        updated.claimMessage = msg;
      }
    })
  );

  const logObj = {
    firstName: empObj.firstName,
    lastName: empObj.lastName,
    dob: empObj.dob,
    message: msg,
    empID: empObj.employeeID,
    localOrderId: empObj.id,
    medicalNo: empObj.medicalNo,
    groupId: "",
    payerid: empObj.payerId,
    clientName: empObj.clientName,
    locationName: empObj.locationName,
    subAgentName: empObj.subAgentName,
    locationID: empObj.locationID,
    clientID: empObj.clientID,
    subAgentID: empObj.subAgentID,
    userID: user.sub,
    userName: user.name,
    action: null,
    eventType: insurance ? `claim${status}` : "claimRejected",
    eventData: insurance ? status : "Rejected",
    orderId: empObj.orderId,
  };

  await saveLogs(logObj);
};

const addReceivedAmountDetails = async (obj, orderID, user, action) => {
  const orders = await DataStore.query(EmployeeOrder, (e) => e.id("eq", orderID));
  const empObj = orders[0];
  const res = {};
  let amountToSave = parseFloat(obj.amount) || 0;
  if (action === claimActions.return) {
    if (parseFloat(empObj.claimAmount) < parseFloat(obj.amount)) {
      Object.assign(res, {
        error: "Cannot refund amount greater than the Claimed amount.",
      });
      return res;
    }

    amountToSave = parseFloat(empObj.claimAmount) - parseFloat(obj.amount);
  }
  await DataStore.save(
    EmployeeOrder.copyOf(empObj, (updated) => {
      updated.InsuranceStatus = action === claimActions.receive ? "Paid" : "Refund";
      updated.InsuranceMessage = obj.message;
      updated.amountRecieved = amountToSave;
      updated.chequeNo = obj.chequeNo;
      updated.employeeClaimID = obj.employeeClaimID;
      updated.amountRecievedDate = moment(obj.chequeDate).toISOString();
      updated.approvedRemarks = obj.message;
    })
  );
  const logObj = {
    firstName: empObj.firstName,
    lastName: empObj.lastName,
    dob: empObj.dob,
    message: obj.message,
    empID: empObj.employeeID,
    localOrderId: empObj.id,
    medicalNo: empObj.medicalNo,
    groupId: "",
    payerid: empObj.payerId,
    clientName: empObj.clientName,
    locationName: empObj.locationName,
    subAgentName: empObj.subAgentName,
    locationID: empObj.locationID,
    clientID: empObj.clientID,
    subAgentID: empObj.subAgentID,
    userID: user.sub,
    userName: user.name,
    action: null,
    eventType: action === claimActions.receive ? "claimAmountReceived" : "claimAmountReturned",
    eventData: action === claimActions.receive ? "Claim Amount Received" : "Claim Amount Returned",
    details: JSON.stringify({
      amount: obj.amount,
      chequeDate: obj.chequeDate,
      chequeNo: obj.chequeNo,
    }),
    orderId: empObj.orderId,
  };

  await saveLogs(logObj);
};

const UpdateOrderData = async (id) => {
  // let employees = await DataStore.query(EmployeeOrder, (e) => e.id("eq", id));
  let allOrders = await DataStore.query(EmployeeOrder, Predicates.ALL);
  let employees = await DataStore.query(Employee, Predicates.ALL);

  allOrders = allOrders.filter((f) => f.amountRecieved === 82.32);
  console.log("Total", allOrders);
  const ttl = allOrders.length;
  const newList = [];
  for (let i = 0; i < ttl; i++) {
    // const totalOrders = empOrders.reduce((result, value) => {
    //   result = result + value.testQty;
    //   return result;
    // }, 0);
    //console.log("totalOrders", totalOrders, emp.testOrdered);
    // await DataStore.save(
    //   EmployeeOrder.copyOf(allOrders[i], (updated) => {
    //     updated.amountRecieved = 94.08;
    //     // updated.testQty = 0;
    //     // updated.subAgentID = "f8fe97c1-e689-4fd3-8924-55fdd6a5fb9c";
    //     // updated.subAgentName = "Billing Requests";
    //   })
    // );
  }

  console.log("Employee", newList);
  return newList;
  console.log("Employees", employees);
  // await DataStore.save(
  //   EmployeeOrder.copyOf(employees[0], (updated) => {
  //     updated.orderDate = "20221001";
  //     // updated.testQty = 0;
  //     // updated.subAgentID = "f8fe97c1-e689-4fd3-8924-55fdd6a5fb9c";
  //     // updated.subAgentName = "Billing Requests";
  //   })
  // );
};

const updateOrderStatus = async (Ids, status, trackingNumber, orderDateTime, msg, user) => {
  for (let i = 0; i < Ids.length; i++) {
    const id = Ids[i];
    const employees = await DataStore.query(EmployeeOrder, (e) => e.id("eq", id));
    console.log("Employees", employees);
    const empObj = employees[0];
    await DataStore.save(
      EmployeeOrder.copyOf(empObj, (updated) => {
        updated.orderStatus = status;
        if (trackingNumber) {
          updated.trackingNumber = trackingNumber;
        }
      })
    );

    const logObj = {
      firstName: empObj.firstName,
      lastName: empObj.lastName,
      dob: empObj.dob,
      logDate: orderDateTime,
      ...(trackingNumber && { trackingNo: trackingNumber }),
      message: msg,
      empID: empObj.employeeID,
      localOrderId: empObj.id,
      medicalNo: empObj.medicalNo,
      groupId: "",
      payerid: empObj.payerId,
      clientName: empObj.clientName,
      locationName: empObj.locationName,
      subAgentName: empObj.subAgentName,
      locationID: empObj.locationID,
      clientID: empObj.clientID,
      subAgentID: empObj.subAgentID,
      userID: user.sub,
      userName: user.name,
      action: null,
      eventType: `Order${OrderStatusEvents[status]}`,
      eventData: `Order ${OrderStatusEvents[status]}`,
      orderId: empObj.orderId,
    };
    await saveLogs(logObj);
  }
};

const updateOrdQtyManual = async (orders, ordQty, subagent, user) => {
  const ttl = orders.length;
  console.log({ orders, ordQty, subagent });
  for (let i = 0; i < ttl; i++) {
    const id = orders[i];
    const employees = await DataStore.query(EmployeeOrder, (e) => e.id("eq", id));
    console.log("Employees", employees);
    const empObj = employees[0];
    await DataStore.save(
      EmployeeOrder.copyOf(empObj, (updated) => {
        updated.testQty = ordQty;
        if (subagent) {
          updated.subAgentName = subagent.name;
          updated.subAgentID = subagent.id;
          updated.orderStatus = "Pending";
        }
      })
    );
  }
};

const updateOrdManual = async () => {
  const orders = await DataStore.query(EmployeeOrder, (e) => e.pcn("eq", "LTCS-002304"));
  console.log("orders", orders);
  for (const ord of orders) {
    await DataStore.save(
      EmployeeOrder.copyOf(ord, (updated) => {
        updated.payerId = "87726";
        updated.medicalNo = "704000601403";
        updated.payerName = "United Health Care";
      })
    );
  }
};

const updatePatientHospice = async (emp) => {
  const dbEmp = await graphqlApi.getEmployeeByIdGQl(emp.id);
  const updatedEmp = {
    id: dbEmp.id,
    isHospice: true,
    _version: dbEmp._version,
  };
  await graphqlApi.updateEmployeeGQL(updatedEmp);
};

const updateTestAvailable = async (Ids, testQty) => {
  const ttl = Ids.length;
  for (let i = 0; i < ttl; i++) {
    const Id = Ids[i];
    const model = await DataStore.query(Employee, (e) => e.id("eq", Id));
    const dbEmp = model[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.testAvailable = testQty;
        //  updated.testOrdered = 16;
      })
    );
  }
};

const UpdateOrderForTest = async (Ids) => {
  // let employees = await DataStore.query(Employee, Predicates.ALL);
  const allOrders = await DataStore.query(EmployeeOrder, Predicates.ALL);
  // employees = employees.filter((f) => f.status === "active");
  // console.log("Status", employees);
  // let arr = [];
  // for (let i = 0; i < employees.length; i++) {
  //   const m = employees[i];
  //   // await DataStore.save(
  //   //   Employee.copyOf(m, (updated) => {
  //   //     updated.testAvailable = 8;
  //   //   })
  //   // );
  // }
  // console.log("Employee", arr);

  // return arr;

  // // const orderList = allOrders.filter(
  // //   (f) => f.claimStatus === "Rejected" && f.InsuranceStatus === "Paid"
  // // );
  // // const employeesList = employees.filter(
  // //   (e) => e.testAvailable > 0 && e.eligibilityStatus?.message === "Eligibile"
  // // );

  // console.log("IDS", Ids);

  // for (let i = 0; i < Ids.length; i++) {
  //   let ord = Ids[i];
  //   const modal = await DataStore.query(EmployeeOrder, (e) =>
  //     e.id("eq", ord.id)
  //   );
  //   const order = modal[0];
  //   await DataStore.save(
  //     EmployeeOrder.copyOf(order, (updated) => {
  //       updated.orderStatus = "Delivered";
  //     })
  //   );
  // }

  // // for (let i = 0; i < billOnly.length; i++) {
  // //   let order = billOnly[i];
  // //   await DataStore.save(
  // //     EmployeeOrder.copyOf(order, (updated) => {
  // //       updated.orderStatus = "Sent";
  // //     })
  // //   );
  // // }

  // return;
  let employees = await DataStore.query(Employee, (e) => e.status("eq", "active"));
  const list = [
    {
      ltcID: "dummy",
      firstName: "dummy",
      lastName: "dummy",
      email: "dummya",
      phoneNumber: "dummy",
      dob: "dummy",
      testOrdered: "testorder",
      street: "dummy",
      street2: "dummy",
      ciyt: "dummy",
      state: "dummy",
      zip: "dummy",
      sept: 9,
      oct: 0,
      nov: 0,
      dec: 0,
      jan: 0,
      feb: 0,
      status: "dummy",
      septClaim: "duummy",
      octClaim: "duummy",
      novClaim: "duummy",
      decClaim: "duummy",
      janClaim: "duummy",
      febClaim: "duummy",
      note: "dummay",
    },
  ];
  for (let i = 0; i < employees.length; i++) {
    const e = employees[i];

    const employee = {
      ltcID: e.schrID,
      firstName: e.firstName,
      lastName: e.lastName,
      email: e.email,
      phoneNumber: e.phoneNumber,
      dob: e.dob,
      testOrdered: e.testOrdered,
      street: e.street,
      street2: e.street2,
      ciyt: e.city,
      state: e.state,
      zip: e.zip,
      status: e.status,
      note: e.note,
    };
    const orders = allOrders.filter((f) => f.employeeID === e.id);

    if (orders.length > 0) {
      const sept = orders.filter((f) => f.orderDate === "20220901");

      if (sept.length > 0) {
        employee.sept = 0;
        employee.septClaim = sept[0].InsuranceStatus;
        console.log("sept", employee);
      }
      const oct = orders.filter((f) => f.orderDate === "20221001");
      if (oct.length > 0) {
        employee.oct = oct[0]?.testQty;
        employee.octClaim = oct[0].InsuranceStatus;
      }

      const nov = orders.filter((f) => f.orderDate === "20221101");
      if (nov.length > 0) {
        employee.nov = nov[0].testQty;
        employee.novClaim = nov[0].InsuranceStatus;
      }

      const dec = orders.filter((f) => f.orderDate === "20221201");
      if (dec.length > 0) {
        employee.dec = dec[0].testQty;
        employee.decClaim = dec[0].InsuranceStatus;
      }
      const jan = orders.filter((f) => f.orderDate === "20230101");
      if (jan.length > 0) {
        employee.jan = jan[0].testQty;
        employee.janClaim = jan[0].InsuranceStatus;
      }

      const feb = orders.filter((f) => f.orderDate === "20230201");
      if (feb.length > 0) {
        employee.feb = feb[0].testQty;
        employee.febClaim = feb[0].InsuranceStatus;
      }

      list.push({ ...employee });
    } else {
      list.push({ ...employee });
    }
  }
  return list;
  // await DataStore.save(
  //   Employee.copyOf(employees[0], (updated) => {
  //     updated.testAvailable = 8;
  //   })
  // );

  // const emps = await DataStore.query(Employee, Predicates.ALL);

  // const members = emps.filter(
  //   (f) => f.insuranceCompany !== "01192" && f.medicareNo
  // );

  // console.log('Employee', members);

  // const updateMember = members.map((m) => {
  //   return {
  //     ...m,
  //     insuranceCompany: "01192",
  //     insuranceCompanyCode: "Medicare",
  //     medicalNo: m.medicareNo,
  //     insuranceDetails: {
  //       insuranceCompany: m.insuranceCompany,
  //       insuranceCompanyCode: m.insuranceCompanyCode,
  //       insuranceGroupId: m.insuranceGroupId,
  //       medicalNo: m.medicalNo,

  //     },
  //   };
  // });

  // for (let i = 0; i < employees.length; i++) {
  //   await DataStore.save(
  //     Employee.copyOf(employees[i], (updated) => {
  //       updated.testAvailable = 0;
  //       updated.testOrdered = 0;
  //     })
  //   );
  // }

  return;
  const ttl = employees.length;
  for (let i = 0; i < ttl; i++) {
    const emp = employees[i];

    // if (empOrders.length > 0) {
    //   await DataStore.save(
    //     Employee.copyOf(emp, (updated) => {
    //       updated.testAvailable = 0;
    //     })
    //   );
    // }
    //  console.log('empOrders',empOrders)
  }

  // console.log(
  //   "orders",
  //   orders.filter((f) => f.InsuranceMessage && f.InsuranceStatus === "Denied")
  // );
  // const orderToUpdate = orders.filter(
  //   (f) => f.InsuranceMessage && f.InsuranceStatus === "Denied"
  // );
  // const ttl = orderToUpdate.length;
  // for (let i = 0; i < ttl; i++) {
  //   const order = orderToUpdate[i];
  //   await DataStore.save(
  //     EmployeeOrder.copyOf(order, (updated) => {
  //       updated.InsuranceStatus = 'Paid';
  //     })
  //   );
  // }

  // const ttl = orderToUpdate.length;
  // for (let i = 0; i < ttl; i++) {
  //   const order = orderToUpdate[i];
  //   await DataStore.save(
  //     EmployeeOrder.copyOf(order, (updated) => {
  //       updated.subAgentName = "Website Requests";
  //     })
  //   );
  // }
  // if (emp) {
  //   console.log("PCN", empOrder.pcn, emp.id, emp.schrID);
  //   await DataStore.save(
  //     EmployeeOrder.copyOf(empOrder, (updated) => {
  //       updated.payerName = insuranceProviderFormatter(emp.insuranceCompany);
  //       updated.locationID = "ee258792-d7d6-4edb-ac23-1acfd903e750";
  //       updated.clientID = "c092f107-d3a3-4942-b9fb-83a537e32249";
  //       updated.subAgentID = "75b8b762-d5df-47a9-ba27-760e40797917";
  //       updated.clientName = "Tackl Health";
  //       updated.locationName = "Internal Tackl Health";
  //       updated.subAgentName = "Website Requests";
  //       updated.orderStatus = "Delivered";
  //       updated.claimAmount = 96;
  //       updated.testQty = 0;
  //       updated.pcn = emp.schrID;
  //       updated.claimSubmitDate = moment().toISOString();
  //     })
  //   );
  // }
};

const createPreviousOrder = async (empObj, user) => {
  const obj = {
    empID: empObj.id,
    employeeID: empObj.id,
    siteID: empObj.companyID,
    locationID: empObj.companyID,
    clientID: empObj.clientID,
    testQty: empObj.qty,
    requestedBy: user.sub,
    requestedByName: user.name,
    test_type: "Antigen",
    details: {
      firstName: empObj.firstName,
      lastName: empObj.lastName,
      phoneNumber: empObj.phoneNumber || "",
      countryCode: empObj.countryCode,
      street: empObj.street,
      street2: empObj.street2,
      city: empObj.city,
      state: empObj.state,
      country: empObj.country,
      email: empObj.email || "",
      medicalNo: empObj.medicalNo,
      clientName: "Tackl Health",
      locationName: "Internal Tackl Health",
    },
    clientName: "Tackl Health",
    locationName: "Internal Tackl Health",
    orderId: "-1",
    orderStatus: "Processed",
    orderDate: "20230101",
    isActive: 1,
  };

  // await DataStore.save(new EmployeeOrder(obj));

  return obj;
};

const getOrdersBilling = async (type, id) => {
  if (type && type === USER_TYPE_USER) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.employeeID("eq", id).claimSubmitDate("ne", null));
    return models;
  }

  if (type && type === USER_TYPE_CLIENT) {
    const models = await DataStore.query(EmployeeOrder, (e) =>
      e.clientID("eq", userCompanyID.get()).claimSubmitDate("ne", null)
    );
    return models;
  }

  if (type && type === USER_TYPE_SUBAGENT) {
    const models = await DataStore.query(EmployeeOrder, (e) =>
      e.subAgentID("eq", userCompanyID.get()).claimSubmitDate("ne", null)
    );
    return models;
  }

  if (userCompanyID.get()) {
    const models = await DataStore.query(EmployeeOrder, (e) =>
      e.siteID("eq", userCompanyID.get()).claimSubmitDate("ne", null)
    );
    return models;
  }
  const models = await DataStore.query(EmployeeOrder, (e) => e.claimSubmitDate("ne", null));

  return models;
};

const getAllPendingOrders = async (type, id) => {
  const models = await DataStore.query(EmployeeOrder, (e) => e.orderStatus("ne", "Delivered"));

  return models;
};

const getOrders = async (type, id) => {
  if (type && type === USER_TYPE_USER) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.employeeID("eq", id));
    return models;
  }

  if (type && type === USER_TYPE_CLIENT) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.clientID("eq", userCompanyID.get()));
    return models;
  }

  if (type && type === USER_TYPE_SUBAGENT) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.subAgentID("eq", userCompanyID.get()));
    return models;
  }

  if (userCompanyID.get()) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.siteID("eq", userCompanyID.get()));
    return models;
  }
  const models = await DataStore.query(EmployeeOrder, Predicates.ALL);

  return models;
};

const deleteOrder = async (items) => {
  const ttl = items.length;
  for (let i = 0; i < ttl; i++) {
    const obj = items[i];
    if (obj) {
      const employeesOrders = await DataStore.query(EmployeeOrder, (e) => e.employeeID("eq", obj.employeeID));

      const totalOrders = employeesOrders.reduce((val, obj) => {
        return obj.testQty + val;
      }, 0);

      const thisMonthOrder = employeesOrders.filter((f) =>
        moment(f.orderDate).isBetween(moment().startOf("month"), moment().endOf("month"))
      );

      if (
        thisMonthOrder.length === 1 &&
        moment(obj.orderDate).isBetween(moment().startOf("month"), moment().endOf("month"))
      ) {
        if (obj.testQty > 0) {
          const models = await DataStore.query(Employee, (e) => e.id("eq", obj.employeeID));
          if (models.length > 0) {
            const dbEmp = models[0];
            const testOrdered = dbEmp.testOrdered - obj.testQty < 0 ? 0 : dbEmp.testOrdered - obj.testQty;
            const testAvailable = dbEmp.testAvailable + obj.testQty;

            await DataStore.save(
              Employee.copyOf(dbEmp, (updated) => {
                updated.testOrdered = testOrdered;
                updated.testAvailable = testAvailable;
              })
            );
          }
        }
      } else {
        if (obj.testQty > 0) {
          const models = await DataStore.query(Employee, (e) => e.id("eq", obj.employeeID));
          if (models.length > 0) {
            const testOrdered = totalOrders - obj.testQty;
            const dbEmp = models[0];
            await DataStore.save(
              Employee.copyOf(dbEmp, (updated) => {
                updated.testOrdered = testOrdered;
              })
            );
          }
        }
      }
      await DataStore.delete(EmployeeOrder, (o) => o.id("eq", obj.id));
    }
  }
  return true;
};

const getOrderByEmployee = async (employeeID) => {
  let model = [];
  let pageNextToken = null;
  const params = {
    filter: { employeeID: { eq: employeeID } },
    limit: 3000,
  };

  try {
    do {
      if (pageNextToken) {
        Object.assign(params, { nextToken: pageNextToken });
      }
      const graphqlData = await API.graphql(graphqlOperation(queries.listEmployeeOrders, params));
      model = model.concat(graphqlData.data.listEmployeeOrders.items);
      pageNextToken = graphqlData.data.listEmployeeOrders.nextToken;
    } while (pageNextToken);
    return model;
  } catch (err) {
    console.log("Error:- EmployeeGraphQl", err.message);
  }
};

const getLocationInformation = async (id, type) => {
  const counterData = await axios.post(GET_LOCATION_URL, { id, type });
  return counterData.data.data;
};

const checkOrderEligibility = async (obj, nameData, user) => {
  const path = "https://jc24amz3s1.execute-api.eu-west-1.amazonaws.com/staging/eligibility";
  const data = {
    firstName: obj.firstName,
    lastName: obj.lastName,
    dob: obj.dob,
    userName: user?.name,
    userID: user?.sub,
    groupId: obj.insuranceGroupId,
    medicalNo: obj.medicalNo,
    insuranceCompany: obj.insuranceCompany,
    insuranceProvider: obj.insuranceProvider,
    clientID: obj.clientID,
    locationID: obj.locationID,
    subAgentID: obj.subAgentID,
    ...nameData,
  };
  const res = await axios.post(path, { data });
  return res.data;
};

const getPatientData = async (medicalNo, groupId, insuranceId) => {
  const counterData = await axios.post(PATIENT_DATA_URL, {
    medicalNo,
    groupId,
    insuranceId,
    location: 1,
  });

  return counterData.data.data;
};

const getUserValidate = async (obj) => {
  const counterData = await axios.post(PATIENT_DATA_URL, {
    medicalNo: obj.medicalNo,
    medicareNo: obj.medicareNo,
    groupId: obj.insuranceGroupId,
    insuranceId: obj.insuranceCompany,
    firstName: obj.firstName,
    lastName: obj.lastName,
    dob: obj.dob,
    id: obj.id,
    isValidate: true,
  });

  return counterData.data.data;
};

const isUserDemoExists = async (obj) => {
  const api = await callAmplifyAPI("/employees/exists", obj);
  return api.items;
};

const placeOrder = async (data) => {
  const counterData = await axios.post(PLACE_ORDER, { data });

  return counterData.data;
};

const getUserSite = async () => {
  const models = await DataStore.query(Site, (e) => e.id("eq", userCompanyID.get()));
  return models[0];
};

const getUserSubAgent = async () => {
  const models = await DataStore.query(SubAgent, (e) => e.id("eq", userCompanyID.get()));
  return models[0];
};

const getUserDefaultSubAgent = async () => {
  const models = await DataStore.query(SubAgent, (e) => e.clientID("eq", userCompanyID.get()).isDefault("eq", true));
  return models[0];
};

const getUserCompany = async () => {
  const models = await DataStore.query(Client, (e) => e.id("eq", userCompanyID.get()));
  return models[0];
};

const getOrderLogs = async (id, type) => {
  const path = "/getOrderLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
      type,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const saveLogs = async (logObj) => {
  const path = "/saveLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: logObj,
  };
  await API.post(apiName, path, params);
};

const getEmployeeLogs = async (id) => {
  const path = "/getEmployeeLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes;
};

const deleteLogRecord = async (id) => {
  const path = "/deleteLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes;
};

const createBulkOrder = async (employees, user, responseEligibility, orderMonth, billingOnly) => {
  const path = "/checkEligibility";
  const limitExcced = [];

  employees.forEach(async (empObj) => {
    if (empObj.testAvailable < empObj.quantity) {
      limitExcced.push(empObj);
      return;
    }
    const model = await DataStore.query(Client, (e) => e.id("eq", empObj.clientID || CONFIG.generalClient));
    const client = model[0];
    const bodyObj = {
      empID: empObj.id,
      firstName: empObj.firstName,
      lastName: empObj.lastName,
      clientID: empObj.clientID,
      companyID: empObj.companyID,
      subAgentID: empObj.subAgentID,
      clientName: client?.name || "Tackl Health",
      locationName: empObj.location?.name || "Internal Tackl Health",
      subAgentName: empObj.subAgentName || "Website Requests",
      testSendQty: empObj.testSendQty,
      schrID: empObj.schrID,
      userName: user.name,
      userID: user.sub,
      details: {
        firstName: empObj.firstName,
        lastName: empObj.lastName,
        phoneNumber: empObj.phoneNumber,
        countryCode: empObj.countryCode,
        dob: empObj.dob,
        street: empObj.street,
        street2: empObj.street2,
        zip: empObj.zip,
        city: empObj.city,
        state: empObj.state,
        email: empObj.email,
        medicalNo: empObj.medicareNo || empObj.medicalNo,
        locationName: empObj.location?.name || "Internal Tackl Health",
        insuranceCompany: "01192",
        insuranceGroupId: empObj.insuranceGroupId,
        insuranceProvider: "Medicare",
      },
      orderDate: moment(orderMonth).format("YYYYMMDD"),
      fdos: moment(orderMonth).format("YYYYMMDD"),
      billOnly: billingOnly,
      empOp: "e",
    };
    try {
      const params = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
        body: bodyObj,
      };
      const apiRes = await API.post(apiName, path, params);
      if (apiRes.error) {
        responseEligibility({ empId: apiRes.id, msg: apiRes.error.error_mesg, errorStatus: "1" });
      } else {
        responseEligibility({ empId: apiRes.id, msg: "suceess", errorStatus: "0" });
      }
    } catch (error) {
      if (error.request) {
        responseEligibility({ empId: bodyObj.empID, msg: "Network Error", errorStatus: "1" });
      } else {
        // There was an error setting up the request
        responseEligibility({ empId: error.id, msg: error.message, errorStatus: "1" });
      }
    }
  });
};

const checkManualBulkEligibility = async (employees, user) => {
  const path = "/checkEligibility";

  const ttl = employees.length;
  const resStatus = [];
  const response = [];
  for (let index = 0; index < ttl; index++) {
    try {
      const empObj = employees[index];
      if (CUSTOM_INS.includes(empObj.insuranceCompany)) {
        response.push({
          emp: empObj,
          res: DUMMY_ELIG_RES,
        });
        resStatus.push("Passed");
        if (!empObj.status || empObj.status === "inactive") await updateEmpSingleField("status", "active", empObj);
        continue;
      }
      const model = await DataStore.query(Client, (e) => e.id("eq", empObj.clientID || CONFIG.generalClient));
      const client = model[0];

      const params = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
        body: {
          empID: empObj.id,
          firstName: empObj.firstName,
          lastName: empObj.lastName,
          middleName: empObj.middleName,
          clientID: empObj.clientID,
          companyID: empObj.companyID,
          subAgentID: empObj.subAgentID,
          clientName: client?.name || "",
          locationName: empObj.location?.name || "",
          subAgentName: "",
          testSendQty: 8,
          userName: user.name,
          userID: user.sub,
          details: {
            firstName: empObj.firstName,
            lastName: empObj.lastName,
            phoneNumber: empObj.phoneNumber,
            countryCode: empObj.countryCode,
            dob: empObj.dob,
            street: empObj.street,
            street2: empObj.street2,
            zip: empObj.zip,
            city: empObj.city,
            state: empObj.state,
            email: empObj.email,
            medicalNo: empObj.medicalNo,
            locationName: empObj.location?.name || "",
            insuranceCompany: empObj.insuranceCompany,
            insuranceGroupId: empObj.insuranceGroupId,
            insuranceProvider: insuranceProviderFormatter(empObj.insuranceCompany),
          },
          fdos: moment().format("YYYYMMDD"),
        },
      };
      const apiRes = await API.post(apiName, path, params);
      console.log("apiRes", apiRes);
      const res = apiRes.data;
      if (CONFIG.isLabType) {
        response.push({ emp: empObj, res: apiRes.data });
        continue;
      }
      if (res && res.benefit && res.benefit.length > 0) {
        const isEligibleClaim = res.benefit.find(
          (f) =>
            f.benefit_code == "30" &&
            f.benefit_coverage_code == "1" &&
            f.insurance_type_description.toLowerCase() === "medicare part b"
        );
        if (isEligibleClaim) {
          resStatus.push("Passed");
        } else {
          resStatus.push("Failed");
        }
      } else {
        resStatus.push("Failed");
      }
    } catch (err) {
      console.log("Err", err);
    }
  }
  return [resStatus, response];
};

const checkManualEligibility = async (empObj, user) => {
  const path = "/checkEligibility";

  try {
    const model = await DataStore.query(Client, (e) => e.id("eq", empObj.clientID || userCompanyID.get()));
    const subAgentModel = await DataStore.query(SubAgent, (e) =>
      e.id("eq", empObj.subAgentID || CONFIG.generalSubAgent)
    );
    const locationModel = await DataStore.query(SubAgent, (e) =>
      e.id("eq", empObj.companyID || CONFIG.generalFacility)
    );
    const subAgent = subAgentModel[0];
    const location = locationModel[0];
    const client = model[0];

    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        empID: empObj.id,
        firstName: empObj.firstName,
        lastName: empObj.lastName,
        middleName: empObj.middleName,
        clientID: empObj.clientID,
        companyID: empObj.companyID,
        subAgentID: empObj.subAgentID,
        clientName: client?.name,
        locationName: location?.name,
        subAgentName: subAgent?.name,
        testSendQty: 8,
        userName: user.name,
        userID: user.sub,
        notLinkwithEmployee: empObj?.notLinkwithEmployee,
        details: {
          firstName: empObj.firstName,
          lastName: empObj.lastName,
          phoneNumber: empObj.phoneNumber,
          countryCode: empObj.countryCode,
          dob: empObj.dob,
          street: empObj.street,
          street2: empObj.street2,
          zip: empObj.zip,
          city: empObj.city,
          state: empObj.state,
          email: empObj.email,
          medicalNo: empObj.medicalNo || empObj.ssn,
          locationName: empObj.location?.name,
          insuranceCompany: empObj.insuranceCompany,
          insuranceGroupId: empObj.insuranceGroupId,
          insuranceProvider: insuranceProviderFormatter(empObj.insuranceCompany),
        },
        fdos: empObj.fdos || moment().format("YYYYMMDD"),
      },
    };
    const apiRes = await API.post(apiName, path, params);

    const res = apiRes;
    return res;
  } catch (err) {
    console.log("Err", err);
  }
};

const deleteRelations = async (params) => {
  const apiRes = await callAmplifyAPIWithError("/employees/connected/delete", params);
  return apiRes;
};

const checkBRMEligibility = async (empObj) => {
  try {
    const model = await DataStore.query(Client, (e) => e.id("eq", empObj.clientID || CONFIG.generalClient));

    const client = model[0];

    const path = "/checkBRMEligibility";

    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        ...empObj,
        clientName: client?.name,
        locationName: client?.name,
        subAgentName: client?.name,
      },
    };
    const apiRes = await API.post(apiName, path, params);
    console.log("apiRes", apiRes);
    const res = apiRes;
    return res;
  } catch (err) {
    console.log("Err", err);
  }
};

const saveFileStorage = async (id, type, fileContent) => {
  try {
    let fileName = `${id || "w"}`;
    if (type) fileName = `${fileName}_${type}_${moment().format("YYYY_MM_DD_HH_mm_ss")}.png`;
    await Storage.put(`VaccinationImage/${fileName}`, fileContent);
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const saveDocumentFile = async (id, type, fileContent) => {
  try {
    let fileName = `Document/${id}/${moment().format("YYYY_MM_DD_HH_mm_ss")}$${type}`;
    await Storage.put(`${fileName}`, fileContent, {
      bucket: CONFIG.documentBucket,
    });
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const saveInvoiceDocFile = async (type, fileContent) => {
  try {
    let fileName = `InvoiceDocs/${CONFIG.envType}/${moment().format("YYYY_MM_DD_HH_mm_ss")}$${type}`;
    await Storage.put(`${fileName}`, fileContent, {
      bucket: CONFIG.eligibilityBucket,
    });
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const saveImageFile = async (id, fileContent) => {
  try {
    let fileName = `${id || "ltc"}`;
    fileName = `${fileName}_${moment().format("YYYY_MM_DD_HH_mm_ss")}.png`;
    await Storage.put(`logoImages/${fileName}`, fileContent, {
      bucket: "medflow-images",
    });
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const updateOrderInsurance = async (orders, payer, user) => {
  const ttl = orders.length;
  for (let i = 0; i < ttl; i++) {
    const orderObj = orders[i];
    const orderRecord = await DataStore.query(EmployeeOrder, (e) => e.id("eq", orderObj.id));

    await DataStore.save(
      EmployeeOrder.copyOf(orderRecord[0], (updated) => {
        updated.payerId = payer.payerid;
        updated.payerName = payer.label;
      })
    );

    const logObj = {
      firstName: orderObj.firstName,
      lastName: orderObj.lastName,
      dob: orderObj.dob,
      localOrderId: orderObj.id,
      empID: orderObj.employeeID,
      medicalNo: orderObj.medicalNo,
      clientName: orderObj.clientName,
      locationName: orderObj.locationName,
      subAgentName: orderObj.subAgentName,
      locationID: orderObj.locationID,
      clientID: orderObj.clientID,
      subAgentID: orderObj.subAgentID,
      userID: user.sub,
      userName: user.name,
      message: `Change insurance provider ${orderRecord[0].payerName} to ${payer.label}`,
      action: null,
      eventType: "OrderUpdate",
      eventData: "OrderUpdate",
      orderId: orderObj.orderId,
    };
    await saveLogs(logObj);
  }
  return true;
};

const updateOrderQuantity = async (employees) => {
  const ttl = employees.length;
  const limitExcced = [];
  for (let i = 0; i < ttl; i++) {
    const empObj = employees[i];
    const empRecord = await DataStore.query(Employee, (e) => e.id("eq", empObj.id));
    const emp = empRecord[0];
    if (emp.testAvailable < emp.quantity) {
      limitExcced.push(emp);
      continue;
    }
    // await DataStore.save(
    //   Employee.copyOf(empRecord[0], (updated) => {
    //     if (!empObj.error) {
    //       updated.testOrdered = emp.testOrdered + empObj.testSendQty;
    //       updated.testAvailable = emp.testAvailable - empObj.testSendQty;
    //     }
    //     updated.autoShipment = empObj.autoShipment;
    //   })
    // );
  }
  return limitExcced;
};
// https://npiregistry.cms.hhs.gov/api/?number=1093937708&enumeration_type=&taxonomy_description=&name_purpose=&first_name=&use_first_name_alias=&last_name=&organization_name=&address_purpose=&city=&state=&postal_code=&country_code=&limit=200&skip=&pretty=&version=2.1

const getProviderPublicAPI = async (provider) => {
  let params = `first_name=${encodeURIComponent(provider.firstName)}&last_name=${encodeURIComponent(
    provider.lastName
  )}&number=${encodeURIComponent(provider.npi)}`;

  params += `&limit=200&version=2.1`;

  const notification = await axios.get(`${PROVIDER_URL}?${params}`);

  return notification.data;
};

const createSystemUser = async (newUser, obj) => {
  if (!newUser.isSignUp) {
    const attributePayload = {
      preferred_username: obj.data,
      email: newUser.email,
      phone_number: newUser.phoneNumber,
      "custom:role": "Employees",
      "custom:autoConfirm": "true",
      "custom:firstName": `${newUser.firstName}`,
      "custom:lastName": `${newUser.lastName}`,
      "custom:note": `${newUser.firstName} ${newUser.lastName}`,
      "custom:labID": obj.id,
      "custom:personalisation": JSON.stringify({
        personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
      }),
    };
    console.log("attributePayload", attributePayload);
    const signUp = await Auth.signUp({
      username: obj.data,
      password: newUser.password,
      attributes: attributePayload,
    });
  }
};

const addOrderNote = async (obj) => {
  const model = await DataStore.query(EmployeeOrder, (e) => e.id("eq", obj.id));
  const dbOrder = model[0];
  if (dbOrder) {
    await DataStore.save(
      EmployeeOrder.copyOf(dbOrder, (updated) => {
        updated.note = obj.note;
      })
    );
  }
  if (dbOrder) {
    const emp = await graphqlApi.getEmployeeByIdGQl(dbOrder.employeeID);
    if (emp) {
      let updated = {
        id: emp.id,
        note: obj.note.message,
        noteAddedBy: obj.userName,
        updatedBy: obj.userID,
        updatedByName: obj.userName,
        _version: emp._version,
      };
      await graphqlApi.updateEmployeeGQL(updated);
    }
  }
  const path = "/saveNotesInLogs";
  const params = {
    body: obj,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const addClaimNote = async (obj) => {
  const model = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbOrder = model[0];
  if (dbOrder) {
    await DataStore.save(
      EmployeeClaims.copyOf(dbOrder, (updated) => {
        updated.note = obj.note;
      })
    );
  }
  if (dbOrder) {
    const modal = await DataStore.query(Employee, (e) => e.id("eq", dbOrder.employeeID));
    const emp = modal[0];
    if (emp) {
      await DataStore.save(
        Employee.copyOf(emp, (updated) => {
          updated.note = obj.note;
          updated.noteAddedBy = obj.userName;
          updated.updatedBy = obj.userID;
          updated.updatedByName = obj.userName;
        })
      );
    }
  }
  const path = "/saveNotesInLogs";
  const params = {
    body: obj,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const addMemberNote = async (obj, user) => {
  const dbEmp = await graphqlApi.getEmployeeByIdGQl(obj.id);
  if (dbEmp) {
    let updated = {
      id: dbEmp.id,
      note: obj.note,
      status: obj.status,
      noteAddedBy: obj.userName,
      updatedBy: obj.userID,
      updatedByName: obj.userName,
      _version: dbEmp._version,
    };

    if (obj.status === "inactive") {
      updated.testAvailable = 0;
    }
    await graphqlApi.updateEmployeeGQL(updated);
    if (dbEmp.status !== obj.status) {
      const logObj = {
        firstName: dbEmp.firstName,
        lastName: dbEmp.lastName,
        dob: dbEmp.dob,
        empID: dbEmp.id,
        medicalNo: dbEmp.medicalNo,
        locationID: dbEmp.companyID,
        clientID: dbEmp.clientID,
        subAgentID: dbEmp.subAgent,
        userID: user.sub,
        userName: user.name,
        action: `User Marked ${CapsFirstLetter(obj.status)}`,
        eventType: "UpdateStatus",
        eventData: "UpdateStatus",
      };

      await saveLogs(logObj);
    }
  }
};

const getProviderInfo = async () => {
  const models = await DataStore.query(ProviderInfo, (e) => e.clientID("eq", userCompanyID.get()));
  return models;
};

const getEmployeeClaims = async () => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.clientID("eq", userCompanyID.get()));
  return models;
};

const getEmployeeClaimsAPI = async (empID) => {
  let params = {};
  if (empID) Object.assign(params, { employeeID: empID });
  if (CONFIG.isLabType) {
    Object.assign(params, { clientID: userCompanyID.get() });
  }
  const res = await getClaimList(params);
  return res.rows;
};

const getClaimByIdAPI = async (id) => {
  const params = { id };
  if (CONFIG.isLabType) {
    Object.assign(params, { clientID: userCompanyID.get() });
  }
  const res = await getClaimList(params);
  return res.rows;
};

const getUnpaidClaimsInBetween = async () => {
  let models = await DataStore.query(CompanyStats, (e) => e.id("eq", userCompanyID.get()).month("eq", "aging"));
  if (models.length > 0) {
    return models[0].daysStats;
  }

  return [];
  return [
    {
      color: "#A52A2A",
      count: 22,
      interval: "Less then 30 days",
      total_charge: 6405,
    },
    {
      color: "#7736F3",
      count: 0,
      interval: "30 days",
      total_charge: 0.0,
    },
    {
      color: "#A378F3",
      count: 0,
      interval: "60 days",
      total_charge: 0.0,
    },
    {
      color: "#9CF9D5",
      count: 0,
      interval: "90 days",
      total_charge: 0.0,
    },
  ];
};

const getLastestDeniedRejectedClaims = async () => {
  const models = await DataStore.query(
    EmployeeClaims,
    (e) => e.clientID("eq", userCompanyID.get()).status("eq", "denied" || "rejected"),
    {
      sort: (c) => c.updatedAt(SortDirection.DESCENDING),
      limit: 10,
    }
  );

  return models;
};

const filterPaidClaims = async (obj) => {
  Object.assign(obj, {
    clientID: userCompanyID.get(),
  });
  const path = "/getClientStats";
  const params = {
    body: obj,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error changing user role:", e);
    return null;
  }
};

const getBRMUsers = async () => {
  const models = await DataStore.query(Users, (e) => e.clientID("eq", userCompanyID.get()));
  return models;
};

const getMonthlyCompanyStats = async () => {
  const models = await DataStore.query(CompanyStats, (e) => e.id("eq", userCompanyID.get()));
  return models;
};

const findEmployeeClaims = async (id) => {
  const models = await DataStore.query(EmployeeClaims, (obj) => obj.employeeID("eq", id));
  return models;
};

const updateEmployeeClaim = async (obj) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbClaim = models[0];
  await DataStore.save(
    EmployeeClaims.copyOf(dbClaim, (updated) => {
      updated.ins_addr_1 = obj.ins_addr_1;
      updated.ins_addr_2 = obj.ins_addr_2;
      updated.ins_city = obj.ins_city;
      updated.ins_dob = obj.ins_dob;
      updated.ins_name_f = obj.ins_name_f;
      updated.ins_name_l = obj.ins_name_l;
      updated.ins_number = obj.ins_number;
      updated.ins_sex = obj.ins_sex;
      updated.ins_state = obj.ins_state;
      updated.ins_zip = obj.ins_zip;
      updated.pat_addr_1 = obj.pat_addr_1;
      updated.pat_addr_2 = obj.pat_addr_2;
      updated.pat_city = obj.pat_city;
      updated.pat_country = obj.pat_country;
      updated.pat_dob = obj.pat_dob;
      updated.pat_name_f = obj.pat_name_f;
      updated.pat_name_l = obj.pat_name_l;
      updated.pat_name_m = obj.pat_name_m;
      updated.pat_email = obj.email;
      updated.pat_phone = obj.pat_phone;
      updated.pat_rel = obj.pat_rel;
      updated.pat_sex = obj.pat_sex;
      updated.pat_state = obj.pat_state;
      updated.pat_zip = obj.pat_zip;
      updated.payer_name = obj.payer_name;
      if (obj.status === CLAIM_SUBMIT_STATUS.submitted) {
        console.log("updated.submissionDate?.split(", ")", updated.submissionDate?.split(","));
        updated.submissionDate = [...(dbClaim.submissionDate?.split(",") || []), moment().toISOString()].join(",");
        updated.reSubmissionDate = moment().toISOString();
      }
      if (updated.status !== obj.status) {
        updated.orderDate = moment().format("YYYYMMDD");
      }
      updated.payerid = obj.payerid;
      updated.payer_icn = obj.payer_icn;
      updated.claimNo = obj.claimNo;
      updated.from_date_1 = obj.from_date_1;
      updated.provider = obj.provider;
      updated.proc_array = obj.proc_array;
      updated.status = obj.status;
      updated.message = obj.message;
      updated.updatedBy = obj.updatedBy;
      updated.total_charge = obj.total_charge?.toString();
      updated.updatedByName = obj.updatedByName;
      updated.clia_number = obj.clia_number;
    })
  );
  return true;
};

const updateEmployeeClaimWithPatient = async (employees) => {
  for (let i = 0; i < employees.length; i++) {
    const emp = employees[i];
    const models = await DataStore.query(EmployeeClaims, (e) => e.employeeID("eq", emp.id));
    const dbClaim = models[0];
    if (!dbClaim) continue;
    console.log("dbClaim", dbClaim);
    await DataStore.save(
      EmployeeClaims.copyOf(dbClaim, (updated) => {
        updated.pat_email = emp.email;
      })
    );
  }

  return true;
};
const updateClaimTesting = async (obj) => {
  for (let i = 0; i < altaIDS.length; i++) {
    const prevClaim = await getLabData(altaIDS[i]);
    const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", localClaimId[i]));
    const dbClaim = models[0];
    await DataStore.save(
      EmployeeClaims.copyOf(dbClaim, (updated) => {
        updated.facility_name = "BRM LABORATORIES";
        updated.facility_npi = "106312280354";
        updated.facility_zip = "912015432";
        updated.facility_city = "CA";
        updated.bill_name = "BRM LABORATORIES";
        updated.bill_npi = "106312280354";
        updated.bill_phone = "8565345776";
        updated.bill_addr_1 = "2268 AVE SCB";
        updated.facility_addr_1 = "2268 AVE SCB";
        updated.bill_city = "CA";
        updated.bill_taxid = "17170221212";
        updated.pat_name_f = prevClaim.pat_name_f;
        updated.pat_name_l = prevClaim.pat_name_l;
        updated.pat_name_m = prevClaim.pat_name_m;
        updated.ins_name_f = prevClaim.ins_name_f;
        updated.ins_name_l = prevClaim.ins_name_l;
        updated.ins_name_m = prevClaim.ins_name_m;
        updated.proc_array = prevClaim.proc_array;
        updated.total_paid = prevClaim.total_paid;
        updated.paidAmount = prevClaim.paidAmount;
        updated.allowedPayment = prevClaim.allowedPayment;
        updated.claimNo = prevClaim.claimNo;
        updated.eraIds = prevClaim.eraIds;
        updated.orderDate = prevClaim.orderDate;
        updated.paymentCheckNo = prevClaim.paymentCheckNo;
        updated.paymentMethod = prevClaim.paymentMethod;
        updated.checkDate = prevClaim.checkDate;
        updated.status = prevClaim.status;
        updated.message = prevClaim.message || "";
      })
    );
  }
  return true;
};

const updateClaimData = async (claims) => {
  for (let i = 0; i < claims.length; i++) {
    const claim = claims[i];
    const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", claim.id));
    const dbClaim = models[0];
    await DataStore.save(
      EmployeeClaims.copyOf(dbClaim, (updated) => {
        updated.total_paid = null;
        updated.paidAmount = null;
        updated.allowedPayment = null;
        updated.claimNo = "";
        updated.eraIds = "";
        updated.paymentCheckNo = "";
        updated.paymentMethod = "";
        updated.checkDate = null;
      })
    );
  }
  return true;
};

const updateEmployeewithClaims = async (claim) => {
  try {
    // Retrieve the employee record from the API
    const dbemp = await graphqlApi.getEmployeeByIdGQl(claim.employeeID);

    // Extract country code and phone number from claim
    const [countryCode, phoneNo] = getPhoneNo(claim.pat_phone);

    // Create an object with the updated employee information
    const updated = {
      id: dbemp.id,
      firstName: claim.pat_name_f,
      lastName: claim.pat_name_l,
      middleName: claim.pat_name_m,
      dob: claim.pat_dob,
      phoneNumber: phoneNo,
      countryCode: countryCode,
      street: claim.pat_addr_1,
      street2: claim.pat_addr_2,
      sex: claim.pat_sex,
      state: claim.pat_state,
      city: claim.pat_city,
      insuranceCompany: claim.payerid,
      insuranceCompanyCode: claim.payer_name,
      insuranceDetails: JSON.stringify(updateInsurancefromClaim(dbemp.insuranceDetails, claim)),
      medicalNo: claim.ins_number,
      _version: dbemp._version,
    };

    // Conditionally add medicareNo based on payer_name
    if (claim.payer_name?.includes("Medicare")) {
      updated.medicareNo = claim.ins_number;
    }

    // Update the employee record in the API
    const res = await graphqlApi.updateEmployeeGQL(updated);

    return res;
  } catch (err) {
    // Handle errors
    console.error("Error updating employee:", err);
    throw err;
  }
};

const mergeEmployeeLogs = async (primaryId, deletedIds) => {
  const path = "/mergeEmployeeLogs";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      primaryId,
      deletedIds,
      companyID: userCompanyID.get(),
    },
  };
};

const fetchPatientClaim = async (empId) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.employeeID("eq", empId));
  return models;
};

const updateClaims = async (obj, user, provider, refProvider, ordProvider, claimList) => {
  let models = claimList;

  if (!models) {
    // models = await fetchPatientClaim(obj.id);
    models = await getEmployeeClaimsAPI(obj.id);
  }
  const ttl = models.length;
  for (let i = 0; i < ttl; i++) {
    let dbClaim = models[i];
    const claim = models[i];
    if (claimList && claimList.length > 0) {
      console.log("claim", claim);
      const res = await DataStore.query(EmployeeClaims, (e) => e.id("eq", claim));
      dbClaim = res[0];
    }
    console.log("dbClaim", dbClaim);
    await DataStore.save(
      EmployeeClaims.copyOf(dbClaim, (updated) => {
        updated.ins_addr_1 = obj.street;
        updated.ins_addr_2 = obj.street2;
        updated.ins_city = obj.ins_city;
        updated.ins_dob = obj.dob;
        updated.ins_name_f = obj.firstName;
        updated.ins_name_l = obj.lastName;
        updated.ins_name_m = obj.middleName || "";
        updated.ins_number = obj.medicalNo;
        updated.ins_sex = obj.gender || obj.sex;
        updated.ins_state = obj.state;
        updated.ins_zip = obj.zip;
        updated.pat_addr_1 = obj.street;
        updated.pat_addr_2 = obj.street2;
        updated.pat_city = obj.city;
        updated.pat_country = obj.country;
        updated.pat_dob = obj.dob;
        updated.pat_name_f = obj.firstName;
        updated.pat_name_l = obj.lastName;
        updated.pat_name_m = obj.middleName || "";
        updated.pat_phone = obj.phoneNumber;
        updated.pat_sex = obj.sex || obj.gender;
        updated.pat_state = obj.state;
        updated.pat_zip = obj.zip;
        updated.payer_name = obj.insuranceCompanyCode;
        updated.payerid = obj.insuranceCompany;
        updated.provider = {
          ...(dbClaim.provider || {}),
          ...(provider && {
            prov_id: provider.id,
            prov_name_f: provider.firstName,
            prov_name_l: provider.lastName,
            prov_name_m: provider.middleName,
            prov_npi: provider.npi,
            prov_taxid: provider.taxid,
          }),
          ...(refProvider && {
            ref_id: refProvider.id,
            ref_name_f: refProvider.firstName,
            ref_name_l: refProvider.lastName,
            ref_name_m: refProvider.middleName,
            ref_npi: refProvider.npi,
          }),
          ...(ordProvider && {
            ord_prov_id: ordProvider.id,
            ord_prov_name_f: ordProvider.firstName,
            ord_prov_name_l: ordProvider.lastName,
            ord_prov_name_m: ordProvider.middleName,
            ord_prov_npi: ordProvider.npi,
          }),
        };
        updated.updatedBy = user?.sub;
        updated.updatedByName = user?.name;
      })
    );
  }
};
const deleteEmployeeClaim = async (id) => {
  const res = await DataStore.delete(EmployeeClaims, (obj) => obj.id("eq", id));
  return res;
};

const updateEmployeeClaimStatus = async (obj, claimStatus, message) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbClaim = models[0];
  await DataStore.save(
    EmployeeClaims.copyOf(dbClaim, (updated) => {
      updated.status = claimStatus;
    })
  );
};

const isAcknowledgeClaim = async (obj, user) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbClaim = models[0];
  await DataStore.save(
    EmployeeClaims.copyOf(dbClaim, (updated) => {
      updated.acknowledgeBy = {
        seenByName: user.name,
        seenById: user.sub,
      };
    })
  );
  const logObj = {
    employeeID: obj.employeeID,
    empID: obj.employeeID,
    orderId: obj.remote_claimid.slice(7),
    medicalNo: obj.ins_number?.toUpperCase(),
    payerid: obj.payerid,
    payerName: obj.payer_name || "",
    locationID: obj.locationID || "",
    clientID: obj.clientID || "",
    subAgentID: obj.subAgentID || "",
    firstName: obj.pat_name_f,
    lastName: obj.pat_name_l,
    dob: obj.pat_dob,
    message: obj.message || "",
    clientName: obj.clientName || "",
    locationName: obj.locationName || "",
    subAgentName: obj.subAgentName || "",
    claimID: obj.id,
    eventType: "isAcknowledge",
    eventData: "isAcknowledge",
    message: "Done",
    userName: user.name || "System",
    userID: user.sub || null,
    amount: obj.total_charge,
  };

  console.log({ logObj });
  await saveLogs(logObj);
};

const updateEmployeeClaimStatusWithLogs = async (obj, date, user) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbClaim = models[0];
  await DataStore.save(
    EmployeeClaims.copyOf(dbClaim, (updated) => {
      updated.status = obj.status;
      updated.message = obj.message;
    })
  );

  const logDate = moment(date, "YYYY-MM-DDTHH:mm").toISOString();

  const logObj = {
    employeeID: obj.employeeID,
    empID: obj.employeeID,
    orderId: obj.remote_claimid.slice(7),
    medicalNo: obj.ins_number.toUpperCase(),
    payerid: obj.payerid,
    payerName: obj.payer_name || "",
    locationID: obj.locationID || "",
    clientID: obj.clientID || "",
    subAgentID: obj.subAgentID || "",
    firstName: obj.pat_name_f,
    lastName: obj.pat_name_l,
    dob: obj.pat_dob,
    message: obj.message || "",
    clientName: obj.clientName || "",
    locationName: obj.locationName || "",
    subAgentName: obj.subAgentName || "",
    claimID: obj.id,
    eventType: CLAIM_STATUS_LOG_OBJ[obj.status],
    eventData: CLAIM_STATUS_LOG_OBJ[obj.status],
    userName: user.name || "System",
    userID: user.sub || null,
    amount: obj.total_charge,
    logDate,
  };

  console.log({ logObj });
  await saveLogs(logObj);
};

const updateEmployeeInsNo = async (emps) => {
  const ttl = emps.length;
  for (let i = 0; i < ttl; i++) {
    const obj = emps[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", obj.id));
    const dbClaim = models[0];
    await DataStore.save(
      Employee.copyOf(dbClaim, (updated) => {
        updated.medicalNo = RemoveSpaceAndChar(obj.medicalNo);
        updated.medicareNo = RemoveSpaceAndChar(obj.medicareNo);
      })
    );
  }
};

const deleteProviderInfo = async (id) => {
  return await DataStore.delete(ProviderInfo, (obj) => obj.id("eq", id));
};

const updateProviderNote = async (user) => {
  const models = await DataStore.query(ProviderInfo, (e) => e.id("eq", user.id));
  const dbEmp = models[0];
  const resp = await DataStore.save(
    ProviderInfo.copyOf(dbEmp, (updated) => {
      updated.note = user.note;
    })
  );
  return resp;
};

const updateClientProviderNumber = async (id, newValue) => {
  try {
    const models = await DataStore.query(Site, (e) => e.id("eq", id));
    const dbEmp = models[0];

    const prevValue = getIntVal(dbEmp.associatedClients);
    await DataStore.save(
      Site.copyOf(dbEmp, (updated) => {
        updated.associatedClients = prevValue + newValue;
      })
    );
  } catch (er) {
    console.log("[updateClientProviderNumber]", er);
  }
};

const updateProviderInfo = async (user) => {
  const models = await DataStore.query(ProviderInfo, (e) => e.id("eq", user.id));
  const dbEmp = models[0];

  // Determine if there's a change in associatedClient values
  const clientChanged =
    dbEmp.associatedClient && user.associatedClient && dbEmp.associatedClient !== user.associatedClient;
  const removedFromOldClient = dbEmp.associatedClient && !user.associatedClient;
  const addedToNewClient = !dbEmp.associatedClient && user.associatedClient;
  const bothUndefinedOrNull = !dbEmp.associatedClient && !user.associatedClient;

  if (!bothUndefinedOrNull) {
    if (clientChanged || removedFromOldClient) {
      await updateClientProviderNumber(dbEmp.associatedClient, -1);
    }

    if (clientChanged || addedToNewClient) {
      await updateClientProviderNumber(user.associatedClient, 1);
    }
  }

  const resp = await DataStore.save(
    ProviderInfo.copyOf(dbEmp, (updated) => {
      updated.name = user.name;
      updated.firstName = user.firstName;
      updated.middleName = user.middleName;
      updated.lastName = user.lastName;
      updated.code = user.code;
      updated.speciality = user.speciality;
      updated.npi = user.npi;
      updated.npiType = user.npiType;
      updated.taxid = user.taxid;
      updated.street = user.street;
      updated.city = user.city;
      updated.state = user.state;
      updated.zip = user.zip;
      updated.phoneNumber = user.phoneNumber;
      updated.fax = user.fax;
      updated.isActive = user.isActive;
      updated.associatedClient = user.associatedClient;
      updated.setting = user.setting;
      updated.updatedByName = user.createdByName;
    })
  );
  return resp;
};

const createNewLab = async (newComp, user) => {
  const [countryCode, phoneNo] = getPhoneNo(newComp.phoneNumber);
  const settings = await getBRMCompanies();

  console.log("newComp", newComp);

  const obj = {
    name: newComp.name,
    contact_name: newComp.name,
    phone_number: newComp.phoneNumber,
    contact_email: newComp.email,
    email: newComp.email,
    street: newComp.street,
    street2: newComp.street2,
    city: newComp.city,
    state: newComp.state,
    countryCode: countryCode,
    country: "CA",
    zip: newComp.zip,
    npi: newComp.npi,
    taxid: newComp.taxid,
    taxonomyType: newComp.taxonomyType,
    bill_id: newComp.bill_id,
    code: "011", //`${parseInt(settings[0]?.code || 0) + 1}`.padStart(3, 0),
    firstName: newComp.firstName,
    lastName: newComp.lastName,
    cliCode: newComp.cliCode,
    ltcLink: newComp.ltcLink,
  };
  const company = await DataStore.save(
    new AppSetting({
      code: obj.code,
      bill_id: obj.bill_id,
      name: obj.name,
      npi: obj.npi,
      taxid: obj.taxid,
      taxonomyType: obj.taxonomyType,
      email: obj.email,
      phoneNumber: obj.phone_number,
      street: obj.street,
      street2: obj.street2,
      city: obj.city,
      state: obj.state,
      zip: obj.zip,
      cliaNumber: [{ cliCode: obj.cliCode, isDefault: true }],
      otherNPI: [
        {
          name: newComp.name,
          street: newComp.street,
          street2: newComp.street2,
          city: newComp.city,
          state: newComp.state,
          zip: newComp.zip,
          bill_id: newComp.bill_id,
          npi: newComp.npi,
          taxid: newComp.taxid,
          taxonomyType: newComp.taxonomyType,
          cliCode: newComp.cliCode,
        },
      ],
      isActive: false,
      employeeCounter: 0,
      orderId: 0,
      testLimit: 0,
      totalOrders: 0,
      minTestOrderQty: 0,
      ltcLink: obj.ltcLink,
      status: "active",
    })
  );

  const client = await DataStore.save(
    new Client({
      companyID: company.id,
      name: obj.name,
      contact_name: obj.contact_name,
      phone_number: obj.phone_number,
      contact_email: obj.email,
      street: obj.street,
      city: obj.city,
      state: obj.state,
      contactName: obj.phone_number,
      countryCode: obj.countryCode,
      country: obj.country,
      zip: obj.zip,
    })
  );

  const facility = await DataStore.save(
    new Site({
      name: `${obj.name}`,
      clientID: client.id,
      contact_name: obj.contact_name,
      phone_number: obj.phone_number,
      contact_email: obj.email,
      street: obj.street,
      city: obj.city,
      state: obj.state,
      contactName: obj.phone_number,
      countryCode: obj.countryCode,
      country: obj.country,
      zip: obj.zip,
      createdByName: user?.name,
    })
  );
  const subAgent = await DataStore.save(
    new SubAgent({
      name: `${obj.name}`,
      clientID: client.id,
      locationID: facility.id,
      contact_name: obj.contact_name,
      phone_number: obj.phone_number,
      contact_email: obj.email,
      street: obj.street,
      city: obj.city,
      state: obj.state,
      contactName: obj.phone_number,
      countryCode: obj.countryCode,
      country: obj.country,
      zip: obj.zip,
      createdByName: user.name,
    })
  );

  const res = await DataStore.save(
    new Users({
      clientID: client.id,
      connectedID: client.id,
      email: obj.email,
      firstName: obj.firstName,
      lastName: obj.lastName,
      locationID: facility.id,
      note: "System",
      personalization: JSON.stringify(PERSONALIZATION),
      phone_number: obj.phone_number,
      roles: [{ role: "Admin" }],
      status: "Confirm",
      subAgentID: "",
      systemUser: 1,
      userID: `${obj.phone_number}_${obj.code}`,
      userRole: "Admins",
      userPermission: DEFAULT_USRERS_PERMISSIONS,
    })
  );

  const cObj = await DataStore.query(AppSetting, (e) => e.id("eq", company.id));

  const companyObj = await DataStore.save(
    AppSetting.copyOf(cObj[0], (updated) => {
      updated.clientID = client.id;
      updated.facilityID = facility.id;
      updated.subAgentID = subAgent.id;
    })
  );

  return { clientID: client.id, code: obj.code };
};

const updateBRMCompany = async (comp) => {
  const cObj = await DataStore.query(AppSetting, (e) => e.id("eq", comp.id));

  const companyObj = await DataStore.save(
    AppSetting.copyOf(cObj[0], (updated) => {
      updated.bill_id = comp.bill_id;
      updated.name = comp.name;
      updated.npi = comp.npi;
      updated.taxid = comp.taxid;
      updated.taxonomyType = comp.taxonomyType;
      updated.email = comp.email;
      updated.phoneNumber = comp.phoneNumber;
      updated.street = comp.street;
      updated.street2 = comp.street2;
      updated.city = comp.city;
      updated.state = comp.state;
      updated.zip = comp.zip;
    })
  );
};
const addRenderingProvider = async (param, operation) => {
  // Query the AppSetting with the specific clientID
  let models = await DataStore.query(AppSetting, (e) => e.clientID("eq", userCompanyID.get()));

  // Ensure models[0] exists
  if (!models.length) {
    throw new Error("No AppSetting found for the specified clientID");
  }

  const setting = models[0];

  // Create a new array instance from renderingProvider
  let prevObj = Array.isArray(setting.renderingProvider) ? [...setting.renderingProvider] : [];

  if (param.isDefault) {
    prevObj = prevObj.map((item) => {
      return {
        ...item,
        isDefault: false,
      };
    });
  } else if (!param.isDefault && prevObj.length !== 0) {
    prevObj[0] = {
      ...prevObj[0],
      isDefault: true,
    };
  }

  // Find the index of the provider to update or delete
  const providerIndex = prevObj.findIndex((provider) => provider.id === param.id);

  if (operation === "add" || operation === "update") {
    const id = operation === "add" ? uuidv4() : prevObj[providerIndex]?.id || uuidv4();
    const providerData = {
      id: id,
      value: id,
      label: param.name,
      name: param.name,
      firstName: param.firstName,
      lastName: param.lastName,
      middleName: param.middleName,
      npi: param.npi,
      npiType: param.npiType,
      taxid: param.taxid,
      speciality: param.speciality,
      phoneNumber: param.phoneNumber,
      fax: param.fax,
      state: param.state,
      street: param.street,
      street2: param.street2,
      city: param.city,
      zip: param.zip,
      isActive: param.isActive,
      isDefault: prevObj.length === 0 ? true : param?.isDefault,
    };

    if (providerIndex !== -1) {
      // If provider exists, update its details
      prevObj[providerIndex] = {
        ...prevObj[providerIndex],
        ...providerData,
      };
    } else if (operation === "add") {
      // If provider does not exist and operation is 'add', add it to the array
      prevObj.push(providerData);
    } else {
      // If provider does not exist and operation is 'update', throw an error
      throw new Error("Provider not found for update");
    }
  } else if (operation === "delete") {
    // If provider exists, delete it from the array
    if (providerIndex !== -1) {
      prevObj.splice(providerIndex, 1);
    } else {
      // If provider does not exist, throw an error
      throw new Error("Provider not found for deletion");
    }
  } else {
    throw new Error("Invalid operation");
  }

  // Save the updated AppSetting with the new renderingProvider array
  return await DataStore.save(
    AppSetting.copyOf(models[0], (updated) => {
      updated.renderingProvider = prevObj;
    })
  );
};

const addOtherNPI = async () => {
  let models = await DataStore.query(AppSetting, (e) => e.clientID("eq", userCompanyID.get()));
  const setting = models[0];

  const obj = [
    {
      name: setting.name,
      npi: setting.npi,
      phoneNumber: setting.phoneNumber,
      state: setting.state,
      street: setting.street,
      street2: setting.street2,
      city: setting.city,
      taxid: setting.taxid,
      taxonomyType: setting.taxonomyType,
      zip: setting.zip,
      bill_id: setting.bill_id,
    },
    // {
    //   name: "VISTA MEDICAL PARTNERS A PROFESSIONAL CORPORATION",
    //   npi: "1043366164",
    //   phoneNumber: "+13102885933",
    //   state: "CA",
    //   street: "2105 BEVERLY BLVD STE 231",
    //   street2: "",
    //   city: "LOS ANGELES",
    //   taxid: "954781848",
    //   taxonomyType: "Internal Medicine",
    //   zip: "90057-2287",
    //   bill_id: "207R00000X",
    // },
  ];
  const companyObj = await DataStore.save(
    AppSetting.copyOf(models[0], (updated) => {
      updated.otherNPI = obj;
    })
  );
};

const createInvoice = async (data, loginUser) => {
  try {
    const setting = await getCompanySetting();
    let paramData = {
      invoiceSendDate: moment().toISOString(),
      invoiceDate: moment().toISOString(),
      dueDate: moment().add(10, "days").toISOString(),
      sendToType: data.sendToType,
      sendTo: data.payerid === "00001" ? "Patient" : "Client",
      employeeID: data.employeeID,
      details: data.proc_array,
      invoiceAmount: data.invoiceAmount,
      minAmount: data.minAmount ? Number(parseFloat(data.minAmount).toFixed(2)) : 0,
      claimID: data.id,
      clientID: userCompanyID.get() || CONFIG.generalClient,
      companyID: data.locationID,
      subAgentID: data.subAgentID,
      status: "pending",
      udpatedBy: loginUser?.sub,
      updatedByName: loginUser?.name,
      toData: {
        id: data.payerid === "00001" ? data.employeeID : data.locationID,
        isActive: true,
        payerid: data.payerid,
        payer_name: data.payer_name,
        firstName: data.pat_name_f,
        lastName: data.pat_name_l,
        street: data.pat_addr_1,
        street2: data.pat_addr_2,
        city: data.pat_city,
        state: data.pat_state,
        country: data.pat_country,
        zip: data.pat_zip,
        pcn: data.pcn,
        email: data.pat_email,
      },
      fromData: {
        id: setting.id,
        companyName: setting.name,
        street: setting.street,
        street2: setting.street2,
        city: setting.city,
        state: setting.state,
        zip: setting.zip,
      },
      message: data.message,
    };

    const payload = createInvoicePayload([paramData]);

    console.log("Payload", payload);

    const res = await callAmplifyAPI("/invoices", { items: payload, data: loginUser });

    return { data: res };
  } catch (error) {
    console.log(error);
  }
};

const createInvoices = async (data, loginUser, isEditInvoice, viewType) => {
  try {
    const payload = createInvoicePayload(data, isEditInvoice || false, viewType);

    const res = await callAmplifyAPI("/invoices", { items: payload, data: { ...loginUser, id: userCompanyID.get() } });

    return { data: res };
  } catch (error) {
    console.log(error);
  }
};

const getInvoiceDetails = async (payload) => {
  const { id, type } = payload;

  try {
    const res = await callAmplifyAPI("/invoices/preview", { id, type });

    return res;
  } catch (error) {
    console.log(error);
  }
};

const reSendInvoice = async (invoiceData, loginUser) => {
  try {
    const res = await callAmplifyAPI("/invoices/resend", {
      ids: invoiceData,
      data: { ...loginUser, id: userCompanyID.get() },
    });
    return { data: res };
  } catch (error) {
    console.log(error);
  }
};

const updateInvoiceNotes = async (data) => {
  try {
    const invoice = graphqlApi.getClaimInvoiceByIdGQl(data.id);
    const params = {
      note: data.note,
    };

    const res = await graphqlApi.updateClaimInvoiceGQL(params);
    return res;
  } catch (e) {
    console.log("Error updating invoice:", e);
    return null;
  }
};

const saveClaimImageFile = async (id, fileContent) => {
  try {
    let fileName = `${id || "ltc"}`;
    fileName = `${fileName}_${moment().format("YYYY_MM_DD_HH_mm_ss")}.png`;
    await Storage.put(`claimInvoice/${fileName}`, fileContent, {
      bucket: CONFIG.eligibilityBucket,
    });
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const addInvoiceNote = async (loc) => {
  console.log(loc);
  const models = await DataStore.query(Invoices, (s) => s.id("eq", loc.id));

  console.log(models);
  const res = await DataStore.save(
    Invoices.copyOf(models[0], (updated) => {
      updated.note = loc.note;
    })
  );
  return res;
};

const getInvoice = async (data) => {
  const models = await callAmplifyAPI("/invoices/list", data);
  return models;
};

const getUnPosted = async (eraid) => {
  const models = await callAmplifyAPI("/payment/unposted", { eraid });
  return models?.data || [];
};

const processPaymentAPI = async (obj) => {
  const path = "/payment";
  const params = {
    body: obj,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    const res = await API.post(apiName, path, params);
    return res;
  } catch (e) {
    console.log("Error changing user role:", e);
    return null;
  }
};

const removeAssignTo = async (params) => {
  const models = await callAmplifyAPI("/claims/removeAssignTo", params);
  return models?.data;
};

const cancelInvoices = async (params) => {
  const models = await callAmplifyAPI("/invoices/cancel", params);
  return models;
};

const getClaimByID = async (params) => {
  try {
    const models = await callAmplifyAPI("/claim", params);
    console.log("Models", models);
    return models?.data;
  } catch (err) {
    console.log("Error:-", err);
  }
};

const getInvoiceClaims = async (params) => {
  try {
    const models = await callAmplifyAPI("/invoices/claims", params);
    console.log("Models", models);
    return models?.items;
  } catch (err) {
    console.log("Error:-", err);
  }
};

export const getDefaultProvidersForInvoice = async (params) => {
  try {
    const models = await callAmplifyAPI("/invoices/provider", params);
    return models;
  } catch (err) {
    console.log("Error:-", err);
  }
  return {};
};
export const getCptData = async (params) => {
  console.log("Params", params);
  if (!params?.codes) {
    return [];
  }

  try {
    return await callAmplifyAPI("/excpt", params);
  } catch (err) {
    console.error("Error fetching CPT data:", err);
    return err;
  }
};

export const createAppeal = async (appealOpb) => {
  try {
    return await callAmplifyAPI("/appeal", appealOpb);
  } catch (err) {
    console.error("Error fetching CPT data:", err);
    return err;
  }
};

export const exportClamDataToExcel = async (claimObj) => {
  try {
    return await callAmplifyAPI("/claims/export", claimObj);
  } catch (err) {
    console.error("Error export", err);
    return err;
  }
};

export const exportEmployeeDataToExcel = async (claimObj) => {
  try {
    return await callAmplifyAPI("/employees/export", claimObj);
  } catch (err) {
    console.error("Error export", err);
    return err;
  }
};

export const getReportsData = async (reportFilter) => {
  try {
    return await callAmplifyAPI("/reports", reportFilter);
  } catch (err) {
    console.error("Error export", err);
  }
};

const updateEmployeeNumber = async (claim) => {
  try {
    const models = await DataStore.query(Employee);

    for (let i = 0; i < models.length; i++) {
      const dbemp = models[i];
      const res = await DataStore.save(
        Employee.copyOf(dbemp, (updated) => {
          updated.employeeFND = getEmployeeIndexValue(dbemp);
        })
      );
    }
  } catch (err) {
    console.log("Error:-", err.message);
  }
};

const saveCsvFileOnBucket = async (data, fileName) => {
  return await Storage.put(`invoiceParams/${fileName}`, jsonToCSV(data), {
    bucket: CONFIG.eligibilityBucket,
  });
};

export const downloadClaimERA = async (params) => {
  try {
    return await callAmplifyAPI("/claims/era", params);
  } catch (err) {
    console.error("Error export", err);
    return err;
  }
};

const cancelClaimERA = async (params) => {
  try {
    return await callAmplifyAPI("/claims/cancel", params);
  } catch (err) {
    console.error("Error export", err);
    return err;
  }
};

const getProxyContactData = async () => {
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/contacttypes";
  const relations = await axios.get(path);
  return relations.data;
};

const getConnectedProfiles = async (params) => {
  try {
    return await callAmplifyAPI("/employees/connected", params);
  } catch (err) {
    console.error("Error create tests data:", err);
    return err;
  }
};

const getEmployeeListFromPG = async (param) => {
  console.log("in-apiRes", apiRes);
  let dob = "";
  if (param?.dob) {
    let employeDOB = new Date(param?.dob);
    dob =
      employeDOB.getFullYear() +
      "-" +
      ("0" + (employeDOB.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + employeDOB.getDate()).slice(-2);
  }

  const path = "/employees/list";

  console.log("path4444", path);

  // const defaultTestDate = !param.testDate ? { testDate: moment().format("YYYY-MM-DD") } : {};
  const defaultTestDate = null;
  const params = {
    params: {
      ...param,
      ...(dob && dob),
      ...(defaultTestDate && defaultTestDate),
      timezone: SYSTEM_TIME_ZONE,
      clientID: CONFIG.clientID,
      ...(CONFIG.siteID && { siteID: CONFIG.siteID }),
    },
  };

  const apiRes = await callAmplifyAPI(path, params);

  return apiRes;
};

const updateRelations = async (params) => {
  try {
    return await callAmplifyAPI("/employees/connected/update", params);
  } catch (err) {
    console.error("Error create tests data:", err);
    return err;
  }
};

const fetchClientAssociatedProviders = async (params) => {
  try {
    return await callAmplifyAPI("/labclient/provider/list", params);
  } catch (err) {
    console.error("Fetch Client Error", err);
    return [];
  }
};

const fetchEligibityApiData = async (params) => {
  try {
    return await callAmplifyAPI("/claims/elligibility", params);
  } catch (err) {
    console.error("Fetch Client Error", err);
    return [];
  }
};

const getLabClientTests = async () => {
  try {
    return await callAmplifyAPI("/labclient/test");
  } catch (err) {
    console.error("Fetch Client Error", err);
    return [];
  }
};

// const getLogs = async (id) => {
//   const apiRes = await callAmplifyAPI("/logs", {
//     id: id,
//   });
//   return apiRes.data || [];
// };


const updateClaimOpenParty = async (params) => {
  try {
    return await callAmplifyAPI("/claims/openparty", params);
  } catch (err) {
    console.error("Fetch Client Error", err);
    return [];
  }
};

const fetchResultFile = async (params) => {
  try {
    return await callAmplifyAPI("/claims/result", params);
  } catch (err) {
    console.error("Fetch Client Error", err);
    return [];
  }
};


export default {
  fetchResultFile,
  updateClaimOpenParty,
  fetchEligibityApiData,
  getLabClientTests,
  mergeEmployeeLogs,
  updateRelations,
  getEmployeeListFromPG,
  getConnectedProfiles,
  getProxyContactData,
  fetchClientAssociatedProviders,
  saveCsvFileOnBucket,
  createAppeal,
  updateEmployeeNumber,
  getCptData,
  getDefaultProvidersForInvoice,
  cancelInvoices,
  addOtherNPI,
  createNewLab,
  addOrderNote,
  createPreviousOrder,
  isEmployeeExists,
  fetchAllClients,
  getUserEmployeeID,
  createSystemUser,
  getPreRegisterData,
  getCompanySetting,
  addAuditLogs,
  deleteEmployee,
  getCurrentUser,
  getAllUsers,
  getUserSite,
  getUserSubAgent,
  getUserDefaultSubAgent,
  getUserCompany,
  updateOrderQuantity,
  saveFileStorage,
  signIn,
  fetchUsersInGroup,
  fetchAllUsers,
  changeUserRole,
  updateUserInfo,
  addUserClients,
  deleteUser,
  updateUserPassword,
  sendSMSNotification,
  sendEmail,
  saveClaimImageFile,
  sendEmailToCrew,
  deleteUserLinkFromEmployee,
  getEmployees,
  getEmployeeByID,
  getLoggedInUserEmployeeID,
  newEligibiliyFiles,
  getEligibilityFiles,
  newEmployee,
  updateEmployeePersonals,
  updateEmployee,
  updateEmployeeInsurance,
  fetchAllEmployees,
  createClaim,
  getLocations,
  getLocationById,
  getSubAgentById,
  getClientTestSettingByCompanyID,
  getSubAgents,
  checkOrderEligibility,
  addJSON,
  getCompanies,
  confirmUserSignUp,
  udpatePersonalization,
  fetchAllCrewMembers,
  addlogs,
  updateCompnayEmployeeNo,
  updateAlertSettings,
  newLocations,
  updateLocation,
  newSubAgent,
  updateSubAgent,
  deleteLocation,
  deleteSubAgent,
  newClient,
  updateClient,
  updateMemberTestKit,
  deleteClient,
  createOrder,
  createBulkOrder,
  getEmployeeClients,
  getOrders,
  deleteOrder,
  getOrderByEmployee,
  getPatientData,
  updateEligibilityFile,
  placeOrder,
  deniedClaimStatus,
  getClientById,
  getLocationInformation,
  getOrderLogs,
  newProviderInfo,
  getUserFromLocalDB,
  getEligibilityList,
  addEligibilityCsv,
  saveFileOnBucket,
  getEmployeeLogs,
  deleteLogRecord,
  updateSignUp,
  saveImageFile,
  getUserValidate,
  isUserDemoExists,
  addReceivedAmountDetails,
  saveLogs,
  UpdateOrderForTest,
  getDuplicateData,
  getOrdersBilling,
  getAllPendingOrders,
  addMemberNote,
  checkManualBulkEligibility,
  UpdateOrderData,
  updateMember,
  updateTestAvailable,
  updateOrderStatus,
  updateOrdQtyManual,
  getProviderInfo,
  deleteProviderInfo,
  updateProviderInfo,
  updateProviderNote,
  updateCliItem,
  updateCompanyAlertSetting,
  getEmployeeClaims,
  getEmployeeClaimsAPI,
  getClaimByIdAPI,
  getUnpaidClaimsInBetween,
  getLastestDeniedRejectedClaims,
  deleteEmployeeClaim,
  findEmployeeClaims,
  updateEmployeeClaim,
  updateEmployeeClaimStatus,
  updateEmployeeClaimStatusWithLogs,
  updateLabData,
  updateEmployeeInsNo,
  updateClaims,
  updateOrderInsurance,
  getClaimERA,
  getPatientERA,
  checkManualEligibility,
  checkBRMEligibility,
  addClaimNote,
  newCreateUser,
  updateCompnayDefaultSetting,
  getProviderPublicAPI,
  fetchAllCompanies,
  isAcknowledgeClaim,
  addUser,
  fetchPatientClaim,
  getLabData,
  updateClaimTesting,
  updateEmployeewithClaims,
  updateEmployeeClaimWithPatient,
  updateClaimData,
  getBRMCompanies,
  updateBRMCompanyActiveToggle,
  updateBRMCompany,
  getClaimList,
  filterPaidClaims,
  getBRMUsers,
  getMonthlyCompanyStats,
  deleteUserFromDataStore,
  updateOrdManual,
  updatePatientHospice,
  updateCompnayClaimNo,
  getStatsData,
  addCptCode,
  searchCptCode,
  updateCptCode,
  searchProviderAPI,
  getEmployeeNo,
  searchICTCode,
  addICTCode,
  updateICTCode,
  getActivePayers,
  fetchDocumentAPI,
  saveDocumentFile,
  newDocumentCreateAPI,
  updateDocumentAPI,
  fetchDocumentTypesAPI,
  creteDocumentTypesAPI,
  createLabClient,
  importLabClient,
  updateLabClient,
  updateLabClientNotes,
  updateProviderClient,
  deleteDocumentAPI,
  updateEmpSingleField,
  updateLogs,
  updateClientMdSettingInfo,
  newPatientCreateAPI,
  patientUpdateAPI,
  createInvoice,
  getInvoice,
  addInvoiceNote,
  newClientSetting,
  deleteClientTestSetting,
  saveInvoiceDocFile,
  processPaymentAPI,
  getUnPosted,
  updateInvoiceNotes,
  removeAssignTo,
  createInvoices,
  reSendInvoice,
  updateEraApi,
  getClaimByID,
  getInvoiceClaims,
  resendOtp,
  verifyOtp,
  getInvoiceDetails,
  downloadClaimERA,
  cancelClaimERA,
  callAmplifyAPI,
  callPublicAPI,
  callAmplifyAPIPut,
  addRenderingProvider,
  deleteRelations,
};
