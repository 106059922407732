export const EMPLOYEE_CLAIM = "employeeClaim";

export const EMPLOYEE_CLAIM_FETCH = "employeeClaim/fetchAllEmployeeClaims";

export const EMPLOYEE_CLAIM_CREATE = "employeeClaim/careateEmployeeClaim";

export const EMPLOYEE_CLAIM_DELETE = "employeeClaim/deleteEmployeeClaim";

export const EMPLOYEE_CLAIM_UPDATE = "employeeClaim/updateEmployeeClaim";

export const EMPLOYEE_CLAIM_ACKNOWLEGDE = "employeeClaim/acknowledgeEmployeeClaim";

export const ASSIGN_CLAIM = "employeeClaim/assignClaim";

export const EMPLOYEE_CLAIM_UPDATE_STATUS = "employeeClaim/updateStatusClaims";

export const EMPLOYEE_CLAIM_CANCEL = "employeeClaim/cancelClaims";

export const GET_CLAIM_STATS = "getClaimClaimStats";

export const EMPLOYEE_CLAIM_EXPORT_EXCEL = "employeeClaim/exporttoexcel";

export const EMPLOYEE_CLAIM_OPEN_PARY = "employeeClaim/updateEmployeeClaimOpenParty";

