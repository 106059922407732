const CONSTANTS = {
  GENERAL: "GENERAL",
  SHOW_CONFIRM_MODAL: "show_confirm_modal",
  SHOW_DOCUMENT: "general/showdocument",
  SHOW_RESULT: "general/showresult",
  EMPTY_DOCUMENT_OBJ: {
    fileUrl: null,
    show: false,
    fileType: "",
    title: "",
  },
};

export default CONSTANTS;
